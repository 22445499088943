import React from 'react'
import styles from './inputs.module.css'
import ISimpleInput from './types'


export const TextInput:  React.FC<ISimpleInput> = ({
                                                      width = '100%',
                                                      height,
                                                      color,
                                                      maxWidth,
                                                      className = '',
                                                      disabled= false,
                                                      wrapperWidth,
                                                      mask,
                                                      value,
                                                      setValue,
                                                      icon,
                                                      callBackRequest,
                                                      ...props
                                                  }) => {


    return (
        <div className={styles.simple_input_wrap + ' ' + className}
             style={{
                height,
                backgroundColor: !disabled ? "white" : "rgb(245, 245, 245)",
                width: wrapperWidth && wrapperWidth}}>
            <input
                value={value}
                id={"simple-input"}
                className={styles.simple_input_style}
                type={props?.type ? props?.type : "text"}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        callBackRequest && callBackRequest()
                    }
                }}

                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const {value, selectionStart} = e.target
                    setValue(value)
                }}
                {...props}
                readOnly={disabled ? true : false}
                style={{
                    ...props.style, width, maxWidth, color,
                    backgroundColor: !disabled ? "white" : "rgb(245, 245, 245)",
                    cursor: !disabled ? "text" : "default"
                }}
            />
            <div style={{cursor: "pointer"}} onClick={() => {callBackRequest && callBackRequest()}}>
                {icon}
            </div>
        </div>
    )
}