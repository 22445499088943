import { configureStore } from '@reduxjs/toolkit'
import authReducer from "./slices/AuthSlice/AuthSlice"
import devicesReducer from "./slices/DevicesSlice/DevicesSlice"
import documentsReducer from "./slices/DocumentsSlice/DocumentsSlice"
import usersReducer from "./slices/UsersSlice/UserSlice"
import adReducer from "./slices/AdSlice/AdSlice"
import verReducer from "./slices/VersionSlice/VersionSlice"





export const store = configureStore({
    reducer: {
        auth: authReducer,
        devices: devicesReducer,
        documents: documentsReducer,
        users: usersReducer, 
        ad: adReducer,
        ver: verReducer

    }
})

export type TRootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch