import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { TransformOptions } from "stream"
import { openNotification } from "../../../components/notifications/notification"
import { instanceAxiosClose, instanceAxiosOpen } from "../../api/instances"
import { paths } from "../../api/paths"
import { loginData } from "../../mockData"
import { TRootState } from "../../store"
import { checkError } from "../../utils/checkErrors"
import { useAppDispatch } from "../../utils/hooks"
import { AuthPayload, AuthState, TCheckConnectData, TOut } from "./AuthSliceTypes"
import { UserInfo } from "os"

export const auth = createAsyncThunk("authorization", async ({login, password, serviceUrl}:AuthPayload) => {
    try {
        const { data }:any = await instanceAxiosOpen(serviceUrl).post(paths.accountService.authorization, {login, password})
        return data
    } catch (err) {
        checkError(err)
    }
})

export const out = createAsyncThunk("out", async (body: AuthPayload) => {
    try {
        const { data }:any = await instanceAxiosClose(localStorage.getItem('CURRENT_SERVICE_URL')).post(paths.accountService.out, body)
        return data
    } catch (err) {
        checkError(err)
    }
})

// export const getMyInfos = = createAsyncThunk("myInfos", async ({login, password, serviceUrl}:AuthPayload) => {
//     try {
//         const { data }:any = await instanceAxiosOpen(serviceUrl).post(paths.accountService.authorization, {login, password})
//         return data
//     } catch (err) {
//         checkError(err)
//     }
// })

export const getMyInfo = createAsyncThunk("myInfo", async () => {
    try {
        const { data }:any = await instanceAxiosClose(localStorage.getItem('c')).get(paths.accountService.myInfo)
        return data as AuthState["userInfo"]
    } catch (err: any) {
        checkError(err)
    }
})

export const checkConnect = createAsyncThunk("checkConnect", async (serviceUrl: string) => {
    try {
        const { data }  = await instanceAxiosClose(serviceUrl).post(paths.accountService.checkConnect, {})
        return data as TCheckConnectData
    } catch (err: any) {
        checkError(err)
    }
})



const initialState:AuthState = {
    userInfo: null,
    loaders: {
        authLoading: {state: false, text: ''},
        getMyInfoLoading: {state: false, text: ''},
        checkConnectLoading: {state: false, text: ''},
        editUserLoading:{state:false, text:''},
    },
    access_token: '',
    isAuthorised: false,
    // formviews:[],
    serviceIsAvailable: null,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers:{
        setUserInfo(state, { payload }) {
            state.userInfo = payload
        },
        setIsAuthorised(state, {payload}) {
            state.isAuthorised = payload
        }
        // setOut(state,{payload}) {
        //     state.
        // }
    },
    extraReducers: (builder) => {
        builder.addCase(checkConnect.pending, (state, action) => {
            state.loaders.checkConnectLoading = {state: true, text: 'Проверка подключения к сервису'}
        })
        builder.addCase(checkConnect.fulfilled, (state, action) => {
            state.loaders.checkConnectLoading = {state: false, text: 'Проверка подключения к сервису'}
            state.serviceIsAvailable = action?.payload?.isSuccess || false
        })
        builder.addCase(checkConnect.rejected, (state, action) => {
            state.loaders.checkConnectLoading = {state: false, text: 'Проверка подключения к сервису'}
            state.serviceIsAvailable = false
        })
        builder.addCase(auth.pending, (state, action) => {
            state.loaders.authLoading = {state: true, text: 'Авторизация'}
        })
        builder.addCase(auth.fulfilled, (state, action) => {
            state.loaders.authLoading = {state: false, text: 'Авторизация'}
            state.isAuthorised = true
            // state.userInfo = action.payload.data.value
            
            if(action.payload?.data.value.access_token){
                localStorage.setItem('ACCESS_TOKEN', action.payload?.data?.value?.access_token)
                
                localStorage.setItem('ACCESS_TOKEN_REFRESH', action.payload?.data?.value?.access_token_refresh)
                
                state.access_token = action.payload?.data?.value?.access_token
            }
            
            
        })


        builder.addCase(auth.rejected, (state, action) => {
            state.loaders.authLoading = {state: false, text: 'Авторизация'}
        })
        // builder.addCase(getMyInfo.pending, (state, action) => {
        //     state.loaders.authLoading = {state: true, text: 'Получение информации'}
        // })
        builder.addCase(getMyInfo.fulfilled,(state, action) => {
                //@ts-ignore
            state.userInfo = action.payload.data.value
        })
    }



})

export const { setUserInfo, setIsAuthorised } = authSlice.actions

export default authSlice.reducer;