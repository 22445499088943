import React, {CSSProperties} from 'react'
import classes from "./classes.module.scss";


export const CloseDialogButton: React.FC<{ handleClose: () => void, style?: CSSProperties, [props: string]: any }> = ({
                                                                     handleClose, style= {}
                                                                         }) => {
    return (
        <img className={classes.close_icon} src={'/images/CloseIcon.svg'} style={style} onClick={handleClose}/>
    )
}