import React, { useEffect, useRef, useState } from "react";
import {
  TGetRoles,
  TGetUsers,
  TRoles,
  TUsers,
} from "../../store/slices/UsersSlice/UsersSliceTypes";
import { TSort, Table } from "../Table/Table";
import useDebouncedFunction, {
  adsErrors,
  devicesErrors,
} from "../../store/utils/helpers";
import { InfoDialog } from "../dialogs/InfoModal";
import { DeviceStaticticContainer } from "../devices/deviceStatistic";
import { DeviceCommandsContainer } from "../devices/deviceCommands";
import { TextInput } from "../inputs/TextInput";
import { Button } from "../buttons/button";
import s from "./ad.module.scss";
import { AppDispatch, TRootState } from "../../store/store";
import { getRoles, getUsers } from "../../store/slices/UsersSlice/UserSlice";
import { connect } from "react-redux";
import { Checkbox, Select } from "antd";
import { HavePermission } from "../difficultFunc/HavePerm";
import { AdCreatingContainer } from "./createAd";
import {
  TActiveAd,
  TAds,
  TDeleteAd,
  TEditAd,
  TEditOrder,
  TGetAds,
  TGetVendingsWithAds,
  TVendingsWithAd,
} from "../../store/slices/AdSlice/AdSliceTypes";
import {
  TGetVendings,
  TVendingDevice,
} from "../../store/slices/DevicesSlice/DevicesSliceTypes";
import {
  ActiveAd,
  EditAd,
  EditOrder,
  GetVendingsWithAds,
  clearAdvertisingForCurrentVending,
  deleteAd,
  deleteAdd,
  getAds,
} from "../../store/slices/AdSlice/AdSlice";
import { getVendings } from "../../store/slices/DevicesSlice/DevicesSlice";
import { AdEditingContainer } from "./editAd";
import { openNotification } from "../notifications/notification";
import {
  EditOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import { unstable_renderSubtreeIntoContainer } from "react-dom";
// import {Button} from "antd";

interface IAds {
  getAds: Function;
  ads: TAds[] | [];
  // ad:TAds | null
  showMoreAd: boolean;
  EditAd: Function;
  DeleteAd: Function;
  currentDevice: TGetVendingsWithAds | null;
  vendingsWithAds: TGetVendingsWithAds[] | null;
  GetVendingsWithAds: Function;
  clearAdvertisingForCurrentVending: Function;
  setAdsModal: Function;
  activeAd: Function;
  EditOrder: Function;

  // clearAdvertisingForCurrentVending: Function
}

const AdsMon: React.FC<IAds> = ({
  clearAdvertisingForCurrentVending,
  currentDevice,
  getAds,
  ads,
  showMoreAd,
  EditAd,
  DeleteAd,
  vendingsWithAds,
  GetVendingsWithAds,
  setAdsModal,
  activeAd,
  EditOrder,
}) => {
  // const [adCreating, setAdCreating] = useState<{open: boolean}>({open: false})
  const [currentDeviceLocal, setCurrentDeviceLocal] = useState(currentDevice);
  const [editAd, setEditAd] = useState<{
    open: boolean;
    currentAd: TAds | null;
  }>({ open: false, currentAd: null });
  const [deleteAddd, setDeletedAddd] = useState<{
    open: boolean;
    currentAd: TAds | null;
  }>({ open: false, currentAd: null });
  const [ActiveAdd, setActiveAdd] = useState<{ currentAd: TAds | null }>({
    currentAd: null,
  });
  const [customAds, setCustomAds] = useState<any[] | []>([]);
  const [count, setCount] = useState<number>(1000000);
  const [offset, setOffset] = useState<number>(0);
  const [orderList, setOrderList] = useState<any[] | []>([]);
  const [currentOrder, setCurrentOrder] = useState([]);
  const [slider, setSlider] = useState(null);
  const decrementButton = useRef();
  const incrementButton = useRef();
  const [currentSort, setCurrentSort] = useState<TSort>({
    orderBy: "",
    isAsc: null,
  });
  const [currentIndex, setCurrentIndex] = useState(0);
  const [typeId, setTypeId] = useState<(number | undefined)[]>();
  const [newTypeId, setNewTypeId] = useState<number>();
  const [loading, setLoading] = useState(true);
  // const [currentAdPV, setCurrentAdPV] = useState<currentAdPV>()
  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (currentDeviceLocal) {
      let arr = currentDeviceLocal.advertisings;
      arr = arr.map((item) => {
        return {
          ...item,
        };
      });

      setCustomAds(arr);
    }
  }, [currentDeviceLocal]);
  useEffect(() => {
    // console.log(orderList)
    // let parseData = []
  }, [ads]);

  const changeIsActive = (id: number) => {
    activeAd({
      id: id,
      active: true,
    }).then(() => {
      VendingAdsHandlet();
    });
  };

  const changeIsNotActive = (id: number) => {
    activeAd({
      id: id,
      active: false,
    }).then(() => {
      VendingAdsHandlet();
    });
  };

  useEffect(() => {
    setOrderList(
      ads?.length
        ? ads.map((aads) => {
            return {
              ...aads,
            };
          })
        : []
    );
  }, [ads]);

  const getAdsHandler = () => {
    getAds({
      count: count,
      offset: offset,
      vendingId: currentDeviceLocal?.id,
    });
  };
  // console.log(orderList)

  useEffect(() => {
    getAdsHandler();
  }, []);

  function dragStartHandler(e: any, item: any) {
    setCurrentOrder(item);
    console.log("dragsS", item);
  }
  function dragLeaveHandler(e: any) {
    e.currentTarget.classList.remove(s.item__over);
  }

  function dragEndHandler(e: any) {
    // e.target.style.backgroung = 'white'
  }
  function dragOverHandler(e: any) {
    e.preventDefault();
    e.stopPropagation();
    // e.target.style.backgroung = 'lightgrey'
    e.currentTarget.classList.add(s.item__over);
  }
  function dropHandler(e: any, item: any) {
    e.currentTarget.classList.remove();
    e.preventDefault();
    console.log("dragsE", item);
    let arr: any[] = [];
    //@ts-ignore
    if (item.id !== currentOrder.id) {
      arr = orderList.map((i) => {
        //@ts-ignore
        if (item.order < currentOrder.order) {
          if (i.order < item.order) {
            return { ...i };
          }
          //@ts-ignore
          if (i.order === currentOrder.order) {
            return { ...i, order: item.order };
          }
          //@ts-ignore
          if (i.order >= item.order && i.order < currentOrder.order) {
            return { ...i, order: i.order + 1 };
          }
          return { ...i };
        } else {
          //@ts-ignore
          if (item.order > currentOrder.order) {
            if (i.order > item.order) {
              return { ...i };
            }
            //@ts-ignore
            if (i.order === currentOrder.order) {
              return { ...i, order: item.order };
            }
            //@ts-ignore
            if (i.order <= item.order && i.order > currentOrder.order) {
              return { ...i, order: i.order - 1 };
            }
            return { ...i };
          }
        }
      });
    } else {
      arr = orderList;
    }

    console.log(item);
    EditOrder({
      list: arr,
      // .map(item => { if(item.id !== 0) return ({ id:item.id,  order:item.order})} )
    }).then(() => {
      VendingAdsHandlet();
      setOrderList(arr);
    });
  }

  const SortOrder = (a: any, b: any) => {
    if (a.order > b.order) {
      return 1;
    } else {
      return -1;
    }
  };

  const VendingAdsHandlet = async () => {
    await GetVendingsWithAds({
      count: count,
      offset: offset,
      // vendingId:currentDevice?.id
      onSuccess: (data: any) => {
        if (vendingsWithAds?.length) {
          setCurrentDeviceLocal(
            data?.find((item: any) => item.id === currentDeviceLocal?.id)
          );
        }
      },
    });
  };

  const deleteAdHandler = () => {
    DeleteAd({
      id: deleteAddd.currentAd?.id,
    }).then(() => {
      setDeletedAddd({ open: false, currentAd: null });
      clearAdvertisingForCurrentVending();
      // getAdsHandler()
      VendingAdsHandlet();
      getAds({
        count: 100000,
        offset: 0,
        vendingId: currentDeviceLocal?.id,
      });
      //  setCurrentIndex(currentAdPV.)
      // handlePrevious()
      //setCurrentIndex(currentIndex === 0 ? ads.length - 1 : currentIndex - 1);
    });
    // useEffect(() => {
    //     console.log(currentIndex)
    // },[currentAdPV])
  };
  const headers = [
    { id: 1, name: "Наименование", mnemo: "name", orderBy: "name" },
    { id: 3, name: "Ативность", mnemo: "ative", orderBy: "active" },
    { id: 2, name: "Порядок", mnemo: "order" },
  ];
  const buttons = [
    {
      id: 1,
      name: "editAd",
      mnemo: "editAd",
      image: (
        <img
          title={"Редактирование"}
          width={25}
          height={25}
          src="/images/editing.svg"
        />
      ),
      function: (val: TAds) => {
        setEditAd({ open: true, currentAd: val });
      },
    },
    {
      id: 2,
      name: "deleteAddd",
      mnemo: "deleteAddd",
      image: (
        <img
          title={"Удалить рекламу"}
          width={25}
          height={25}
          src="/images/trash.svg"
        />
      ),
      function: (val: TAds) => {
        setDeletedAddd({ open: true, currentAd: val });
      },
    },
    // {id:3, name:'active', mnemo:'active', image: currentDevice?.advertisings.find(i => i.active) ? <img width={25} height={25} src='./images/check.svg'/> : <img width={25} height={25} src='./images/error.svg'/>, function:(val:TAds) => {!val.active ? changeIsActive(val.id) : changeIsNotActive(val.id)}}
  ];

  const currentAdPV = currentDeviceLocal?.advertisings[currentIndex];

  const handlePrevious = () => {
    setCurrentIndex(currentIndex === 0 ? ads.length - 1 : currentIndex - 1);
    loadImgReff();
  };
  const handleNext = () => {
    setCurrentIndex(currentIndex === ads.length - 1 ? 0 : currentIndex + 1);
    loadImgReff();
  };

  // //   useEffect(() =>{
  // //     console.log(loading)
  // //   },[loading])
  //   useEffect(() => {
  //     setLoading(true);
  //     setTimeout(() => {
  //       setLoading(false);
  //     }, 2000);
  //     // Promise.all([promiseTime]).then(() => {
  //     //     setTimeout(() => {
  //     //         setLoading(false);
  //     //       }, 2500);
  //     //     })
  //   }, [currentAdPV]);
  // //   useEffect(() => {
  // //     console.log(currentAdPV)
  // //     console.log(currentIndex)
  // //   },[currentAdPV])
  // //   useEffect(()=>{
  // //     console.log(currentIndex)
  // //   },[])
  const loadImgReff = () => {
    setLoading(true);
    if (imageRef.current) {
      imageRef.current.onload = () => setLoading(false);
    }
  };
  useEffect(() => {
    console.log(loading);
  }, [loading]);
  const imageOnErrorHandler = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    event.currentTarget.src = "/images/404.png";
    event.currentTarget.className = "error";
  };

  return (
    <>
      <div className={s.adModalMonitor}>
        <InfoDialog
          open={editAd.open}
          handleClose={() => {
            setEditAd({ open: false, currentAd: null });
          }}
        >
          <AdEditingContainer
            currentAd={editAd.currentAd}
            setEditAd={setEditAd}
            setCurrentDeviceLocal={setCurrentDeviceLocal}
            currentDeviceLocal={currentDeviceLocal}
          />
        </InfoDialog>
        <InfoDialog
          open={deleteAddd.open}
          handleClose={() => {
            setDeletedAddd({ open: false, currentAd: null });
          }}
        >
          <div className={s.forSureDelete}>
            <div className={s.forSureTitle}>
              Вы действительно хотите удалить рекламу "
              {deleteAddd.currentAd?.name}"?
            </div>
            <div className={s.forSureButton}>
              <Button
                text="Удалить"
                onClick={() => {
                  deleteAdHandler();
                }}
              />
            </div>
          </div>
        </InfoDialog>

        <div className={s.AddHeader}>
          Реклама по вендинговому аппарату{" "}
          {currentDeviceLocal?.nameNumber
            ? `"${currentDeviceLocal?.nameNumber}"`
            : ""}
        </div>

        <div className={s.table}>
          {currentDeviceLocal?.advertisings ? (
            <>
              <div className={s.tableWithCheck}>
                <Table
                  buttons={buttons}
                  headers={headers}
                  body={customAds}
                  sizes={[40, 20, 10, 5]}
                  greyRow={{
                    className: s.badRowClassName,
                    customId: "active",
                    matchIds: adsErrors,
                  }}
                  sort={currentSort}
                />
              </div>
            </>
          ) : (
            setAdsModal({ open: false })
          )}

          <div className={s.checkBoxForTable}>
            {currentDeviceLocal?.advertisings &&
              currentDeviceLocal?.advertisings.length > 0 &&
              currentDeviceLocal?.advertisings.map((item) => (
                <>
                  <Checkbox
                    className={s.checkb}
                    checked={item.active}
                    onClick={() => {
                      !item.active
                        ? changeIsActive(item.id)
                        : changeIsNotActive(item.id);
                    }}
                  ></Checkbox>
                </>
              ))}
          </div>
        </div>

        <div>
          <div className={s.Adss}>
            <div className={s.adsss}>
              <div className={s.Filess}>
                <div className={s.photoslide}>
                  {currentAdPV?.typeId == 2 ? (
                    <>
                      <div className={s.blockWImg}>
                        <label className={s.textImg}>{currentAdPV.name}</label>
                        <div className={s.smoothImageWrapper}>
                          <img
                            className={`${s.smoothImage} ${
                              loading ? s.imageHidden : s.imageVisible
                            }`}
                            onLoad={() => setLoading(false)}
                            width={256}
                            height={455}
                            src={currentAdPV.fileUrl}
                            alt=""
                            ref={imageRef}
                          />
                          {/* {loading && (
                                        <div className={s.smoothPreloader}>
                                            <div className={s.loader} />
                                        </div>
                                    )} */}
                        </div>
                      </div>
                    </>
                  ) : currentAdPV?.typeId == 3 ? (
                    <>
                      <div className={s.blockWImg}>
                        {/* <label className={s.textVid}>{currentAdPV?.name}</label> */}
                        <label className={s.textVid}>{currentAdPV?.name}</label>
                        <div className={s.smoothImageWrapper}>
                          <video
                            className={s.img}
                            width={256}
                            height={455}
                            src={currentAdPV?.fileUrl}
                            controls
                            style={{ objectFit: "cover" }}
                          />
                          {/* <img    
                                    className={`${s.smoothImage} ${loading ? s.imageHidden :  s.imageVisible}`}
                                    onLoad={()=> setLoading(true)}
                                    width={256} 
                                    height={455} 
                                    src={ currentDeviceLocal?.advertisings[0].fileUrl} 
                                    alt="" 
                                    />  */}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={s.blockWImg}>
                        <label className={s.textVid}>
                          {currentDeviceLocal?.advertisings[0].name}
                        </label>
                        <div className={s.smoothImageWrapper}>
                          <img
                            className={`${s.smoothImage} ${
                              loading ? s.imageHidden : s.imageVisible
                            }`}
                            onLoad={() => setLoading(false)}
                            onError={imageOnErrorHandler}
                            width={256}
                            height={455}
                            src={currentDeviceLocal?.advertisings[0].fileUrl}
                            alt=""
                          />
                        </div>
                      </div>
                    </>
                  )}
                  <div className={s.LeftBlock}>
                    <label className={s.orderText}>управление порядком</label>
                    {orderList &&
                      orderList?.length > 0 &&
                      orderList.sort(SortOrder).map((item) => (
                        // orderList && orderList?.length > 0 && orderList.sort((a, b) => b.order > a.order  ? b.order - a.order : a.order - b.order).map(item =>
                        <div
                          className={s.orderList}
                          draggable={true}
                          onDragStart={(e) => dragStartHandler(e, item)}
                          onDragLeave={(e) => dragLeaveHandler(e)}
                          onDragEnd={(e) => dragEndHandler(e)}
                          onDragOver={(e) => dragOverHandler(e)}
                          onDrop={(e) => dropHandler(e, item)}
                        >
                          {/* <span className={s.orderNumber}>{item.order}.</span> */}
                          {item.order}. {item.name}
                          {/* {item.order} */}
                        </div>
                      ))}
                  </div>
                </div>
                {/* <div className={s.Arrr}>
                            <button onClick={handlePrevious} className={s.buttomsArrowsl}>
                            ←
                            </button>
                            
                            <button  onClick={handleNext} className={s.buttomsArrowsr}>
                             →
                            </button>
                            </div> */}

                {currentDeviceLocal?.advertisings.length !== 1 ? (
                  // currentDeviceLocal?.advertisings?.length > 1 ?
                  <div className={s.Arrr}>
                    <button
                      onClick={handlePrevious}
                      className={`${s.buttomsArrowsl} ${
                        ads.length > 1 ? s.buttomsArrowsl : s.buttomsArrowsldis
                      }`}
                    >
                      ←
                    </button>

                    <button
                      onClick={handleNext}
                      className={`${s.buttomsArrowsr} ${
                        ads.length > 1 ? s.buttomsArrowsr : s.buttomsArrowsrdis
                      }`}
                    >
                      →
                    </button>
                  </div>
                ) : (
                  <div className={s.Arrr}>
                    <button
                      disabled
                      onClick={handlePrevious}
                      className={s.buttomsArrowsldis}
                    >
                      ←
                    </button>

                    <button
                      disabled
                      onClick={handleNext}
                      className={s.buttomsArrowsrdis}
                    >
                      →
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state: TRootState) => ({
  showMoreAd: state.ad.showMoreAd,
  ads: state.ad.ads,
  vendingsWithAds: state.ad.advertisingForCurrentVendings,
  // vendingDevices: state.devices.vendingDevices
});

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    getAds: (body: TGetAds) => dispatch(getAds(body)),
    EditAd: (body: TEditAd) => dispatch(EditAd(body)),
    DeleteAd: (body: TDeleteAd) => dispatch(deleteAd(body)),
    clearAdvertisingForCurrentVending: () =>
      dispatch(clearAdvertisingForCurrentVending()),
    GetVendingsWithAds: (body: TVendingsWithAd) =>
      dispatch(GetVendingsWithAds(body)),
    activeAd: (body: TActiveAd) => dispatch(ActiveAd(body)),
    EditOrder: (body: TEditOrder) => dispatch(EditOrder(body)),
  };
};

export const AdModContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdsMon);
