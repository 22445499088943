import React, {useEffect, useState} from 'react'
import clsx from 'clsx'
import styles from "./multipleSelect.module.scss";
import { TextInput } from './TextInput';
import classes from './selects.module.scss'

// const classes = require('./selects.module.scss')

export interface ISelectDataItem {
    // null - выберет все, если подать
    id: any
    name: string
    upDown?: "up" | "down"
    mnemo?: string

    [key: string]: any
}

interface IMultipleSelect {
    readonly items: ISelectDataItem[]
    selectedItems: ISelectDataItem[]
    setSelected: IsetSelectedMultipleSelect
    type?: 'dark' | 'light'
    width?: string
    height?: string
    border?: string
    className?: string
    classNameMainSelect?:string
    anotherClassName?: string
    placeholder?: string
    multiple?: boolean
    above? : boolean
    listHeight?: string
    listWidth?: string

    OkWidth?: string

    [props: string]: any
}

interface IsetSelectedMultipleSelect {
    (val: ISelectDataItem[]): void
}

interface IMultipleSelectItem {
    checked: boolean
    item: ISelectDataItem
    items: ISelectDataItem[]
    selectedItems: ISelectDataItem[]
    setSelected: (val: ISelectDataItem[]) => void
    multiple?: boolean,
    disabled?: boolean,
    allowSearch?: boolean,
    OkWidth?: string
}

export const MultipleSelect: React.FC<IMultipleSelect> = ({
                                                              width = 230,
                                                              height,
                                                              above = false,
                                                              type = 'light',
                                                              items,
                                                              listHeight,
                                                              listWidth,
                                                              OkWidth,
                                                              selectedItems,
                                                              setSelected,
                                                              className,
                                                              anotherClassName,
                                                              placeholder,
                                                              multiple,
                                                              classNameMainSelect,
                                                              border,
                                                              disabled = false,
                                                              allowSearch = false,
                                                              ...props
                                                          }) => {
    const [opened, setOpened] = useState<boolean>(false)
    const classNames = clsx({
        [classes.main_select__dark]: type === 'dark',
        [classes.main_select__light]: type === 'light'
    }, classes.main_select)

    const [inputValue, setInputValue] = useState<string>('')
    const [filteredItems, setFilteredItems] = useState<ISelectDataItem[] | []>(items)

    useEffect(() => {
        if(inputValue){
            setFilteredItems(items.filter(item => 
                item.name.toLowerCase().includes(inputValue.toLowerCase())  
            ))
        } else {
            setFilteredItems(items)
        }
    }, [inputValue, items])

    return (
        <div className={classes.container + ' ' + className}
             {...props}
             style={disabled ? {cursor:'not-allowed', ...props.style, width, height} : {...props.style, width, height}}
             onBlur={() => {!allowSearch && setOpened(false)}}
             tabIndex={0}>
            {!allowSearch ? 
            <div className={classNames + ' ' + classNameMainSelect}
                style={disabled ? {background: '#f5f5f5'} : border ? {border: border} : {}}
                onClick={() => {
                    !disabled &&
                    setOpened(!opened)
                }}>
                {selectedItems?.length === 0 && 
                <span className={classes.muted_text} style={{justifySelf:"flex-end"}}>{placeholder ? placeholder : 'Выберите'}</span>}
                {
                    (selectedItems?.length === 1 && selectedItems[0]?.upDown) ?
                        <div className={anotherClassName}>
                            <div style={{minWidth: "58px", maxWidth:"80px"}}><span className={classes.muted_text}>{selectedItems[0]?.name}</span></div>
                            {
                                selectedItems[0]?.upDown === "up" ? 
                                    <div className={classes.select_item__updown_main}>
                                        <img className={classes.select_item__updown_img} src="/images/arrowUp.svg" alt="arrowUp" />
                                    </div> : 
                                    <div className={classes.select_item__updown_main}>
                                        <img className={classes.select_item__updown_img} src="/images/arrowDown.svg" alt="arrowUp" />
                                    </div> 
                            }
                        </div>
                    :  selectedItems?.length === 1 && <span className={classes.muted_text}>{selectedItems[0]?.name ? selectedItems[0]?.name : ""}</span>
                    
                }
                {selectedItems?.length > 1 && selectedItems[0]?.id !== null && (
                    <span>Выбрано: {selectedItems.length}</span>
                )}
                {selectedItems?.length > 1 && selectedItems[0]?.id === null && (
                    <span>{selectedItems[0]?.name}</span>
                )}

                <img src='./images/dropdown.svg' className={styles.buttons}
                          style={{height: 12, width: 12, transform: opened ? 'rotate(-180deg) translateY(50%)' : 'translateY(-50%)'}}
                          alt="dropdown"/>
            </div>
            :
            <div
                className={selectedItems?.length === 0 ? classes.selectWithInput + ' ' + classNameMainSelect : classNames + ' ' + classNameMainSelect}
                style={disabled ? {background: '#f5f5f5'} : border ? {border: border} : {}}
                onClick={() => {
                    !disabled &&
                    setOpened(!opened)
                }}
                >
                {
                selectedItems?.length === 0 && 
                <TextInput
                    width={width}
                    height={height}
                    value={inputValue}
                    setValue={setInputValue}
                    placeholder={placeholder ? placeholder : 'Выберите'}
                    onClick={() => {
                        !disabled &&
                        setOpened(!opened)
                    }}
                />
                }
                {
                    (selectedItems?.length === 1 && selectedItems[0]?.upDown) ?
                        <div className={anotherClassName}>
                            <div style={{minWidth: "58px", maxWidth:"80px"}}><span className={classes.muted_text}>{selectedItems[0]?.name}</span></div>
                            {
                                selectedItems[0]?.upDown === "up" ? 
                                    <div className={classes.select_item__updown_main}>
                                        <img className={classes.select_item__updown_img} src="/images/arrowUp.svg" alt="arrowUp" />
                                    </div> : 
                                    <div className={classes.select_item__updown_main}>
                                        <img className={classes.select_item__updown_img} src="/images/arrowDown.svg" alt="arrowUp" />
                                    </div> 
                            }
                        </div>
                    :  selectedItems?.length === 1 && <span className={classes.muted_text}>{selectedItems[0]?.name ? selectedItems[0]?.name : ""}</span>
                    
                }
                {selectedItems?.length > 1 && selectedItems[0]?.id !== null && (
                    <span>Выбрано: {selectedItems.length}</span>
                )}
                {selectedItems?.length > 1 && selectedItems[0]?.id === null && (
                    <span>{selectedItems[0]?.name}</span>
                )}

                {/* <Dropdown className={styles.buttons}
                          style={{height: 12, width: 12, transform: opened ? 'rotate(-180deg) translateY(50%)' : 'translateY(-50%)'}}
                          alt="dropdown"/> */}
            </div>
            
            }

            {
                opened && (
                    <div className={above ? classes.options_container__above : classes.options_container}
                         style={listWidth ? {width: listWidth, top: `-${listHeight}`, height : listHeight} :
                             {top: `-${listHeight}`, height : listHeight}}>
                        {
                            !allowSearch ? 
                            items?.map(item => (
                                <MultipleSelectItem
                                    checked={!!selectedItems?.find(selectedItem => selectedItem?.id === item?.id)}
                                    selectedItems={selectedItems}
                                    setSelected={(val)=> {
                                        multiple &&
                                        setOpened(false)
                                        setSelected(val)
                                    }}
                                    OkWidth={OkWidth}
                                    item={item}
                                    items={items}
                                    multiple={multiple}
                                />
                            )) 
                            :
                            filteredItems?.map(item => (
                                <MultipleSelectItem
                                    checked={!!selectedItems?.find(selectedItem => selectedItem?.id === item?.id)}
                                    selectedItems={selectedItems}
                                    setSelected={(val)=> {
                                        multiple &&
                                        setOpened(false)
                                        setSelected(val)
                                    }}
                                    OkWidth={OkWidth}
                                    item={item}
                                    items={filteredItems}
                                    multiple={multiple}
                                />
                            ))
                        }
                    </div>
                )
            }
        </div>
    )
}

const MultipleSelectItem: React.FC<IMultipleSelectItem> = ({
                                                               checked,
                                                               setSelected,
                                                               item,
                                                               items,
                                                               selectedItems,
                                                               multiple,
                                                               OkWidth
                                                           }) => {


    const selectItem = (val: boolean) => {
        if (val) {
             
            item?.id === null
                ? setSelected(items)
                : multiple ? setSelected([item]) :
                    selectedItems ? setSelected([...selectedItems, item]) : setSelected([item])
        } else {
            item?.id === null
                ? setSelected([])
                : setSelected(selectedItems
                    .filter(selectedItem => selectedItem.id !== item.id)
                    .filter(item => item.id !== null)
                )
        }
    }


    if (item?.upDown){
    return (
        <div className={classes.select_item + " " + classes.select_item_with_updown} onClick={() => {
            selectItem(!checked)
        }}>
            <div className={classes.select_label}>{item.name}</div>
            {
                item?.upDown === "up" ? <div className={classes.select_item__updown}>
                    <img src='/images/arrowUp.svg'
                                                    height={12}
                                                    width={8}
                                                    alt="search"/>
                </div> : 
                <div className={classes.select_item__updown}>
                    <img src='/images/arrowDown.svg'
                                                    height={12}
                                                    width={8}
                                                    alt="search"/>
                </div> 
            }
            {selectedItems?.find(filteredItem => filteredItem?.id === item?.id) &&
            <img src={'./images/ok.svg'} className={styles.buttons} style={{height: 16, width: OkWidth ? OkWidth : 16}} alt="ok"/>}
        </div>
    )}
    else{
    return (
        <div className={item?.iconUrl ? classes.select_item + ' ' + classes.select_item_with_img : classes.select_item} onClick={() => {
            selectItem(!checked)
        }}>
            <div className={classes.select_label}>{item?.name}</div>
            {item?.iconUrl ? <div className={classes.select_label}><img width={30} src={item?.iconUrl}/></div> : null}
            {selectedItems?.find(filteredItem => filteredItem?.id === item?.id) &&
            <img src={'./images/ok.svg'} className={styles.buttons} style={{height: 16, width: OkWidth ? OkWidth : 16}} alt="ok"/>}
        </div>
    )}
}
