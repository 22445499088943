
import { DatePicker, Input, InputNumber, Radio, RadioChangeEvent } from 'antd'
import moment, { Moment } from 'moment'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { NULL } from 'sass'
import { CreateVending, EditVending, getVendingOperations, getVendings } from '../../store/slices/DevicesSlice/DevicesSlice'
import { TCreateVending, TEditVending, TGetVendings, TOperationJob, TVendingDevice, TVendingOperation } from '../../store/slices/DevicesSlice/DevicesSliceTypes'
import { AppDispatch, TRootState } from '../../store/store'
import useDebouncedFunction, { operationsErrors } from '../../store/utils/helpers'
import { Button } from '../buttons/button'
import { InfoDialog } from '../dialogs/InfoModal'
import { TextInput } from '../inputs/TextInput'
import { Table, TSort } from '../Table/Table'
import { TActivateUser, TEditUser, TGetRoles, TGetUser, TGetUsers, TRoles, TUsers } from '../../store/slices/UsersSlice/UsersSliceTypes'
import { Select } from 'antd'
import { getUser, EditUser, Active, getRoles, getUsers } from '../../store/slices/UsersSlice/UserSlice'
import s from './versions.module.scss'
import { ISelectDataItem, MultipleSelect } from '../inputs/MultipleSelect'
import { PhoneInput } from '../inputs/MaskInput'
import { openNotification } from '../notifications/notification'
import { TCreatVer, TGetVer } from '../../store/slices/VersionSlice/VersionSliceType'
import { CreateVer, getVer } from '../../store/slices/VersionSlice/VersionSlice'

interface IVendigEdit {
    createVer:Function
    setVerCreating:Function
    getVersions:Function
    

    
}

const VerCreating:React.FC<IVendigEdit> = ({createVer, setVerCreating, getVersions}) =>{
        const [version,  setVersion] = useState<number | undefined>()
        const [count, setCount] = useState<number>(30)
        const [offset, setOffset] = useState<number>(0)
       
        
        
            
       

        const changeVer = (value: number | undefined) => {
            setVersion(value)
        }
        


        const saveVer = () => {
            if (!version) {
                return openNotification({type: 'warning', message: 'Не заполнено поле с версией!'})
            }
       
            createVer({
                version: version
                
            }).then(() =>{
                getVersions({
                    offset:offset,
                    count:count,
                })
            })
            setVerCreating({open:false})
       
        }
    return (
        <div className={s.editVendingModal}>

                <label 
                style={{marginBottom:'-25px'}}
                    htmlFor="port">
                        Версия
                </label>
                <InputNumber
                    value={version}
                    onChange={changeVer}
                    // onKeyPress={blockInvalidChars}
                    id="version"/>

                <Button text='Сохранить' onClick = {()=>saveVer()}/>
        </div>
    )
}

const mapDispatchToProps = (dispatch :AppDispatch) => {
    return {
        getVersions: (body: TGetVer) => dispatch(getVer(body)),
        createVer: (body: TCreatVer) => dispatch(CreateVer(body)),
    }
    
        
    
}
export const VerCreatingContainer = connect(null, mapDispatchToProps)(VerCreating)