import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { openNotification } from "../../../components/notifications/notification"
import { instanceAxiosClose } from "../../api/instances"
import { paths } from "../../api/paths"
import { checkError } from "../../utils/checkErrors"
import { getInlineParams } from "../../utils/helpers"
import { AdState, TEditAd,TCreateAd, TFileAdvertisement, TGetAd, TGetAds, TDeleteAd, TVendingsWithAd, TActiveAd, TEditOrder } from "./AdSliceTypes"

export const getAds = createAsyncThunk("getAdss", async (body: TGetAds) => {
    try {
        const { data } = await instanceAxiosClose(localStorage.getItem('CURRENT_SERVICE_URL')).get(paths.advertisingService.getAd + getInlineParams(body))
        return data?.data.value
    } catch (err: any) {
        checkError(err)
    }
})
export const getAd = createAsyncThunk("getAd", async (body: TGetAd) => {
    try {
        const { data } = await instanceAxiosClose(localStorage.getItem('CURRENT_SERVICE_URL')).get(paths.advertisingService.getSoloAd + getInlineParams(body))
        return data?.data.value
    } catch (err: any) {
        checkError(err)
    }
})

export const saveFileAd = createAsyncThunk('saveFileAd', async (body: TFileAdvertisement) =>{
    try{
        const { data } = await instanceAxiosClose(localStorage.getItem('CURRENT_SERVICE_URL')).post(paths.advertisingService.saveFiles, body)
        return data?.data
    } catch (err: any){
        checkError(err)
    }
})
//@ts-ignore
export const SaveFileAdd = createAsyncThunk<TFileAdvertisement, {formData: any}>("file/SaveFileByte", async (formData) => {
    try {
        console.log(formData)
        // const { data } = await instanceAxiosCloseFormData().post(paths.fileSevice.images, 
       
        // )
        const newFormData = new FormData()
       
        newFormData.append('Files', formData.formData.Files)
        
        newFormData.append('Json', formData.formData.Json)
        var myHeaders = new Headers({Authorization:`Bearer ${String(localStorage.getItem('ACCESS_TOKEN'))}`})
        // const prom = fetch(localStorage.getItem('CURRENT_SERVICE_URL') + paths.advertisingService.saveFiles, {
        const prom = fetch(paths.baseURL + paths.advertisingService.saveFiles, {
            method: 'POST',
            headers:  myHeaders,
            // Authorization: `Bearer ${String(localStorage.getItem('ACCESS_TOKEN'))}`,
            body: newFormData,
        }
        )
        
        return prom
    } catch (err) {
        
        checkError(err)
        
    }
})
export const EditAd = createAsyncThunk("editAd", async (body: TEditAd) => {
    try {
        const { data } = await instanceAxiosClose(localStorage.getItem('CURRENT_SERVICE_URL')).post(paths.advertisingService.editAd, body)
        return data
    } catch (err: any) {
        checkError(err)
    }
})
export const EditOrder = createAsyncThunk("editOrder", async (body: TEditOrder) => {
    try {
        const { data } = await instanceAxiosClose(localStorage.getItem('CURRENT_SERVICE_URL')).post(paths.advertisingService.editOrder, body)
        return data
    } catch (err: any) {
        checkError(err)
    }
})
export const CreateAd = createAsyncThunk("createAd", async (body: TCreateAd) => {
    try {
        const { data } = await instanceAxiosClose(localStorage.getItem('CURRENT_SERVICE_URL')).post(paths.advertisingService.createAd, body)
        return data
    } catch (err: any) {
        checkError(err)
    }
})
export const deleteAd = createAsyncThunk("deleteAd", async (body: TDeleteAd) => {
    try {
        const { data } = await instanceAxiosClose(localStorage.getItem('CURRENT_SERVICE_URL')).post(paths.advertisingService.deleteAd, body)
        return data?.data
    } catch (err: any) {
        checkError(err)
    }
})

export const GetVendingsWithAds = createAsyncThunk("getAdvertisingsforVendings", async (body: TVendingsWithAd) => {
    try {
        const { data } = await instanceAxiosClose(localStorage.getItem('CURRENT_SERVICE_URL')).get(paths.advertisingService.getVendingsWithAds + getInlineParams(body))
        return { data: data?.data.value, onSuccess: body?.onSuccess }
    } catch (err: any) {
        checkError(err)
    }
})
export const ActiveAd = createAsyncThunk("ad/activation", async(body:TActiveAd) => {
    try {
        const { data } = await instanceAxiosClose(localStorage.getItem('CURRENT_SERVICE_URL')).post(paths.advertisingService.adActive, body)
        return data
    } catch (err: any) {
        checkError(err)
    }
})


const initialState:AdState = {
    advertisingForCurrentVendings:[],
    showMoreAd: true,
    ads:[],
    ad:null,
    loaders:{
        adLoading:  {state: false, text: ''},
        editingAdLoading:  {state: false, text: ''},
        createAdLoading:  {state: false, text: ''},
        saveFileAdLoading: {state: false, text: ''},
        adWithVendingsLoading: {state: false, text: ''},
    }
}


export const adSlice = createSlice({
    name:"AdSlice",
    initialState,
    reducers:{
        deleteAdd(state, { payload }) {
            state.ads = state.ads.filter(n => n !== payload)
        },
        clearAdvertisingForCurrentVending(state){
            state.advertisingForCurrentVendings = []
        }

    },
    extraReducers:(builder) => {

        builder.addCase(getAds.pending, (state, action) => {
            state.loaders.adLoading = {state: true, text: 'Загрузка реклам'}
            
        })
        builder.addCase(getAds.fulfilled, (state, action) => {
            state.loaders.adLoading = {state: false, text: 'Загрузка реклам'}
            if(action.meta.arg.offset === 0 && action.payload.length){
                state.ads = action.payload
                if(action.payload.length < action.meta.arg.count){
                    state.showMoreAd = false
                }
            } else  
                if (action.payload.length){
                    state.ads = [...state.ads, action.payload]
                    if(action.payload.length < action.meta.arg.count){
                        state.showMoreAd = false
                    }
                }
                    
        })
        builder.addCase(getAds.rejected, (state, action) => {
            state.loaders.adLoading = {state: false, text: 'Загрузка реклам'}
            state.showMoreAd = false
        })
        builder.addCase(SaveFileAdd.rejected, (state, action) => {
            state.loaders.saveFileAdLoading = {state: false, text: 'Загрузка файла'}
        })
        builder.addCase(SaveFileAdd.pending, (state, action) => {
            state.loaders.saveFileAdLoading = {state: true, text: 'Загрузка файла'}
        })
        builder.addCase(SaveFileAdd.fulfilled, (state, action) => {
            state.loaders.saveFileAdLoading = {state: false, text: 'Загрузка файла'}
            if(action.payload){
                openNotification({type: 'success', message: 'Файл успешно загружен'})
            }
        })
        builder.addCase(EditAd.pending,(state, action) =>{
            state.loaders.editingAdLoading = {state: true, text:'Редактирование рекламы'}
        })
        builder.addCase(EditAd.fulfilled, (state, action) => {
            state.loaders.editingAdLoading = {state: false, text: 'Редактирование рекламы'}
            if(action.payload){
                openNotification({type: 'success', message: 'Реклама изменена'})
            }
        })
        builder.addCase(EditAd.rejected, (state, action) => {
            state.loaders.editingAdLoading = {state: false, text: 'Редактирование рекламы'}
        })
        builder.addCase(CreateAd.pending,(state, action) =>{
            state.loaders.createAdLoading = {state: true, text:'Создание рекламы'}
        })
        builder.addCase(CreateAd.fulfilled, (state, action) => {
            state.loaders.createAdLoading = {state: false, text: 'Создание рекламы'}
            if(action.payload){
                openNotification({type: 'success', message: 'Реклама создана'})
            }
        })
        builder.addCase(CreateAd.rejected, (state, action) => {
            state.loaders.createAdLoading = {state: false, text: 'Создание рекламы'}
        })

        builder.addCase(GetVendingsWithAds.pending, (state, action) => {
            state.loaders.adWithVendingsLoading = {state: true, text: 'Получение запросов на вендинги с рекламой'}
        })
        builder.addCase(GetVendingsWithAds.fulfilled, (state, action) => {
            state.loaders.adWithVendingsLoading = {state: false, text: 'Получение запросов на вендинги с рекламой'}
            if(action.meta.arg.offset === 0 && action?.payload?.data.length){
                state.advertisingForCurrentVendings = action?.payload?.data
                if(action?.payload?.data.length < action.meta.arg.count){
                    state.showMoreAd = false
                }
            }else 
                    if(action?.payload?.data.length){
                        state.advertisingForCurrentVendings = [...state.advertisingForCurrentVendings, action.payload.data]
                        if(action.payload.data.length < action.meta.arg.count){
                            state.showMoreAd = false
                        }
                    }
            if(action?.payload?.onSuccess){
                action.payload.onSuccess(action.payload.data)
            }
        })
        // builder.addCase(getAds.fulfilled, (state, action) => {
        //     state.loaders.adLoading = {state: false, text: 'Загрузка реклам'}
        //     if(action.meta.arg.offset === 0 && action.payload.length){
        //         state.ads = action.payload
        //         if(action.payload.length < action.meta.arg.count){
        //             state.showMoreAd = false
        //         }
        //     } else  
        //         if (action.payload.length){
        //             state.ads = [...state.ads, action.payload]
        //             if(action.payload.length < action.meta.arg.count){
        //                 state.showMoreAd = false
        //             }
        //         }
                    
        // })
        builder.addCase(GetVendingsWithAds.rejected, (state, action) => {
            state.loaders.adWithVendingsLoading = {state: false, text: 'Получение запросов на вендинги с рекламой'}
            state.showMoreAd = false
        })


    }
})

export const {deleteAdd, clearAdvertisingForCurrentVending} = adSlice.actions
export default adSlice.reducer