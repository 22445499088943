
import { Checkbox, DatePicker, Input, InputNumber, Radio, RadioChangeEvent, Switch } from 'antd'
import moment, { Moment } from 'moment'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { NULL } from 'sass'
import { BlockVend, EditVending, getVendingOperations, getVendings } from '../../store/slices/DevicesSlice/DevicesSlice'
import { TBlockVending, TEditVending, TGetVendings, TOperationJob, TVendingDevice, TVendingOperation } from '../../store/slices/DevicesSlice/DevicesSliceTypes'
import { AppDispatch, TRootState } from '../../store/store'
import useDebouncedFunction, { operationsErrors } from '../../store/utils/helpers'
import { Button } from '../buttons/button'
import { InfoDialog } from '../dialogs/InfoModal'
import { TextInput } from '../inputs/TextInput'
import { Table, TSort } from '../Table/Table'
import { TActivateUser, TEditUser, TGetRoles, TGetUser, TGetUsers, TRoles, TUsers } from '../../store/slices/UsersSlice/UsersSliceTypes'
import { Select } from 'antd'
import { getUser, EditUser, Active, getRoles, getUsers } from '../../store/slices/UsersSlice/UserSlice'
import s from './devices.module.scss'
import { ISelectDataItem, MultipleSelect } from '../inputs/MultipleSelect'
import { PhoneInput } from '../inputs/MaskInput'
import { HavePermission } from '../difficultFunc/HavePerm'
import { openNotification } from '../notifications/notification'

interface IVendigEdit {
    currentVendingDevice : TVendingDevice | null
    // Active: Function
    getUsers:Function
    users: TUsers[] | []
    EditVending:Function
    BlockVending:Function
    setEditingVendingModal:Function
    getVendings:Function

    
}

const VendingEditing:React.FC<IVendigEdit> = ({users,  currentVendingDevice,EditVending, getUsers, setEditingVendingModal, getVendings, BlockVending}) =>{
        const [leftPaper,  setLeftPaper] = useState<number | undefined>()
        const [leftToner,  setLeftToner] = useState<number | undefined>()
        const [selectedUser, setSelectedUser] = useState<ISelectDataItem[]>([])
        const [showtime, setShowtime] = useState<number | undefined>()
        const [downtime, setDowntime] = useState<number | undefined>()
        const [operationPrice, setOperationPrice] = useState<number | undefined>()
        const [phoneTp, setPhoneTp] = useState(currentVendingDevice?.phoneTp || '')
        const [currentUser, setCurrentUser] = useState<string | undefined>()
        const [org, setOrg] = useState <number | undefined>()
        const [processed, setProcessed] = useState<boolean>(true)
        const [count, setCount] = useState<number>(30)
        const [offset, setOffset] = useState<number>(0)
        const [block, setBlock] = useState<any>()

   

        useEffect(() => {
            setLeftPaper(currentVendingDevice?.leftPaper)
            setLeftToner(currentVendingDevice?.leftToner)
            setOrg(currentVendingDevice?.userId)
            setShowtime(currentVendingDevice?.showtime)
            setDowntime(currentVendingDevice?.downtime)
            setOperationPrice(currentVendingDevice?.operationPrice)
            setBlock(currentVendingDevice?.isBlocked)
            // setPhoneTp(currentVendingDevice?.phoneTp)
            // setOrg(currentVendingDevice?.name)
        
        }, [currentVendingDevice])
       
        const getUsersHandler = () => {
            getUsers({
                count:10000,
                offset:0
            })
        }
        
            
        useEffect(() => {
            getUsersHandler()
        },[])

      

        const changeLeftPaper = (value: number | undefined) => {
            setLeftPaper(value)
        }
        const changeLeftToner = (value: number | undefined) => {
            setLeftToner(value)
        }
        const changeShowtime = (value: number | undefined) => {
            setShowtime(value)
        }
        const changeDowntime = (value: number | undefined) => {
            setDowntime(value)
        }
        const changeOperationPrice = (value: number | undefined) => {
            setOperationPrice(value)
        }
        const changePhoneTp = (e: React.ChangeEvent<HTMLInputElement>) => {
            setPhoneTp(e.target.value)
        }

        
        const changeUser = (value: number) => {
            setOrg(value)
        }
        const getVendingsHandler = () => {
            getVendings({
                
                    count: count,
                    offset: offset,
                    Processed: processed,
               

            })
        }

        const changeIsActive = () => {
            if(block == 'Работает')
            {
                setBlock('Заблокирован')
            }else{
                setBlock('Работает')
            }
            BlockVending({
                id:currentVendingDevice?.id
            })
            .then(() =>{
                getVendings({
                    offset:offset,
                    count:count,
                    Processed: processed
                })
            })
            // console.log(block)
        }
        const changeIsNotActive = () => {
            
            // .then(() =>{
            //     getVendings({
            //         offset:offset,
            //         count:count,
            //         Processed: processed
            //     })
            // })
        }
        const saveUser = () => {
            if(downtime && downtime > 180) {
                debugger
                EditVending({
                    vendingId: currentVendingDevice?.id,
                    leftPaper: leftPaper,
                    leftToner: leftToner,
                    contractorId :org  ,
                    downtime: downtime,
                    showtime: showtime,
                    // name:currentUser
                    userId:selectedUser[0]?.id,
                    operationPrice: operationPrice,
                    phoneTp: phoneTp
                    
                }).then(() =>{
                    getVendings({
                        offset:offset,
                        count:count,
                        Processed: processed
                    })
                })
                setEditingVendingModal({open:false})
                return openNotification({type: 'success', message: 'Успешно'})
            } else {
                return openNotification({type: 'warning', message: 'Время простоя не может быть меньше 180 секунд!'})
                debugger
            }

            
            // .then(() => { 
            //     if(getVendingsHandler() !== undefined)
            //     getVendingsHandler()
            //     })
            

            
            
        }
        // useEffect(() =>{
        //     getVendingsHandler()
        // },[])


    return (
        <div className={s.editVendingModal}>
            <div className={s.count}>
                <div className={s.paper}>
                <label
                    // style={{marginBottom:'-25px'}}
                    htmlFor="name">
                    Осталось бумаги 
                </label>
                <InputNumber
                    disabled
                    value={leftPaper}
                    onChange={changeLeftPaper}
                    // {(val) => {changeLeftPaper(val.currentTarget.value ? Number(val.currentTarget.value) : 0)}}
                    id="leftPaper"/>
                </div>
                <div className={s.toner}>
                 <label
                    // style={{marginBottom:'-25px'}}
                    htmlFor="leftPaper">
                    Осталось тонера
                </label>
                <InputNumber
                    disabled
                    value={leftToner}
                     onChange={changeLeftToner}
                    id="email"/>
                </div>
            </div>
            <div className={s.time}>
                <div className={s.advert}>
                <label
                    // style={{marginBottom:'-25px'}}
                    htmlFor="showtime">
                    Время показа рекламы
                </label>
                <InputNumber
                    value={showtime}
                     onChange={changeShowtime}
                    id="showtime"/>
                </div>
                <div className={s.downtime}>
                <label
                    // style={{marginBottom:'-25px'}}
                    htmlFor="downtime">
                    Время простоя
                </label>
                <InputNumber
                    value={downtime}
                     onChange={changeDowntime}
                    id="showtime"/>
                </div>
            </div>
                <label
                    style={{marginBottom:'-25px'}}
                    htmlFor="price">
                    Стоимость операции
                </label>
                <InputNumber
                    value={operationPrice}
                     onChange={changeOperationPrice}
                    id="price"/>
                 <div className={s.phone}>
                    <label 
                    // style={{marginBottom:'5px'}}
                    htmlFor="phone">
                    Телефон тп
                </label>
               
                <PhoneInput
                    width={150}
                    value={phoneTp}
                    setValue={setPhoneTp}
                    id="phone"/>
                </div>
                <Select 
                        dropdownClassName={s.CustomDropDown}
                        showSearch 
                        allowClear
                        onChange={changeUser} 
                        value={org || undefined}
                        optionFilterProp="children"
                        style={{marginLeft:'20px', width:'250px'}}
                        placeholder="Выберите пользователя">
                            {users.map(user => 
                                    <Select.Option 
                                        key={user.id} 
                                        value={user.id}>
                                        {user?.name}
                                    </Select.Option>    
                                )}
                </Select>
                <HavePermission mnemoName={'users'}>
                    <div className={s.block}>
                        Активность
                        <Switch
                          style={{alignItems:"center", marginTop:"10px"}}
                          checked={block == 'Работает' ? true : false}
                          onChange={() => {changeIsActive()}}
                        />
                    </div>
                </HavePermission>

                <Button text='Сохранить' onClick = {()=>saveUser()}/>
        </div>
    )
}
const mapStateToProps = (state: TRootState) => ({
    users: state.users.users
})
const mapDispatchToProps = (dispatch :AppDispatch) => {
    return {
        getUsers: (body: TGetUsers) => dispatch(getUsers(body)),
        EditVending: (body: TEditVending) => dispatch(EditVending(body)),
        getVendings: (body: TGetVendings) => dispatch(getVendings(body)),
        BlockVending: (body: TBlockVending) => dispatch(BlockVend(body))
    }
    
        
    
}
export const VendingEditingContainer = connect(mapStateToProps, mapDispatchToProps)(VendingEditing)