import React, { CSSProperties, useEffect } from 'react'
import {StyledDialog} from "./styles";
import classes from './classes.module.scss'
import { Loader } from './loadingAnimation';
import { TLoading } from '../../store/slices/types';

export interface ILoadingModalProps {
    open: TLoading
    type?: 'mini' | 'center'
    style?: CSSProperties
    text?: string
}

export const LoadingModal: React.FC<ILoadingModalProps> = ({
                                                               open,
                                                               text,
                                                               type = 'center',
                                                               style= {}
                                                           }) => {

    if (type === 'mini') {
        return (
            <div className={classes.loader_container__mini} style={{...style}}>
                <img style={{width: 50, height: 50}}
                     src='/images/loader.gif'
                     alt="loading"/>
                { text} 
            </div>
        )
    }
    return (
        <StyledDialog open={open.state} >
            <div className={classes.loader_block}>
                <div className={classes.loader_container} style={{width: 110, height: 110}}>
                    <Loader/>
                </div>
                <div className={classes.textContainer}>{ open.text }</div>
            </div>
        </StyledDialog>
    )
}