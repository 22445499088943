import { EditUser } from "../slices/UsersSlice/UserSlice"

export let paths = {
    baseURL: 'https://vendingapi.bitoobit.ru/',
    //  baseURL: 'http://192.168.222.208:13003/',
    accountService:{
        authorization: 'api/account/authorization',
        out:'api/account/out',
        //'api/account/authorization',
        checkConnect: '/api/General/Check',
        myInfo: 'api/account/getMyInfo',
        // roles:'api/account/rolesAdmin'
        // editUser:'api/account/edit'
    },
    usersService:{
        getUsers: 'api/account/listAdmin',
        EditUser: 'api/account/edit',
        EditActive: 'api/account/activation',
        CreateUser: 'api/account/register',
        getRoles: 'api/account/rolesAdmin',
        getUser: 'api/account/contractorInfo',
        getPassword: 'api/cryptography/Decode'
    },
    devicesService:{
        EditVending: 'api/Vending/editVending',
        CreateVending: 'api/Vending/RegVending',
        getVendings: 'api/Vending/GetVendingStatistic',
        getVendingOperations: 'api/Vending/GetVendingOperationsStatistic',
        getVendingCommands: 'api/General/GetVendingCommand',
        setVendingCommand: 'api/Command/SetVendingCommand',
        getAdvertisings: 'api/Advertising/GetAdvertisings',
        blockVending: 'api/Vending/blockVending'
    },
    documentsService:{
        getBlancDocuments: 'api/Vending/GetFileBlancDocuments',
        deleteBlancDocument: 'api/file/DeleteFile',
        saveFile: 'api/file/SaveFile'
    },
    advertisingService:{
        getAd: 'api/advertisingFile/getListAdvertisementsAdmin',
        getSoloAd: 'api/advertisingFile/getAdvertisementAdmin',
        createAd:'api/advertisingFile/addAdvertisement',
        saveFiles: 'api/advertisingFile/saveAdvertisementFile',
        editAd: 'api/advertisingFile/editAdvertisement',
        deleteAd: 'api/advertisingFile/deleteAdvertisement',
        getVendingsWithAds:'api/advertisingFile/getAdvertisementsAdmin',
        adActive:'api/advertisingFile/activationAdvertisement',
        editOrder:'api/advertisingFile/orderEdit'
        
    },
    versionService:{
        getVer:'api/General/GetVersions',
        editVer:'api/General/EditVersion',
        CreateVer:'api/General/InsertVersion'
    }

}

export const BASE_URL = {
    panels:{
        menu: '/',
        blancDocuments: '/blancDocuments',
        users:'/users',
        advertisement:'/advertisement',
        versions:'/versions'
    }
}