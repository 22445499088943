import React, { useEffect, useRef, useState } from 'react'
import { paths } from '../../store/api/paths'
import { auth, checkConnect, getMyInfo } from '../../store/slices/AuthSlice/AuthSlice'
import { TLoading } from '../../store/slices/types'
import { TRootState } from '../../store/store'
import { checkLoaders } from '../../store/utils/helpers'
import { useAppDispatch, useAppSelector } from '../../store/utils/hooks'
import { Button } from '../buttons/button'
import { LoadingModal } from '../dialogs/LoadingModal'
import { TextInput } from '../inputs/TextInput'
import { openNotification } from '../notifications/notification'
import styles from './AuthModal.module.scss'

const AuthModal:React.FC<{}> = ({}) => {
    const dispatch = useAppDispatch()
    const [login, setLogin] = useState<string>('')
    const [serviceUrl, setServiceUrl] = useState<string>(localStorage.getItem('CURRENT_SERVICE_URL') || paths.baseURL)
    const [password, setPassword] = useState<string>('')
    const [togglePassword, setTogglePassword] = useState<boolean>(true)
    const button = useRef()

    const authHandler = () => {
        if(login === ''){
            return openNotification({type: 'warning', message: 'Введите логин'})
        }
        if(password === ''){
            return openNotification({type: 'warning', message: 'Введите пароль'})
        }
        dispatch(checkConnect(serviceUrl)).then(() => {
            dispatch(auth({login,password, serviceUrl}))
            .then((res) => {
                localStorage.setItem('CURRENT_SERVICE_URL', serviceUrl)
                dispatch(getMyInfo())
            })
            
        })   
    }

    useEffect(() => {
        const onKeypress = (e:any) => {
            if(e.keyCode === 13){
                authHandler()
            }
        };
        document.addEventListener('keypress', onKeypress);
        return () => {
          document.removeEventListener('keypress', onKeypress);
        };
      });


    return <>
    <div className={styles.authMain}>
        <div className={styles.authModal}>
            <div className={styles.authModal__title}>
                Пожалуйста, пройдите авторизацию
            </div>
            <div className={styles.authModal__inputs}>
                <div className={styles.authModal__inputs__input}>
                    <span className={styles.authModal__inputs__input__title}>Логин</span>
                    <TextInput className={styles.authModal__inputs__input__textInput} value={login} setValue={setLogin} />
                </div>
                <div className={styles.authModal__inputs__input}>
                    <span className={styles.authModal__inputs__input__title}>Пароль</span>
                    <TextInput className={styles.authModal__inputs__input__textInput} value={password} setValue={setPassword}
                        type={togglePassword ? 'password' : 'text'}
                        icon={<img onClick={() => setTogglePassword(!togglePassword)}
                                    src='/images/eye.svg'
                                    height={20}
                                    width={20}
                                    alt="eye"/>}/>
                </div>
                <div className={styles.authModal__inputs__input}>
                    <span className={styles.authModal__inputs__input__title}>URL сервиса</span>
                    <TextInput className={styles.authModal__inputs__input__textInput} value={serviceUrl} setValue={setServiceUrl} />
                </div>
            </div>
            <div className={styles.authModal__buttons}>
                <Button ref={button} className={styles.authModal__butons__acccept} onClick={() => {authHandler()}} text={'Войти'}/>
            </div>
        </div>
    </div>
    </>
}

export default AuthModal