import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import moment from "moment"
import { openNotification } from "../../../components/notifications/notification"
import { instanceAxiosClose } from "../../api/instances"
import { paths } from "../../api/paths"
import { checkError } from "../../utils/checkErrors"
import { getInlineParams } from "../../utils/helpers"
import { DevicesState, TBlockVending, TCreateVending, TEditVending, TGetAdvertisings, TGetVendingCommands, TGetVendings, TSetVendingCommand } from "./DevicesSliceTypes"

export const getVendings = createAsyncThunk("getVendings", async (body: TGetVendings) => {
    try {
        const { data } = await instanceAxiosClose(localStorage.getItem('CURRENT_SERVICE_URL')).get(paths.devicesService.getVendings + getInlineParams(body))
        return data?.data.value
    } catch (err: any) {
        checkError(err)
    }
})

export const getVendingOperations = createAsyncThunk("getVendingOperations", async (body: TGetVendings) => {
    try {
        const { data } = await instanceAxiosClose(localStorage.getItem('CURRENT_SERVICE_URL')).get(paths.devicesService.getVendingOperations + getInlineParams(body))
        return data?.data.value
    } catch (err: any) {
        checkError(err)
    }
})

export const getVendingCommands = createAsyncThunk("getVendingCommands", async (body: TGetVendingCommands) => {
    try {
        const { data } = await instanceAxiosClose(localStorage.getItem('CURRENT_SERVICE_URL')).get(paths.devicesService.getVendingCommands + getInlineParams(body))
        return data?.data.value
    } catch (err: any) {
        checkError(err)
    }
})

export const setVendingCommand = createAsyncThunk("setVendingCommand", async (body: TSetVendingCommand) => {
    try {
        const { data } = await instanceAxiosClose(localStorage.getItem('CURRENT_SERVICE_URL')).post(paths.devicesService.setVendingCommand, body)
        return data?.data
    } catch (err: any) {
        checkError(err)
    }
})

export const getAdvertisings = createAsyncThunk("getAdvertisings", async (body: TGetAdvertisings) => {
    try {
        const { data } = await instanceAxiosClose(localStorage.getItem('CURRENT_SERVICE_URL')).get(paths.devicesService.getAdvertisings + getInlineParams(body))
        return data?.data.value
    } catch (err: any) {
        checkError(err)
    }
})

export const EditVending = createAsyncThunk("editVending", async (body: TEditVending) => {
    try {
        const { data } = await instanceAxiosClose(localStorage.getItem('CURRENT_SERVICE_URL')).post(paths.devicesService.EditVending, body)
        return data
    } catch (err: any) {
        checkError(err)
    }
})

export const BlockVend = createAsyncThunk("blockVending", async (body: TBlockVending) => {
    try {
        const { data } = await instanceAxiosClose(localStorage.getItem('CURRENT_SERVICE_URL')).post(paths.devicesService.blockVending, body)
        return data
    } catch (err: any) {
        checkError(err)
    }
})
export const CreateVending = createAsyncThunk("createVending", async (body: TCreateVending) => {
    try {
        const { data } = await instanceAxiosClose(localStorage.getItem('CURRENT_SERVICE_URL')).post(paths.devicesService.CreateVending, body)
        return data
    } catch (err: any) {
        checkError(err)
    }
})

const initialState:DevicesState = {
    vendingDevice: null,
    vendingDevices: [],
    currentVendingOperations: [],
    showMoreDevices: true,
    showMoreOperations: true,
    advertisingForCurrentDevice: null,
    commandsForCurrentDevice: [
        {
            id: 0,
            name: 'Открыть главное окно',
            mnemo: 'OpenWindowMain'
        },
        {
            id: 1,
            name: 'Отменить все задания',
            mnemo: 'CancelAllJobs'
        },
        {
            id:2,
            name: 'Перезапуск программы',
            mnemo: 'RestartFrontVending'
        }
    ],
    loaders:{
        editingDeviceLoading: {state: false, text: ''},
        devicesLoading: {state: false, text: ''},
        deviceStatisticLoading: {state: false, text: ''},
        devicesCommandLoading: {state: false, text: ''},
        deviceSetCommandLoading: {state: false, text: ''},
        deviceAdvertisingsLoading: {state: false, text: ''},
    }
}

export const devicesSlice = createSlice({
    name: 'DevicesSlice',
    initialState,
    reducers:{
        clearCurrentVendingOperations(state){
            state.currentVendingOperations = []
        },
        clearAdvertisingForCurrentDevice(state){
            state.advertisingForCurrentDevice = null
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getVendings.pending, (state, action) => {
            state.loaders.devicesLoading = {state: true, text: 'Загрузка вендинговых аппаратов'}
            
        })
        builder.addCase(getVendings.fulfilled, (state, action) => {
            state.loaders.devicesLoading = {state: false, text: 'Загрузка вендинговых аппаратов'}
            if(action.meta.arg.offset === 0 && action.payload.length){
                state.vendingDevices = action.payload
                if(action.payload.length < action.meta.arg.count){
                    state.showMoreDevices = false
                }
            } else  
                if (action.payload.length){
                    state.vendingDevices = [...state.vendingDevices, action.payload]
                    if(action.payload.length < action.meta.arg.count){
                        state.showMoreDevices = false
                    }
                }
                    
        })
        builder.addCase(getVendings.rejected, (state, action) => {
            state.loaders.devicesLoading = {state: false, text: 'Загрузка вендинговых аппаратов'}
            state.showMoreDevices = false
        })

        builder.addCase(getVendingOperations.pending, (state, action) => {
            state.loaders.deviceStatisticLoading = {state: true, text: 'Загрузка статистики по аппарату'}
        })

        builder.addCase(getVendingOperations.fulfilled, (state, action) => {
            state.loaders.deviceStatisticLoading = {state: false, text: 'Загрузка статистики по аппарату'}
            if(action.meta.arg.offset === 0 && action?.payload?.length){
                state.currentVendingOperations = action.payload.map((item:any) => {
                    return {
                        ...item,
                        dateCreate: item.dateCreate ? moment(item.dateCreate).format('DD.MM.YYYY hh:mm:ss') : ''
                    }
                })
                if(action.payload.length < action.meta.arg.count){
                    state.showMoreOperations = false
                }
            } else  
                if (action?.payload?.length){
                    state.currentVendingOperations = [...state.currentVendingOperations, action.payload.map((item:any) => {
                        return {...item, dateCreate: item.dateCreate ? moment(item.dateCreate).format('DD.MM.YYYY hh:mm:ss') : ''}
                    })]
                    if(action?.payload?.length < action.meta.arg.count){
                        state.showMoreOperations = false
                    }
                }
        })
        builder.addCase(getVendingOperations.rejected, (state, action) => {
            state.loaders.deviceStatisticLoading = {state: false, text: 'Загрузка статистики по аппарату'}
        })

        builder.addCase(getVendingCommands.pending, (state, action) => {
            state.loaders.devicesCommandLoading = {state: true, text: 'Загрузка комманд для вендингового аппарата'}
        })
        builder.addCase(getVendingCommands.fulfilled, (state, action) => {
            state.loaders.devicesCommandLoading = {state: false, text: 'Загрузка комманд для вендингового аппарата'}
            if(action?.payload){
                state.commandsForCurrentDevice = action.payload
            }
        })
        builder.addCase(getVendingCommands.rejected, (state, action) => {
            state.loaders.devicesCommandLoading = {state: false, text: 'Загрузка комманд для вендингового аппарата'}
        })

        builder.addCase(setVendingCommand.pending, (state, action) => {
            state.loaders.deviceSetCommandLoading = {state: true, text: 'Передача комманды вендинговому аппарату'}
        })
        builder.addCase(setVendingCommand.fulfilled, (state, action) => {
            state.loaders.deviceSetCommandLoading = {state: false, text: 'Передача комманды вендинговому аппарату'}
            if(action?.payload){
                openNotification({type: 'success', message: action.payload?.message || 'Успешно'})
            }
        })
        builder.addCase(setVendingCommand.rejected, (state, action) => {
            state.loaders.deviceSetCommandLoading = {state: false, text: 'Передача комманды вендинговому аппарату'}
        })
        builder.addCase(getAdvertisings.pending, (state, action) => {
            state.loaders.deviceAdvertisingsLoading = {state: true, text: 'Получение запросов на рекламу'}
        })
        builder.addCase(getAdvertisings.fulfilled, (state, action) => {
            state.loaders.deviceAdvertisingsLoading = {state: false, text: 'Получение запросов на рекламу'}
            if(action?.payload){
                state.advertisingForCurrentDevice = action.payload
            }
        })
        builder.addCase(getAdvertisings.rejected, (state, action) => {
            state.loaders.deviceAdvertisingsLoading = {state: false, text: 'Получение запросов на рекламу'}
        })
        builder.addCase(EditVending.pending,(state, action) =>{
            state.loaders.editingDeviceLoading = {state: true, text:'Редактирование вендинга'}
        })
        builder.addCase(EditVending.fulfilled, (state, action) => {
            state.loaders.editingDeviceLoading = {state: false, text: 'Редактирование вендинга'}
            // if(action.payload){
            //     openNotification({type: 'success', message: 'Вендинг изменён'})
            // }
        })
        builder.addCase(EditVending.rejected, (state, action) => {
            state.loaders.editingDeviceLoading = {state: false, text: 'Редактирование вендинга'}
        })


        builder.addCase(BlockVend.pending,(state, action) =>{
            state.loaders.editingDeviceLoading = {state: true, text:'Блокировка вендинга'}
        })
        builder.addCase(BlockVend.fulfilled, (state, action) => {
            state.loaders.editingDeviceLoading = {state: false, text: 'Блокировка вендинга'}
            // if(action.payload){
            //     openNotification({type: 'success', message: 'Вендинг изменён'})
            // }
        })
        builder.addCase(BlockVend.rejected, (state, action) => {
            state.loaders.editingDeviceLoading = {state: false, text: 'Блокировка вендинга'}
        })


        builder.addCase(CreateVending.pending,(state, action) =>{
            state.loaders.editingDeviceLoading = {state: true, text:'Создание вендинга'}
        })
        builder.addCase(CreateVending.fulfilled, (state, action) => {
            state.loaders.editingDeviceLoading = {state: false, text: 'Создание вендинг'}
            if(action.payload){
                openNotification({type: 'success', message: 'Вендинг создан'})
            }
        })
        builder.addCase(CreateVending.rejected, (state, action) => {
            state.loaders.editingDeviceLoading = {state: false, text: 'Создание вендинга'}
        })
    }
})

export const { clearCurrentVendingOperations, clearAdvertisingForCurrentDevice } = devicesSlice.actions

export default devicesSlice.reducer;