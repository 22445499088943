import axios, { AxiosResponse } from 'axios'
import { paths } from './paths'

export const instanceAxiosClose = (baseUrl: string | null, token?: string) => {
    const customAxios = axios.create({
        baseURL: baseUrl || paths.baseURL,
        headers: {
            ContentType: 'application/json',
            Authorization: `Bearer ${String(localStorage.getItem('ACCESS_TOKEN'))}`
        }
    })
    
    customAxios.interceptors.response.use((response: AxiosResponse) => {
        return response
    })

    return customAxios
}



export const instanceAxiosOpen = (baseUrl: string | null,) => {
    return axios.create({
        baseURL: baseUrl || paths.baseURL,
        headers: {
            ContentType: 'application/json'
        }
    })
}