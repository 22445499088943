
import { DatePicker, Input, Radio, RadioChangeEvent } from 'antd'
import moment, { Moment } from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { NULL } from 'sass'
import { getVendingOperations } from '../../../store/slices/DevicesSlice/DevicesSlice'
import { TGetVendings, TOperationJob, TVendingDevice, TVendingOperation } from '../../../store/slices/DevicesSlice/DevicesSliceTypes'
import { AppDispatch, TRootState } from '../../../store/store'
import useDebouncedFunction, { operationsErrors } from '../../../store/utils/helpers'
import { Button } from '../../buttons/button'
import { InfoDialog } from '../../dialogs/InfoModal'
import { TextInput } from '../../inputs/TextInput'
import { Table, TSort } from '../../Table/Table'
import { TActivateUser, TEditUser, TGetRoles, TGetUser, TGetUsers, TPassword, TRoles, TUserPassword, TUsers } from '../../../store/slices/UsersSlice/UsersSliceTypes'
import { Select } from 'antd'
import { getUser, EditUser, Active, getRoles, getUsers, getPassword, clearPassword } from '../../../store/slices/UsersSlice/UserSlice'
import s from '../users.module.scss'
import { ISelectDataItem, MultipleSelect } from '../../inputs/MultipleSelect'
import { PhoneInput } from '../../inputs/MaskInput'
import { paths } from '../../../store/api/paths'

interface IUserEdit {
    currentUser : TUsers | null
    EditUser: Function
    Active: Function
    getRoles:Function
    roles: TRoles[] | []
    setUserEditing:Function
    getUsers:Function
    userPass: any
    getPassword: Function
    clearPassword: Function
    
}

const UserEditing:React.FC<IUserEdit> = ({currentUser,  EditUser, Active,getRoles, roles,setUserEditing, getUsers, userPass, getPassword, clearPassword}) =>{
        const [name,  setName] = useState<string | undefined>()
        const [town,  setTown] = useState<string | undefined>()
        const [phone, setPhone] = useState(currentUser?.phone || '')
        const [email, setEmail] = useState<string | undefined>()
        const [isActive, setIsActive] = useState<boolean | undefined>()
        const [userRole, setUserRole] = useState <number | undefined>()
        const [password, setPassword] = useState<string | undefined>()
        const [selectedRole, setSelectedRole] = useState<ISelectDataItem[]>([])
        const [chagneEye, setChangeEye] = useState<number>(0)
        const [fakePass, setFakePass] = useState<string | undefined>()
        // const button = useRef()

        useEffect(() => {
            setName(currentUser?.name)
            setTown(currentUser?.town)
            // setPhone(currentUser?.phone)
            setIsActive(currentUser?.active)
            setEmail(currentUser?.email)
            setUserRole(currentUser?.role)
            setPassword(currentUser?.password)
            setFakePass(currentUser?.password)

            
        }, [currentUser])
       
        const getRolesHandler = () => {
            getRoles({
                count:10000,
                offset:0
            })
        }
            
        useEffect(() => {
            getRolesHandler()
        },[])

        useEffect(() => {
            if(chagneEye == 1 && userPass){
                setPassword(userPass)
            }
          
        }, [userPass])

        const changeActive = (e: RadioChangeEvent) => {
            setIsActive(e.target?.value)
        }
        const changeName = (e: React.ChangeEvent<HTMLInputElement>) => {
            setName(e.target.value)
        }
        const changePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
            setPhone(e.target.value)
        }
        const changeTown = (e: React.ChangeEvent<HTMLInputElement>) => {
            setTown(e.target.value)
        }
        const changeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(e.target.value)
        }
        const changeRole = (value:number) =>{
            setUserRole(value)
        }

        const GettingPassword = () => {
            getPassword({
                value: password
            })
            setPassword(userPass)
            setChangeEye(1)
            // setPassword(userPass)
        }

        const changeFakePass = () => {
            setPassword(fakePass)
            // if(chagneEye === 0){
            //     setPassword(password?.replace(/[a-zA-Zа-яА-я0-9]/gi, '*'))
            //     debugger
            // }
            setChangeEye(0)
        }
        
        const saveUser = () => {
            EditUser({
                id: currentUser?.id,
                phone: phone,
                email: email,
                name: name,
                town: town,
                roleId: userRole,
                isDeleted:false
            }).then(()=>{
            Active({
                id:currentUser?.id,
                active:isActive
                
            })}).then(()=>{
                getUsers({
                    count:30,
                    offset:0
                
            })
            })
            // .then(()=> {
            //     setUserEditing({open:false, currentUser:null})
            // })
            setUserEditing({open:false})
            clearPassword()
            setPassword(undefined)
            // .then(()=> {
           
            
            
        }
    return (
        <div className={s.editUsersModal}>
                <label
                    htmlFor="name">
                    Организация
                </label>
                <Input
                    value={name}
                    onChange={changeName}
                    id="name"/>
                 <label
                    htmlFor="email">
                    Email
                </label>
                <Input
                    value={email}
                     onChange={changeEmail}
                    id="email"/>
                     <label
                    htmlFor="town">
                    Город
                </label>
                <Input
                    value={town}
                     onChange={changeTown}
                    id="town"/>
                     <label 
                    htmlFor="phone">
                    Телефон
                </label>
                <PhoneInput
                    value={phone}
                    setValue={setPhone}
                    id="phone"/>
                <div className={s.password}>
                    <Input
                    value={password}
                    disabled
                    />
                    {chagneEye === 0 ?
                    <img title={'Дешифровать'} width={25} height={25} src='/images/eyePass.svg' 
                    className={s.eye}
                    onClick={() => {GettingPassword()}}/>
                    :
                    <img title={'Скрыть'} width={25} height={25} src='/images/eyeOffPass.svg' 
                    className={s.eye}
                    onClick={() => {changeFakePass()}}/>}
                </div>
                

                        <Select 
                        dropdownClassName={s.CustomDropDown}
                        showSearch 
                        allowClear
                        onChange={changeRole} 
                        value={userRole || undefined}
                        optionFilterProp="children"
                        style={{marginLeft:'20px', width:'150px'}}
                        placeholder="Роль пользователя">
                            {roles.map(role => 
                                    <Select.Option 
                                        key={role.id} 
                                        value={role.id}>
                                        {role?.name}
                                    </Select.Option>    
                                )}
                    </Select>
                       {/* <MultipleSelect
                //@ts-ignore
                placeholder={userRole}
                multiple={true}
                items={roles}
                selectedItems={selectedRole}
                setSelected={setSelectedRole}
                /> */}

                <Radio.Group 
                     value={ isActive } 
                     onChange={changeActive}> 
                    <Radio value={ true }>Активировать</Radio>
                    <Radio value={ false }>Деактивировать</Radio>
                </Radio.Group>

                <Button text='Сохранить'  onClick = {()=>saveUser()}/>
        </div>
    )
}
const mapStateToProps = (state: TRootState) => ({
    roles: state.users.roles,
    userPass: state.users.userPass
})
const mapDispatchToProps = (dispatch :AppDispatch) => {
    return {
        getUser: (body: TGetUser) => dispatch(getUser(body)),
        EditUser: (body: TEditUser) => dispatch(EditUser(body)),
        Active:(body: TActivateUser) => dispatch(Active(body)),
        getRoles:(body:TGetRoles) => dispatch(getRoles(body)),
        getUsers: (body: TGetUsers) => dispatch(getUsers(body)),
        getPassword: (body: TUserPassword) => dispatch(getPassword(body)),
        clearPassword: () => dispatch(clearPassword())
       
    }
    
        
    
}
export const UserEditingContainer = connect(mapStateToProps, mapDispatchToProps)(UserEditing)