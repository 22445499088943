import React from 'react'
import InputMask from 'react-input-mask'
import ISimpleInput from './types'
import styles from './inputs.module.css'

export const MaskInput: React.FC<ISimpleInput> = ({
                                                       width = '100%',
                                                       className = '',
                                                       value,
                                                       setValue,
                                                       mask,
                                                       ...props
                                                   }) => {

    return (
        <div className={styles.simple_input_wrap + ' ' + className}>
            <InputMask
                style={{
                    ...props?.style,
                    width
                }}
                //@ts-ignore
                mask={mask}
                value={value}
                onChange={({target}) => {
                    let newVal = target.value.split('')
                    newVal[1] = '7'
                    setValue(newVal.join(''))
                }}
                className={styles.simple_input_style }
                {...props}
            />
        </div>
    )
}

export const PhoneInput: React.FC<ISimpleInput> = ({
    width = '100%',
    className = '',
    value,
    setValue,
    ...props
}) => {

return (
<div className={styles.simple_input_wrap + ' ' + className}>
<InputMask
style={{
...props?.style,
width
}}
mask="+9 (999) 999-99-99"
value={value}
onChange={({target}) => {
let newVal = target.value.split('')
newVal[1] = '7'
setValue(newVal.join(''))
}}
className={styles.simple_input_style }
{...props}
/>
</div>
)
}