import React, { ReactElement, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { Node } from 'typescript'
import s from './Table.module.scss'

interface TEnumItem {
    id: number | string,
    name: string,
    mnemo?: string
    orderBy?: string
}

export type TBadRow = {
    className: string,
    customId: string,
    matchIds: number[]
}
export type TGreyRow = {
    className: string,
    customId: string,
    matchIds: boolean[],
}
export type TVendingBadRow = {
    className: string,
    customId: string,
    matchIds: boolean[]
}

export type TSort = {
    orderBy: string
    isAsc: boolean | null
    
}

interface ITableProps {
    headers: TEnumItem[]
    sizes: number[]
    body: any,
    buttons?: TEnumButtons[]
    customId?: string
    uniqueProp?: string
    rowClassName?: string
    badRow?: TBadRow
    greyRow?: TGreyRow
    vendingBadRow?: TVendingBadRow
    sort?: TSort
    setSort?: Function
}

interface TEnumButtons {
    id: number,
    name: string,
    mnemo: string,
    image: ReactElement,
    function: (value?: any) => any
}

interface ITableHeaderProps {
    headers: TEnumItem[]
    sizes: number[]
    sort?: TSort
    setSort?: Function
}

interface ITableDataProps {
    body: any
    sizes: number[]
    headers: TEnumItem[]
    buttons?: TEnumButtons[]
    customId: string
    uniqueProp?: string
    rowClassName: string
    badRow?: TBadRow
    greyRow?: TGreyRow
    vendingBadRow?: TVendingBadRow
}




export const Table: React.FC<ITableProps> = ({
    headers,
    sizes,
    body,
    buttons = [],
    customId = 'id',
    uniqueProp = '',
    rowClassName = '',
    badRow,
    greyRow,
    vendingBadRow,
    sort,
    setSort
}) => {
    return <div className={s.root}>
        <Header sort={sort} setSort={setSort} headers={headers} sizes={sizes} />
        <Data greyRow={greyRow} badRow={badRow} vendingBadRow={vendingBadRow} rowClassName={rowClassName} uniqueProp={uniqueProp} buttons={buttons} headers={headers} body={body} sizes={sizes} customId={customId} />
    </div>
}


const Header: React.FC<ITableHeaderProps> = ({
    headers,
    sizes,
    sort,
    setSort
}) => {
    const setSortHandler = (item: any) => {
        if(sort && setSort){
           if(item?.orderBy){
            if(sort.orderBy === item?.orderBy && sort.isAsc === null){
                setSort({orderBy: item.orderBy, isAsc: false})
            } else {
                if(sort.orderBy === item?.orderBy && sort.isAsc === false){
                    setSort({orderBy: item.orderBy, isAsc: true})
                } else {
                    if(sort.orderBy === item?.orderBy && sort.isAsc === true){
                        setSort({orderBy: '', isAsc: null})
                    }
                    else {
                        if(sort.orderBy !== item?.orderBy){
                            setSort({orderBy: item.orderBy, isAsc: false})
                        }
                    }
                } 
            }
           }
        }
    }
    return <div className={s.header} >
        {headers?.map((item, idx) => (<div key={`${item?.mnemo}__${idx}`} style={{ flex: `0 0 ${sizes[idx]}%` }}><span className={item?.orderBy ? s.canSort : ''} onClick={() => setSortHandler(item)}>{item?.name}
        {(sort?.orderBy === item?.orderBy && sort?.isAsc === false) ? <img width={'20px'} height={'20px'} src='./images/arrow.svg'/> : null}
        {(sort?.orderBy === item?.orderBy && sort?.isAsc === true) ? <img width={'20px'} height={'20px'} className={s.rotate} src='./images/arrow.svg'/> : null}
        </span>
        </div>))
        }
    </div>
}


const Data: React.FC<ITableDataProps> = ({
    body,
    sizes,
    headers,
    buttons,
    customId,
    uniqueProp,
    rowClassName,
    badRow,
    greyRow,
    vendingBadRow
}) => {


    return <div className={s.data}>
        {body?.map((bodyItem: any, index: number) => (<DataItem greyRow={greyRow} badRow={badRow} vendingBadRow={vendingBadRow} rowClassName={rowClassName} uniqueProp={uniqueProp} buttons={buttons} headers={headers} item={bodyItem} key={index} sizes={sizes} customId={customId} />))}
    </div>
}


interface ITableDataItemProps {
    item: any
    sizes: number[]
    headers: TEnumItem[]
    buttons?: TEnumButtons[]
    customId: string
    uniqueProp?: string
    rowClassName: string
    badRow?: TBadRow
    greyRow?: TGreyRow
    vendingBadRow?:TVendingBadRow

}

const DataItem: React.FC<ITableDataItemProps> = ({
    item,
    sizes,
    headers,
    buttons,
    customId,
    uniqueProp,
    rowClassName,
    badRow,
    greyRow,
    vendingBadRow
}) => {
    const renderData = (object: any) => {
        if(document.getElementById(`item__${item[customId] + uniqueProp}`)){
            const node = document.getElementById(`item__${item[customId] + uniqueProp}`)
                node!.textContent = ''
                node!.innerHTML = ''  
        }
        
        let arrayFromObjectKeys = Object.keys(object);
        headers?.map((header, index) => {
            // if  (arrayFromObjectKeys?.find(el => el.toString() !== header?.mnemo)) {
           
            // }
                 
            if (arrayFromObjectKeys?.find(el => el.toString() === header?.mnemo)) {
                 
                let a = arrayFromObjectKeys?.find(el => el.toString() === header?.mnemo)
                if (a) {
                    
                    let value = object[a]
                    let element = document.createElement("div")
                    element.innerHTML = ''
                    element.style.flex = `0 0 ${sizes[index]}%`
                    
                    //element.appendChild(document.createTextNode(`${value}`));
                    
                    element.innerHTML = value
                    
                    
                    if (document && item[customId]) document.getElementById(`item__${item[customId] + uniqueProp}`)?.appendChild(element);
                     
                    
                }
            }else{
                
                let a = arrayFromObjectKeys?.find(el => el.toString() !== header?.mnemo)
                if (a) {
                 
                let value = '-'
                let element = document.createElement("div")
                element.innerHTML = ''
                element.style.flex = `0 0 ${sizes[index]}%`
                element.innerHTML = value
                if (document && item[customId]) document.getElementById(`item__${item[customId] + uniqueProp}`)?.appendChild(element);
                
                }
            }
        })
        if(buttons?.length){
            let mainElement = document.createElement('div')
            buttons.map((button, index) => {
                let element = document.createElement(`div`)
                element.innerHTML = ''
                let divImg = document.createElement('div')
                divImg.innerHTML = ''
                divImg.addEventListener('click', () => button.function(item))
                element = element.appendChild(divImg)
                element.id = `function__${String(index + button.name)}`
                mainElement.appendChild(divImg)
                ReactDOM.render(button.image, divImg)
            })
            mainElement.style.flex = `0 0 ${sizes[sizes.length - 1]}%`
            mainElement.style.display = 'flex'
            mainElement.style.gap = '10px'
            
            if (document && item[customId]) document.getElementById(`item__${item[customId] + uniqueProp}`)?.appendChild(mainElement);
            let element = document.createElement("div")
            element.style.flex = `0 0 ${sizes[sizes.length - 1]}%`
            if (document && item[customId]) document.getElementById(`item__${item[customId] + uniqueProp}`)?.appendChild(element);
        } 
    }

    useEffect(() => {
        renderData(item)
    }, [item])

    const error = badRow?.matchIds.find( matchId => matchId === item[badRow.customId]) ? badRow.className : 
                  vendingBadRow?.matchIds.find( matchId => matchId === item[vendingBadRow.customId]) ? vendingBadRow.className : ''
    const greyError = greyRow?.matchIds.find(matchId => matchId == item[greyRow.customId]) ? greyRow.className : ''
    const vendingRow = vendingBadRow?.matchIds.find( matchId => matchId === item[vendingBadRow.customId]) ? vendingBadRow.className : ''

    // const err = badRow?.matchIds.find( matchId => matchId === item[badRow.customId])
    // const vend = vendingBadRow?.matchIds.find( matchId => matchId === item[vendingBadRow.customId])
    // let resres = {}
    // const res = () =>{
    //     if(err){
    //         badRow?.className
    //         resres = err
    //     }if(vend) {
    //         vendingBadRow?.className
    //         resres = vend
    //     } else {
    //         resres =  ''
            
    //     }
    //     return 
    // }
    
    return <div id={`item__${item[customId] + uniqueProp}`} className={s.row + ' ' + rowClassName + error} >
    </div>
}
