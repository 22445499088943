
import { DatePicker, Input, InputNumber, Radio, RadioChangeEvent } from 'antd'
import moment, { Moment } from 'moment'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { NULL } from 'sass'
import { CreateVending, EditVending, getVendingOperations, getVendings } from '../../store/slices/DevicesSlice/DevicesSlice'
import { TCreateVending, TEditVending, TGetVendings, TOperationJob, TVendingDevice, TVendingOperation } from '../../store/slices/DevicesSlice/DevicesSliceTypes'
import { AppDispatch, TRootState } from '../../store/store'
import useDebouncedFunction, { operationsErrors } from '../../store/utils/helpers'
import { Button } from '../buttons/button'
import { InfoDialog } from '../dialogs/InfoModal'
import { TextInput } from '../inputs/TextInput'
import { Table, TSort } from '../Table/Table'
import { TActivateUser, TEditUser, TGetRoles, TGetUser, TGetUsers, TRoles, TUsers } from '../../store/slices/UsersSlice/UsersSliceTypes'
import { Select } from 'antd'
import { getUser, EditUser, Active, getRoles, getUsers } from '../../store/slices/UsersSlice/UserSlice'
import s from './devices.module.scss'
import { ISelectDataItem, MultipleSelect } from '../inputs/MultipleSelect'
import { PhoneInput } from '../inputs/MaskInput'
import { openNotification } from '../notifications/notification'

interface IVendigEdit {
    CreateVending:Function
    getUsers:Function
    setDeviceCreating:Function
    getVendings:Function
    

    
}

const VendingCreating:React.FC<IVendigEdit> = ({CreateVending, getUsers, setDeviceCreating,getVendings}) =>{
        const [leftPaper,  setLeftPaper] = useState<number | undefined>()
        const [port,  setPort] = useState<number | undefined>()
        const [selectedUser, setSelectedUser] = useState<ISelectDataItem[]>([])
        const [nameNumber, setNameNumber] = useState<string | undefined>()
        const [address, setAddress] = useState<string | undefined>()
        const [hostAddress, setHostAddress] = useState<string | undefined>()
        const [adressGlobalService, setAdressGlobalService] = useState<string | undefined>()
        const [telegramService, setTelegramService] = useState<string | undefined>()
        const [terminal, setTerminal] = useState<number | undefined>()
        const [printerName, setPrinterName] = useState<string | undefined>()
        const [scanerName, setScanerName] = useState<string | undefined>()
        const [cupsHosting, setCupsHosting] = useState<string | undefined>()
        const [phoneTp, setPhoneTp] = useState<string | undefined>()
        const [processed, setProcessed] = useState<boolean>(true)
        const [count, setCount] = useState<number>(30)
        const [offset, setOffset] = useState<number>(0)
       
        const getUsersHandler = () => {
            getUsers({
                count:10000,
                offset:0
            })
        }
        
            
        useEffect(() => {
            getUsersHandler()
        },[])

        const changeLeftPaper = (value: number | undefined) => {
            setLeftPaper(value)
        }
        const changePort = (value: number | undefined) => {
            setPort(value)
        }
        const changeTerminal = (value: number | undefined) => {
            setTerminal(value)
        }
        const changeNameNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
            setNameNumber(e.target.value)
        }
        const changeAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
            setAddress(e.target.value)
        }
        const changeHostAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
            setHostAddress(e.target.value)
        }
        const changeAdressGlobalService = (e: React.ChangeEvent<HTMLInputElement>) => {
            setAdressGlobalService(e.target.value)
        }
        const changeTelegramService = (e: React.ChangeEvent<HTMLInputElement>) => {
            setTelegramService(e.target.value)
        }
        const changePrinterName = (e: React.ChangeEvent<HTMLInputElement>) => {
            setPrinterName(e.target.value)
        }
        const changeScanerName = (e: React.ChangeEvent<HTMLInputElement>) => {
            setScanerName(e.target.value)
        }
        const changeCupsHosting = (e: React.ChangeEvent<HTMLInputElement>) => {
            setCupsHosting(e.target.value)
        }
        const changePhoneTp = (e: React.ChangeEvent<HTMLInputElement>) => {
            setPhoneTp(e.target.value)
        }
        


        const saveUser = () => {
            if (!nameNumber) {
                return openNotification({type: 'warning', message: 'Не заполнено название!'})
            }
            if (!address) {
                return openNotification({type: 'warning', message: 'Не заполнен адрес!'})
            }
            if (!hostAddress) {
                return openNotification({type: 'warning', message: 'Не заполнен адрес хоста!'})
            }
            if (!adressGlobalService) {
                return openNotification({type: 'warning', message: 'Не заполнен адрес глобального сервиса!'})
            }
            if (!telegramService) {
                return openNotification({type: 'warning', message: 'Не заполнен адрес телеграм сервиса!'})
            }
            if (!leftPaper) {
                return openNotification({type: 'warning', message: 'Не заполнено количество бумаги!'})
            }
            if (!port) {
                return openNotification({type: 'warning', message: 'Не заполнен порт!'})
            }
            if (!terminal) {
                return openNotification({type: 'warning', message: 'Не заполнен терминал!'})
            }
            if (!printerName) {
                return openNotification({type: 'warning', message: 'Не заполнено наименование принтера!'})
            }
            if (!scanerName) {
                return openNotification({type: 'warning', message: 'Не заполнено наименование сканера!'})
            }
            if (!cupsHosting) {
                return openNotification({type: 'warning', message: 'Не заполнен адрес раздачи хостинга!'})
            }
            if (!phoneTp) {
                return openNotification({type: 'warning', message: 'Не заполнен телефон технической поддержки!'})
            }
            
            
            
            
            CreateVending({
                nameNumber:  nameNumber,
                address: address,
                hostAddress:hostAddress,
                adressGlobalService: adressGlobalService,
                telegramService: telegramService,
                leftPaper: leftPaper,
                port: port,
                terminal: terminal,
                printerName: printerName,
                scanerName: scanerName,
                cupsHosting: cupsHosting,
                phoneTp: phoneTp
                
            }).then(() =>{
                getVendings({
                    offset:offset,
                    count:count,
                    Processed: processed
                })
            })
            setDeviceCreating({open:false})
       
        }
    return (
        <div className={s.editVendingModal}>
            <div className={s.Naming}> 
                <div className={s.nameForNaming}>
                    Наименования
                </div>
                <div className={s.colomnsForNaming}>
                <div className={s.NamingColomn}>
                <label 
                 style={{marginBottom:'-25px'}}
                    htmlFor="phone">
                    Название вендинга*
                </label>
                <Input
                    value={nameNumber}
                    onChange={changeNameNumber}
                    // onKeyPress={blockInvalidChars}
                    id="nameNumber"/>
                </div>
                <div className={s.NamingColomn}>
                <label 
                    style={{marginBottom:'-25px'}}
                    htmlFor="printerName">
                    Наименование принтера*
                </label>
                <Input
                    value={printerName}
                    onChange={changePrinterName}
                    // onKeyPress={blockInvalidChars}
                    id="printerName"/>
                </div>
                <div className={s.NamingColomn}>
                <label 
                    style={{marginBottom:'-25px'}}
                    htmlFor="scanerName">
                    Наименование сканера*
                </label>
                <Input
                    value={scanerName}
                    onChange={changeScanerName}
                    // onKeyPress={blockInvalidChars}
                    id="scanerName"/>
                    </div>
                </div>
            </div>

            <div className={s.Adresses}>
                <div className={s.nameForAdresses}>
                    Адреса
                </div>
                <div className={s.colomnsForAdresses}>
                    <div className={s.AdressesColomn}>
                <label 
                    style={{marginBottom:'-25px'}}
                    htmlFor="phone">
                    Адрес глобального адреса*
                </label>
                <Input
                    value={adressGlobalService}
                    onChange={changeAdressGlobalService}
                    // onKeyPress={blockInvalidChars}
                    id="adressGlobalService"/>
                    </div>
                    <div className={s.AdressesColomn}>
                <label 
                    style={{marginBottom:'-25px'}}
                    htmlFor="telegramService">
                    Адрес телеграм сервиса*
                </label>
                <Input
                    value={telegramService}
                    onChange={changeTelegramService}
                    // onKeyPress={blockInvalidChars}
                    id="adressGlobalService"/>
                    </div>
                    <div className={s.AdressesColomn}>
                <label 
                    style={{marginBottom:'-25px'}}
                    htmlFor="cupsHosting">
                    Адрес раздачи хостинга*
                </label>
                <Input
                    value={cupsHosting}
                    onChange={changeCupsHosting}
                    // onKeyPress={blockInvalidChars}
                    id="cupsHosting"/>
                    </div>
                <div className={s.bottomAdresses}>
                    <div className={s.ButtomAdressesColomn}>
                <label 
                    style={{marginBottom:'-25px'}}
                    htmlFor="phone">
                    Адрес*
                </label>
                <Input
                    value={address}
                    onChange={changeAddress}
                    // onKeyPress={blockInvalidChars}
                    id="address"/>
                    </div>
                    <div className={s.ButtomAdressesColomn}>
                <label 
                    style={{marginBottom:'-25px'}}
                    htmlFor="phone">
                    Адрес хоста*
                </label>
                <Input
                    value={hostAddress}
                    onChange={changeHostAddress}
                    // onKeyPress={blockInvalidChars}
                    id="hostAddress"/>
                    </div>
                    </div>
                </div>
            </div>
              
            <div className={s.other}>
                <div className={s.NameForOther}>
                    Остальное
                </div>
                <div className={s.pomogite}>
                <div className={s.PhoneForOther}>
                    <div className={s.PhoneThing}>
                    <label 
                        style={{marginBottom:'-25px'}}
                        htmlFor="phoneTp">
                        Телефон технической поддержки*
                    </label>
                    <PhoneInput
                        value={phoneTp}
                        setValue={setPhoneTp}
                        // onKeyPress={blockInvalidChars}
                        id="phoneTp"/>
                    </div>
                    
                </div>
                <div className={s.otherForOther}>
                    <div className={s.thingForOther}>
                    <label 
                        style={{marginBottom:'-25px'}}
                        htmlFor="port">
                        Порт*
                    </label>
                    <InputNumber
                        value={port}
                        onChange={changePort}
                        // onKeyPress={blockInvalidChars}
                        id="port"/>
                    </div>

                    <div className={s.thingForOther}>
                    <label
                        style={{marginBottom:'-25px'}}
                        htmlFor="nameNumber">
                        Бумага*
                    </label>
                    <InputNumber
                        value={leftPaper}
                        onChange={changeLeftPaper}
                        width={'100px'}
                        // {(val) => {changeLeftPaper(val.currentTarget.value ? Number(val.currentTarget.value) : 0)}}
                        id="leftPaper"/>
                    </div>
                    <div className={s.thingForOther}>
                    <label 
                        style={{marginBottom:'-25px'}}
                        htmlFor="terminal">
                        Терминал*
                    </label>
                    <InputNumber
                        value={terminal}
                        onChange={changeTerminal}
                        // onKeyPress={blockInvalidChars}
                        id="terminal"/>
                    
                    </div>
                </div>
                </div>
            </div>

                {/* <Select 
                        showSearch 
                        allowClear
                        onChange={changeUser} 
                        optionFilterProp="children"
                        style={{marginLeft:'20px'}}
                        placeholder="Роль пользователя">
                            {users.map(role => 
                                    <Select.Option 
                                        key={role.id} 
                                        value={role.id}>
                                        {role?.name}
                                    </Select.Option>    
                                )}
                    </Select>
                       <MultipleSelect
                
                multiple={true}
                items={users}
                selectedItems={selectedUser}
                setSelected={setSelectedUser}
                /> */}

                <Button text='Сохранить' onClick = {()=>saveUser()}/>
        </div>
    )
}

const mapDispatchToProps = (dispatch :AppDispatch) => {
    return {
        getVendings: (body: TGetVendings) => dispatch(getVendings(body)),
        getUsers: (body: TGetUsers) => dispatch(getUsers(body)),
        CreateVending: (body: TCreateVending) => dispatch(CreateVending(body)),
    }
    
        
    
}
export const VendingCreatingContainer = connect(null, mapDispatchToProps)(VendingCreating)