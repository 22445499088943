import {useDispatch} from "react-redux";
import {useSelector} from "react-redux";
import React, {useEffect, useState} from 'react'
import { useAppSelector } from "../../store/utils/hooks";
import { TRootState } from "../../store/store";

export interface IHavePermission{
    mnemoName: string,
    children: React.ReactNode
}

export const HavePermissionFunc = (mnemoName:any, userData:any) => {
    if (userData && Array.isArray(userData?.formviews) && userData?.formviews?.length){

        let havePermn
        userData?.formviews.map((formview:any) => {
            if (formview?.mnemo === mnemoName){
                 
                havePermn = true
            }
        })
        if (havePermn === true){
             
            return true
        }
        else{
            return null
        }

    }
    else{
        return null
    }
}

export const HavePermission:React.FC<IHavePermission> = ({mnemoName, children}) => {
    //const userData = useSelector((state) => state?.AuthState)
    const userData = useAppSelector((state: TRootState) => state.auth.userInfo)

    if (userData && Array.isArray(userData?.formViews) && userData?.formViews?.length){

        let havePermn
        userData?.formViews.map((formview) => {
            if (formview?.mnemo === mnemoName){
                havePermn = true
            }
        })
        if (havePermn === true){
            return <>
                {children}
            </>
        }
        else{
            return null
        }

    }
    else{
        return null
    }

}

