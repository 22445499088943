import React, { Dispatch, useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { AppDispatch, TRootState } from '../../store/store'
import {clearAdvertisingForCurrentDevice, clearCurrentVendingOperations, getVendings} from '../../store/slices/DevicesSlice/DevicesSlice'
import { useAppDispatch, useAppSelector } from '../../store/utils/hooks'
import { ThunkDispatch } from '@reduxjs/toolkit'
import s from './devices.module.scss'
import { StylesContext } from '@material-ui/styles'
import { TGetVendings, TVendingDevice } from '../../store/slices/DevicesSlice/DevicesSliceTypes'
import { Table, TSort } from '../Table/Table'
import { TextInput } from '../inputs/TextInput'
import { Button } from '../buttons/button'
import { DatePicker, Select } from 'antd'
import { Moment } from 'moment'
import useDebouncedFunction, { devicesErrors, vendingError } from '../../store/utils/helpers'
import { InfoDialog } from '../dialogs/InfoModal'
import { DeviceStaticticContainer } from './deviceStatistic'
import { DeviceCommandsContainer } from './deviceCommands'
import { DeviceAdContainer } from './deviceAd'
import {VendingEditingContainer} from './deviceEdit'
import { getMyInfo } from '../../store/slices/AuthSlice/AuthSlice'
import { TGetUsers, TUsers } from '../../store/slices/UsersSlice/UsersSliceTypes'
import { getUsers } from '../../store/slices/UsersSlice/UserSlice'
import { VendingCreatingContainer } from './deviceCreating'
import { HavePermission } from '../difficultFunc/HavePerm'

interface IDevices {
    getVendings: Function
    vendingDevices: TVendingDevice[] | []
    showMoreDevices: boolean
    clearCurrentVendingOperations: Function
    clearAdvertisingForCurrentDevice: Function
    getUsers:Function
    users: TUsers[] | []
}

const Devices:React.FC<IDevices> = ({getVendings, vendingDevices, showMoreDevices, clearCurrentVendingOperations, clearAdvertisingForCurrentDevice, getUsers, users}) => {
    const [customVendingDevices, setCustomVendingDevices] = useState<any[] | []>([])
    const [count, setCount] = useState<number>(30)
    const [vendingDevicesCustom, setVendingDevicesCustom] = useState<any[]>([])
    const [offset, setOffset] = useState<number>(0)
    const [vendingId, setVendingId] = useState<string>('')
    const [searchValue, setSearchValue] = useState<string>('')
    const [processed, setProcessed] = useState<boolean>(true)
    const [name, setName] = useState<string>('')
    const [dateBeg, setDateBeg] = useState<Moment | null>(null)
    const [dateEnd, setDateEnd] = useState<Moment | null>(null)
    const [deviceStatistic, setDeviceStatistic] = useState<{open: boolean, currentDevice: TVendingDevice | null}>({open: false, currentDevice: null})
    const [openCommandModal, setOpenCommandModal] = useState<{open: boolean, currentDevice: TVendingDevice | null}>({open: false, currentDevice: null})
    const [adModal, setAdModal] = useState<{open: boolean, currentDevice: TVendingDevice | null}>({open: false, currentDevice: null})
    const [editingVendingModal, setEditingVendingModal] = useState<{open: boolean, currentDevice: TVendingDevice | null}>({open: false, currentDevice: null})
    const dispatch = useAppDispatch()
    //const [currentAccaunt, setCurrentAccaunt] = useState<>
    const [currentSort, setCurrentSort] = useState<TSort>({orderBy: '', isAsc: null})
   // const usersSelect = useAppSelector((state: TRootState) => state.users.users)
    const [deviceCreating, setDeviceCreating] = useState<{open: boolean}>({open: false})
   

    const setCurrentSortHandler = (val: TSort) => {
        setCurrentSort(val)
        getVendings({
            count: offset + count,
            offset: 0,
            orderBy: val.orderBy,
            isAsc: val.isAsc,
            vendingId: vendingId,
            searchValue: searchValue,
            Processed: processed,
            DateBeg: dateBeg ? dateBeg?.format('YYYY-MM-DDT00:00:00') : '',
            DateEnd: dateEnd ? dateBeg?.format('YYYY-MM-DDT23:59:59') : '',
        })
    }

    // useEffect(() => {
    //     console.log(currentSort)
    // },[currentSort])
  

    useEffect(() => {
        setCustomVendingDevices(
            vendingDevices?.length ? 
            vendingDevices.map( device => {
                // console.log(device.phone)
                // console.log(device.name)
                return {
                    ...device,
                     isBlocked: device.isBlocked ? 'Заблокирован' : 'Работает'
                    // leftPaper: `<div style='margin-left: 320px'>${device.leftPaper}</div>`,
                    // leftToner: `<div style='margin-left: 40px'>${device.leftToner}%</div>`,
                    // status: device?.status ? `<img src='./images/check.svg' style='width: 25px; height=25px; margin-left: 10px' />` : `<img src='./images/error.svg' style='width: 25px; height:25px; margin-left: 10px' />`
                }
            }) : []
        )
    },[vendingDevices])
    
    const getVendingsHandler = () => {
        getVendings({
            count: count,
            offset: offset,
            vendingId: vendingId,
            orderBy: currentSort.orderBy,
            isAsc: currentSort.isAsc,
            searchValue: searchValue,
            Processed: processed,
            DateBeg: dateBeg ? dateBeg?.format('YYYY-MM-DD') : '',
            DateEnd: dateEnd ? dateBeg?.format('YYYY-MM-DD') : '',
        })
    }

    const getUsersHandler = () => {
        getUsers({
            count: 10000,
            offset: 0,
        })
    }

    const getVendingsDateBeg = (value: Moment | null) => {
        getVendings({
            orderBy: currentSort.orderBy,
            isAsc: currentSort.isAsc,
            count: offset + count,
            offset: 0,
            vendingId: vendingId,
            searchValue: searchValue,
            Processed: processed,
            DateBeg: value ? value?.format('YYYY-MM-DD') : '',
            DateEnd: dateEnd ? dateBeg?.format('YYYY-MM-DD') : '',
        })
    }

    const getVendingsDateEnd = (value: Moment | null) => {
        getVendings({
            orderBy: currentSort.orderBy,
            isAsc: currentSort.isAsc,
            count: offset + count,
            offset: 0,
            vendingId: vendingId,
            searchValue: searchValue,
            Processed: processed,
            DateBeg: dateBeg ? dateBeg?.format('YYYY-MM-DD') : '',
            DateEnd: value ? value?.format('YYYY-MM-DD') : '',
        })
    }

    const getVendingsSearch = useDebouncedFunction((val: string) => {
        setCustomVendingDevices([])
        getVendings({
            count: offset + count,
            offset: 0,
            vendingId: vendingId,
            searchValue: val,
            Processed: processed,
            DateBeg: dateBeg ? dateBeg?.format('YYYY-MM-DD') : '',
            DateEnd: dateEnd ? dateBeg?.format('YYYY-MM-DD') : '',
        })
    }, 1000)

    const showMoreHandler = () => {
        getVendings({
            orderBy: currentSort.orderBy,
            isAsc: currentSort.isAsc,
            count: count,
            // offset: offset + count,
            offset: offset,
            vendingId: vendingId,
            searchValue: searchValue,
            Processed: processed,
            DateBeg: dateBeg ? dateBeg?.format('YYYY-MM-DD') : '',
            DateEnd: dateEnd ? dateBeg?.format('YYYY-MM-DD') : '',
        })
        // setOffset(offset + count)
        setCount(count + 30)
    }

    const changeName = (value:number) => {
        setCustomVendingDevices([])
        getVendings({
        count: offset + count,
        offset: 0,
        name: name,
        orderBy: currentSort.orderBy,
        isAsc: currentSort.isAsc,
        vendingId: vendingId,
        searchValue: searchValue,
        Processed: processed,
        DateBeg: dateBeg ? dateBeg?.format('YYYY-MM-DDT00:00:00') : '',
        DateEnd: dateEnd ? dateBeg?.format('YYYY-MM-DDT23:59:59') : '',
        contractorId: value
        })
    }

    useEffect(() => {
        getVendingsHandler()
        getUsersHandler()
    },[count])

    
    const headers = [
        
        {id: 1, name: "Название", mnemo: "nameNumber", orderBy: 'nameNumber'},
        {id: 2, name: "Адрес", mnemo: "address", orderBy: 'address'},
        {id: 3, name: "Хост-адрес", mnemo: "hostAddress", orderBy: 'hostAddress'},
        {id: 4, name: "Наименование", mnemo: "name", orderBy: 'name'},
        {id: 5, name: "Телефон", mnemo: "phoneTp", orderBy: 'phoneTp'},
        {id: 6, name: "Осталось листов", mnemo: 'leftPaper', orderBy: 'leftPaper'},
        {id: 7, name: "Осталось тонера", mnemo: 'leftToner', orderBy: 'leftToner'},
        {id: 8, name: "Статус", mnemo: 'errorName', orderBy: 'errorName'},
        {id: 9, name: "Активность", mnemo:'isBlocked', orderBy: 'isBlocked'}
    ]



    const buttons = [
        {id: 1, name: 'deviceOperations', mnemo: 'deviceOperations', image: <img title={'Операции устройства'} width={25} height={25} src='/images/list.svg'/>, function: (val: TVendingDevice) => {setDeviceStatistic({open: true, currentDevice: val}); }},
        {id: 2, name: 'deviceCommand', mnemo: 'deviceCommand', image: <img title={'Задать команду'} width={25} height={25} src='/images/console.svg'/>, function: (val: TVendingDevice) => setOpenCommandModal({open: true, currentDevice: val})},
        {id: 2, name: 'deviceAd', mnemo: 'deviceAd', image: <img title={'Запросы на рекламу'} width={25} height={25} src='/images/horn.svg'/>, function: (val: TVendingDevice) => setAdModal({open: true, currentDevice: val})},
        {id:3, name:"VendingEditing",mnemo: 'vendingEditing',image:<img title={'Редактирование'} width={25} height={25} src='/images/editing.svg'/>, function: (val:TVendingDevice) => {setEditingVendingModal({open: true, currentDevice:val}); }},
    ]

    useEffect(() => {
        console.log(vendingDevices)
        setVendingDevicesCustom(vendingDevices.map(item => {
            return {
                ...item,
                status: item.status ? `<img src='./images/check.svg' style='width: 25px' height='25px' />` : `<img src='./images/error.svg' style='width: 25px' height='25px' />`
            }
        }))
    },[vendingDevices])

    return (
        <>
        <div className={s.mainBlock}>
            <InfoDialog open={deviceStatistic.open} handleClose={() => {clearCurrentVendingOperations(); setDeviceStatistic({open: false, currentDevice: null})}}>
                <DeviceStaticticContainer currentVendingDevice={deviceStatistic.currentDevice}/>
            </InfoDialog>
            <InfoDialog open={openCommandModal.open} handleClose={() => {setOpenCommandModal({open: false, currentDevice: null})}}>
                <DeviceCommandsContainer currentVendingDevice={openCommandModal.currentDevice}/>
            </InfoDialog>
            <InfoDialog open={adModal.open} handleClose={() => {setAdModal({open: false, currentDevice: null}); clearAdvertisingForCurrentDevice();}}>
                <DeviceAdContainer currentVendingDevice={adModal.currentDevice}/>
            </InfoDialog>
            <InfoDialog open={editingVendingModal.open} handleClose={() => {setEditingVendingModal({open: false, currentDevice: null});}}>
                <VendingEditingContainer currentVendingDevice={editingVendingModal.currentDevice} setEditingVendingModal={setEditingVendingModal}/>
            </InfoDialog>
            <InfoDialog open={deviceCreating.open} handleClose={() => {setDeviceCreating({open: false})}}>
                <VendingCreatingContainer setDeviceCreating={setDeviceCreating}/>
            </InfoDialog>
            <div className={s.tableContainer}>
                <div className={s.filters}>
                    <div className={s.filterBlock__search} style={{marginTop:'9px'}} >
                        <TextInput height={'36px'} value={searchValue}  setValue={(val) => {setSearchValue(val); getVendingsSearch(val)}} placeholder={'Поиск'}/>
                    </div>
                    {/* <div className={s.filterBlock__datePicker}>
                        <div className={s.title}>Дата от:</div>
                        <DatePicker
                            style={{height: '36px'}}
                            value={dateBeg}
                            onChange={(val) => {setDateBeg(val); getVendingsDateBeg(val)}}
                            placeholder={'Выберите'}
                            format={'DD.MM.YYYY'}
                        />
                    </div>
                    <div className={s.filterBlock__datePicker}>
                        <div className={s.title}>Дата до:</div>
                        <DatePicker
                            style={{height: '36px'}}
                            value={dateEnd}
                            onChange={(val) => {setDateEnd(val); getVendingsDateEnd(val)}}
                            placeholder={'Выберите'}
                            format={'DD.MM.YYYY'}
                        />
                    </div> */}
                    <HavePermission mnemoName={'users'}>
                    <Select 
                        showSearch 
                        allowClear
                        onChange={changeName} 
                        optionFilterProp="children"
                        style={{marginLeft:'20px', marginTop:'12px', width:'250px'}}
                        placeholder="Наименование организации">
                            {users.map(user => 
                                    <Select.Option 
                                        key={user.id} 
                                        value={user.id}>
                                        {user?.name ? user.name : user.login}
                                    </Select.Option>    
                                )}
                    </Select>
                    </HavePermission>
                </div>
                <div className={s.table}>
                    <Table
                        buttons={buttons}
                        headers={headers}
                        body={customVendingDevices}
                        sizes={[10, 16, 11, 9, 9, 5, 6, 16, 10, 1]}
                        badRow={{className: s.badRowClassName, customId: 'errorId', matchIds: devicesErrors}}
                        // vendingBadRow={{className:s.vendingBadClassName, customId: 'isBlocked', matchIds: vendingError}}
                        sort={currentSort}
                        setSort={setCurrentSortHandler}
                    />
                </div>
                { showMoreDevices ? <div className={s.showMore}>
                    <Button text={'Показать еще...'} onClick={() => {showMoreHandler()}}/>
                </div> : null }
            </div>
        </div>
        </>
    )
}

const mapStateToProps = (state: TRootState) => ({
    users: state.users.users,
    vendingDevices: state.devices.vendingDevices,
    showMoreDevices: state.devices.showMoreDevices
})

const mapDispatchToProps = (dispatch:AppDispatch) => {
    return {
        getVendings: (body: TGetVendings) => dispatch(getVendings(body)),
        getUsers:(body:TGetUsers) => dispatch(getUsers(body)),
        clearCurrentVendingOperations: () => dispatch(clearCurrentVendingOperations()),
        clearAdvertisingForCurrentDevice: () => dispatch(clearAdvertisingForCurrentDevice())
    }
}

export const DevicesContainer = connect(mapStateToProps, mapDispatchToProps)(Devices)