
import { ConfigProvider, DatePicker, Input, Radio, RadioChangeEvent } from 'antd'
import moment, { Moment } from 'moment'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { NULL } from 'sass'
import { getVendingOperations } from '../../../store/slices/DevicesSlice/DevicesSlice'
import { TGetVendings, TOperationJob, TVendingDevice, TVendingOperation } from '../../../store/slices/DevicesSlice/DevicesSliceTypes'
import { AppDispatch, TRootState } from '../../../store/store'
import useDebouncedFunction, { operationsErrors } from '../../../store/utils/helpers'
import { Button } from '../../buttons/button'
import { InfoDialog } from '../../dialogs/InfoModal'
import { TextInput } from '../../inputs/TextInput'
import { Table, TSort } from '../../Table/Table'
import { TActivateUser, TCreateUser, TEditUser, TGetRoles, TGetUser, TGetUsers, TRoles, TUsers } from '../../../store/slices/UsersSlice/UsersSliceTypes'
import { Select } from 'antd'
import { getUser, EditUser, Active, getRoles, CreateUser, getUsers } from '../../../store/slices/UsersSlice/UserSlice'
import s from '../users.module.scss'
import { ISelectDataItem, MultipleSelect } from '../../inputs/MultipleSelect'
import { PhoneInput } from '../../inputs/MaskInput'
import { openNotification } from '../../notifications/notification'

interface IUserCreate {
    CreateUser: Function
    getRoles:Function
    roles: TRoles[] | []
    setUserCreating: Function
    getUsers: Function
}

const UserCreating:React.FC<IUserCreate> = ({CreateUser,getRoles, roles, setUserCreating, getUsers}) =>{
        const [name,  setName] = useState<string | undefined>()
        const [town,  setTown] = useState<string | undefined>()
        const [phone, setPhone] = useState<string | undefined>()
        const [email, setEmail] = useState<string | undefined>()
        const [isActive, setIsActive] = useState<boolean | undefined>()
        const [userRole, setUserRole] = useState <number | undefined>()
        const [password, setPassword] = useState<string | undefined>()
        const [login, setLogin] = useState<string | undefined>()
        const [selectedRole, setSelectedRole] = useState<ISelectDataItem[]>([])
        const [isCorrect, setIsCorrect] = useState<boolean>(false)

        const getRolesHandler = () => {
            getRoles({
                count:10000,
                offset:0
            })
        }
            
        useEffect(() => {
            getRolesHandler()
        },[])

        const changeActive = (e: RadioChangeEvent) => {
            setIsActive(e.target?.value)
        }
        const changeName = (e: React.ChangeEvent<HTMLInputElement>) => {
            setName(e.target.value)
        }
        // const changePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
        //     setPhone(e.target.value)
        // }
        const changeTown = (e: React.ChangeEvent<HTMLInputElement>) => {
            setTown(e.target.value)
        }
        const changeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(e.target.value)
        }
        const changeRole = (value:number) =>{
            setUserRole(value)
        }
        const changePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value)
        }
        const changeLogin = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLogin(e.target.value)
        }
        const blockInvalidChars = (event:any) => {
            const regex = /[\\/[\]:¦<>+=;,*?"]/;
            const { key } = event;
            if (regex.test(key)) {
              event.preventDefault();
            }
          };

        const saveUser = () => {
            if (!login) {
                return openNotification({type: 'warning', message: 'Не заполнен логин!'})
            }
            if (!password) {
                return openNotification({type: 'warning', message: 'Не заполнен пароль!'})
            }
            if (!email) {
                return openNotification({type: 'warning', message: 'Не заполнен email!'})
            }
            if (!phone) {
                return openNotification({type: 'warning', message: 'Не заполнен телефон!'})
            }
            if (!name) {
                return openNotification({type: 'warning', message: 'Не заполнен наименование организации!'})
            }
            if (!town) {
                return openNotification({type: 'warning', message: 'Не заполнен город!'})
            }
            CreateUser({
                login: login, 
                password: password,
                role: selectedRole[0]?.id,
                email: email,
                phone: phone,
                name: name,
                town: town
            }).then(()=>{
                getUsers({
                    count:30,
                    offset:0
                
            })
            })
            setUserCreating({
                open:false
            })
            // getUsers()
           
        }
    return (
        // <ConfigProvider
        //     theme={{
        //         token:{
        //             colorPrimary: '#C95596'
        //         }
        //     }}>
        <div className={s.createUsersModal}>
            {/* <div className={s.usersForm}> */}
                <div className={s.mainUser}>
                    <div className={s.login}>
                <div className={s.accauntObj}>
                <label 
                    style={{marginRight:'10px'}}
                    htmlFor="phone">
                    Логин:
                </label>
                <Input
                    value={login}
                    onChange={changeLogin}
                    onKeyPress={blockInvalidChars}
                    id="phone"
                    placeholder='Логин'/>
                </div>
                
                <div className={s.accauntObj}>
                <label 
                    style={{marginRight:'10px', marginLeft:'10px'}}
                    htmlFor="phone">
                    Пароль:
                </label>
                <Input
                    value={password}
                    onChange={changePassword}
                    id="phone"/>
                </div>
                </div>
                <div className={s.emailForUser}>
                <div  className={s.accauntObj}>
                <label
                    style={{marginRight:'15px'}}
                    htmlFor="email">
                    Email: 
                </label>
                <Input
                    value={email}
                     onChange={changeEmail}
                    id="email"/>
                </div>
                </div>
                
            <div className={s.otherInfo}>
                
                 <div className={s.PhoneTown}>
                    <div className={s.accauntObj}>
                     <label
                     style={{marginRight:'15px'}}
                    htmlFor="town">
                    Город:
                </label>
                <Input
                    value={town}
                     onChange={changeTown}
                    id="town"/>
                    </div>
                    <div className={s.accauntObj} >
                <label
                    style={{marginRight:'15px'}}
                    htmlFor="name">
                    Организация:
                </label>
                <Input
                    value={name}
                    onChange={changeName}
                    id="name"/>
                    </div>
                    
                </div>
                <div className={s.NameOrg}>
                <div className={s.accauntObj}>
                     <label 
                     style={{marginRight:'15px', marginLeft:'10px'}}
                    htmlFor="phone">
                    Телефон:
                    </label>
                <PhoneInput
                    value={phone}
                    setValue={setPhone}
                    id="phone"/>
                    </div>
                
                </div>
             
            </div>
            <div className={s.MultipleSelector}>
                    {/* <label 
                    htmlFor="role">
                    Роль
                    </label> */}
                <MultipleSelect
                placeholder='Выберите роль'
                multiple={true}
                items={roles}
                selectedItems={selectedRole}
                setSelected={setSelectedRole}
                />
                </div>
            </div>
            {/* </div> */}
            
                <Button  text='Сохранить' onClick = {()=>saveUser()}/>
        </div>
        // </ConfigProvider>
    )
}
const mapStateToProps = (state: TRootState) => ({
    roles: state.users.roles
})
const mapDispatchToProps = (dispatch :AppDispatch) => {
    return {
        getUser: (body: TGetUser) => dispatch(getUser(body)),
        CreateUser: (body: TCreateUser) => dispatch(CreateUser(body)),
        getRoles:(body:TGetRoles) => dispatch(getRoles(body)),
        getUsers: (body: TGetUsers) => dispatch(getUsers(body)),
       
    }
    
        
    
}
export const UserCreatingContainer = connect(mapStateToProps, mapDispatchToProps)(UserCreating)