import React from "react";

import styles from './button.module.css'

interface IButton {
    width?: string
    height?: string
    text: string
    backgroundColor?: string
    color?: string
    outlineColor?: string
    border?: string
    className?: string
    style?: any
    paddingTop?: string
    paddingBottom?: string
    [key: string]: any
}

export const Button: React.FC<IButton> = ({
                                              width,
                                              height,
                                              text,
                                              backgroundColor,
                                              color,
                                              outlineColor,
                                              border,
                                              className,
                                              style,
                                              paddingTop,
                                              paddingBottom,
                                              ...props
                                          }) => {
    return (
        <div style={{
            width: width,
            height: height,
            backgroundColor: backgroundColor,
            color: color,
            outlineColor: outlineColor,
            border: border,
            paddingTop: paddingTop,
            paddingBottom: paddingBottom,
            ...style || null
        }}
             className={styles.button + ' ' + className}
             {...props}>
            {text}
        </div>)
}