import React from 'react'
import { BlancDocumentsContainer } from '../components/blancDocuments/blancDocuments'
import Header from '../components/header/Header'
import Panel from '../components/panel/Panel'
import { AdContainer } from '../components/advertisement/ad'
import { VersionsContainer } from '../components/versions/versions'

const Version: React.FC = () => {
    return <>
        <Header/>
            <VersionsContainer/>
        <Panel/>
    </>
}

export default Version