
import { DatePicker, Input, Radio, RadioChangeEvent } from 'antd'
import moment, { Moment } from 'moment'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { NULL } from 'sass'
import { getVendingOperations, getVendings } from '../../store/slices/DevicesSlice/DevicesSlice'
import { TGetVendings, TOperationJob, TVendingDevice, TVendingOperation } from '../../store/slices/DevicesSlice/DevicesSliceTypes'
import { AppDispatch, TRootState } from '../../store/store'
import useDebouncedFunction, { operationsErrors } from '../../store/utils/helpers'
import { Button } from '../buttons/button'
import { InfoDialog } from '../dialogs/InfoModal'
import { TextInput } from '../inputs/TextInput'
import { Table, TSort } from '../Table/Table'
import { TActivateUser, TCreateUser, TEditUser, TGetRoles, TGetUser, TRoles, TUsers } from '../../store/slices/UsersSlice/UsersSliceTypes'
import { Select } from 'antd'
import { getUser, EditUser, Active, getRoles, CreateUser } from '../../store/slices/UsersSlice/UserSlice'
import s from './ad.module.scss'
import { ISelectDataItem, MultipleSelect } from '../inputs/MultipleSelect'
import { PhoneInput } from '../inputs/MaskInput'
import { openNotification } from '../notifications/notification'
import { TCreateAd, TFileAdvertisement, TGetAds, TGetVendingsWithAds, TVendingsWithAd } from '../../store/slices/AdSlice/AdSliceTypes'
import { CreateAd, EditAd, GetVendingsWithAds, SaveFileAdd, getAds, saveFileAd } from '../../store/slices/AdSlice/AdSlice'
import { ISelectDataItemNew, NewMultipleSelect } from '../inputs/NewMultipleSelect'
import { current } from '@reduxjs/toolkit'

interface IAdCreate {
    saveFileAd: Function,
    SaveFileAdd: Function
    CreateAd: Function,
    getDevices: Function,
    devices: TVendingDevice[] | []
    getAds:Function,
    setAdCreating:Function
    GetVendingsWithAds:Function
    vendingsWithAds:TGetVendingsWithAds[] | null
    // setCurrentDeviceLocal: Function
    // currentDeviceLocal: any

}

const AdCreating:React.FC<IAdCreate> = ({CreateAd, getDevices, devices,saveFileAd,SaveFileAdd,getAds, setAdCreating,GetVendingsWithAds,vendingsWithAds}) => {
    const [name, setName] = useState<string|undefined>()
    const [selectedVending, setSelectedVending] = useState<ISelectDataItemNew[]>([])
    // const [newPhotos, setNewPhotos] = useState([])
    const [image, setImage] = useState<File>();
    const [imageVid, setImageVid] = useState<File>();
    const [preview, setPreview] = useState<string | null>();
    const [previewVid, setPreviewVid] = useState<string | null>();
    const [currentFormData, setCurrentFormData] = useState<any | null>(null)
    const [currentFormDataVid, setCurrentFormDataVid] = useState<any|null>(null)
    const [activeTab, setActiveTab] = useState(0);
    const [selectVend, setSelectVend] = useState<string|undefined>()
    // const [photos, setPhotos] = useState(devices?.images || [])
    const hiddenFileInput: any = React.createRef()
    const hiddenVideoInput: any = React.createRef()




    const handleClick = (event: any) => {
        event.preventDefault()
        hiddenFileInput.current.click()
    }
    const handleClickVid = (event: any) => {
        event.preventDefault()
        hiddenVideoInput.current.click()
    }
    const handleTabClick = (index:any) => {
        setActiveTab(index);
      };

      useEffect(() => {
        
        if (imageVid) {
            hiddenVideoInput.current?.focus();
            const reader = new FileReader();
            reader.readAsDataURL(imageVid);
            reader.onloadend = () => {
                setPreviewVid(reader.result as string);
              };
              
          } else {
            setPreviewVid(null)
          }
        //   let type = currentTarget.files[0].name.split(".").splice(-1,1)[0]
            //     if(type === 'jpeg' || type === 'jpg' || type === 'png'){
                    let newFile = {
                        Name: imageVid?.name,
                        Extention: imageVid?.name.split('.')[imageVid.name.split('.').length-1],
                        dataType: '2',
                        Rewrite: true,
                        
                    }
          console.log(imageVid)
          const formData = new FormData();
          if(imageVid){
            formData.append('Files', imageVid)
            formData.append('Json', JSON.stringify({
                Flag: 'I',
                Id: selectVend,
                Type: "Advertisement.Video",
                Files:[{
                        Name: imageVid?.name,
                        Extention: imageVid?.name.split('.')[imageVid.name.split('.').length-1].toUpperCase(),
                        dataType: '3',
                        Rewrite: true,
                }]
            })
            )
            
            setCurrentFormDataVid({Files: imageVid, Json: JSON.stringify({
                Flag: 'I',
                Id: selectVend,
                Type: "Advertisement.Video",
                Files:[{
                    Name: imageVid?.name,
                    Extention: imageVid?.name.split('.')[imageVid.name.split('.').length-1].toUpperCase(),
                    dataType: '3',
                    Rewrite: true,
            }]
            })})
          
            console.log(formData)
            console.log(imageVid)
            
        }
    }, [imageVid]);

    useEffect(() => {
        
        if (image) {
            hiddenFileInput.current?.focus();
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onloadend = () => {
                setPreview(reader.result as string);
              };

          } else {
            setPreview(null)
          }
        //   let type = currentTarget.files[0].name.split(".").splice(-1,1)[0]
            //     if(type === 'jpeg' || type === 'jpg' || type === 'png'){
                    let newFile = {
                        Name: image?.name,
                        Extention: image?.name.split('.')[image.name.split('.').length-1],
                        dataType: '2',
                        Rewrite: true,
                        
                    }
          console.log(image)
          const formData = new FormData();
          if(image){
            formData.append('Files', image)
            formData.append('Json', JSON.stringify({
                Flag: 'I',
                Id: selectVend,
                Type: "Advertisement.Photo",
                Files:[{
                        Name: image?.name,
                        Extention: image?.name.split('.')[image.name.split('.').length-1].toUpperCase(),
                        dataType: '2',
                        Rewrite: true,
                }]
            })
            )
            
            setCurrentFormData({Files: image, Json: JSON.stringify({
                Flag: 'I',
                Id: selectVend,
                Type: "Advertisement.Photo",
                Files:[{
                    Name: image?.name,
                    Extention: image?.name.split('.')[image.name.split('.').length-1].toUpperCase(),
                    dataType: '2',
                    Rewrite: true,
            }]
            })})
          
            console.log(formData)
            console.log(image)
           
        }
    }, [image]);


    const getVendingsHandler = () => {
        getDevices({
            count:10000,
            offset:0,
            Processed: true,
         
            
        })
    }

    useEffect(()=>{
        getVendingsHandler()
    },[])

    const changeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value)
    }
    const changeVend = (value:string) => {
        setSelectVend(value)
    }
    const VendingAdsHandlet = async () =>{
        await GetVendingsWithAds({
            count: 10000,
            offset: 0,
            // vendingId:currentDevice?.id
            // onSuccess: (data: any) => {
            //     if(vendingsWithAds?.length){
            //         setCurrentDeviceLocal(data?.find((item:any) => item.id === currentDeviceLocal?.id))
                    
            //     }  
            // }
        })
        console.log(vendingsWithAds)
            
       
            
    }

    const saveAd = () => {
       const promiseImg =  SaveFileAdd({
            formData:currentFormData
                    })
        Promise.all([promiseImg]).then(() => {
        const promiseCreate = CreateAd({
            vendingId:selectVend,
            name:name,
            fileName:image?.name.replaceAll(' ', '_'),
            typeId:2

        })
        return promiseCreate
        })
        .then((promiseCreate) => {
        Promise.all([promiseCreate]).then(() => {
        const promiseGetAdVend = GetVendingsWithAds({
                count:100000,
                offset:0
         })
         const promiseGetAd = getAds({
            count:100000,
            offset:0
        })
        })

        
            // VendingAdsHandlet()
            
        })
        setAdCreating({open: false})
        
       
    }
    const deleteUploadImage = () =>{
        setPreview(null)
        setCurrentFormData(null)
        setPreviewVid(null)
        setCurrentFormDataVid(null)
    }
    const saveAdVid = () => {
        if (!name) {
            return openNotification({type: 'warning', message: 'Не заполнено название рекламы!'})
        }
        const promiseVid = SaveFileAdd({
            formData:currentFormDataVid
        })
        Promise.all([promiseVid]).then(() => {
        const promiseCreateVid = CreateAd({
            vendingId:selectVend,
            name:name,
            fileName:imageVid?.name.replaceAll(' ', '_'),
            typeId:3
        })
        })
      .then(() => {
        const promiseGetAdForVid =  getAds({
            count:100000,
            offset:0
        })
        
        Promise.all([promiseVid, promiseGetAdForVid]).then(() => {
        // VendingAdsHandlet()
        GetVendingsWithAds({
            count:10000,
            offset:0
        })
        })
    })
      
        setAdCreating({open: false})
       
    }
    return(
        <div className={s.adModal}>
                <label 
                    style={{marginBottom:'-25px'}}
                    htmlFor="name">
                    Наименование
                </label>
                <Input
                    value={name}
                    onChange={changeName}
                    id="name"/>

                    <Select 
                        dropdownClassName={s.CustomDropDown}
                        showSearch 
                        allowClear
                        onChange={changeVend} 
                        value={selectVend || undefined}
                        optionFilterProp="children"
                        style={{marginLeft:'20px', width:'300px'}}
                        placeholder="Выберите вендинг">
                            {devices.map(dev => 
                                    <Select.Option 
                                        key={dev.id} 
                                        value={dev.id}>
                                        {dev?.nameNumber}
                                    </Select.Option>    
                                )}
                    </Select>
                        {/* <NewMultipleSelect
                            placeholder='Выберите вендинг'
                            multiple={true}
                            items={devices}
                            selectedItems={selectedVending}
                            setSelected={setSelectedVending}
                /> */}
                <div className={s.filters}>
                    <div className={s.Tabs}> 
                    <div className={s.tabsHeader}>
                        <div tabIndex={0}
                        // style={{display: 'inline-block', paddingRight:'10px',paddingLeft:'10px', border:'1px solid',backgroundColor:'#662482',color:'#FFFFFF',transition: '0.3s ease'}}
                            className={`${s.tabItem} ${activeTab === 0 ? s.active : s.tabItem}`}
                            onClick={() => setActiveTab(0)}
                        >
                            Фото
                        </div>
                        <div tabIndex={1}
                        //  style={{display: 'inline-block', paddingLeft:'10px',paddingRight:'10px', border:'1px solid',backgroundColor:'#662482', borderLeft:'none', color:'#FFFFFF',transition: '0.3s ease'}}
                            className={`${s.tabItemR} ${activeTab === 1 ? s.active1 : s.tabItemR}`}
                            onClick={() => setActiveTab(1)}
                        >
                            Видео
                        </div>
                    </div>
                    <div className={s.TabsContent}>
                    {activeTab === 0 && (
                        <div className={s.TapsPhoto}>
                            {preview ? (
                                <>
                                <div className={s.photoDiv}>
                                <button onClick ={deleteUploadImage} className={s.buttonClosePrevie}>✕</button>
                                <img className={s.photoimg} src={preview} alt={"preview"} />
                                </div>
                                </>
                            ) : (
                                <div className={s.addBlock}>
                            
                                    <img onClick={handleClick}
                                           src='/images/addBig.svg'
                                           height={32}
                                           width={32}
                                           alt="addBig"
                                           className={s.button}/>
                                           <input type="file"  style={{ display: "none" }}
                                accept="image/*,.png,.jpg,"
                                onChange={(event: any) => {
                                if (!name) {
                                    return openNotification({type: 'warning', message: 'Заполните название рекламы!'})
                                }
                                if (!selectVend) {
                                    return openNotification({type: 'warning', message: 'Не заполнен вендинг!'})
                                }
                                if (event?.target?.files?.length !== 0) {
                                const file = event.target.files[0];
                                if (file && file.type.substring(0, 5) === "image") {
                                    setImage(file);
                                } else {
                                    setImage(undefined);
                                    
                                }
                                }}}
                                    ref={hiddenFileInput}
                                            />
                            </div>
                            
                            )}
                            <div style={{marginTop:'10px'}}>
                                Рекомендуемый размер: 1366х768
                            </div>
                            <Button  text='Сохранить Фото' style={{margin:'0 auto', marginTop:'20px', }} onClick = {()=>saveAd()}/>
                        </div>
                    )}

                        <div className={s.TabsVideo}>
                            {activeTab === 1 && (
                               <div className={s.TapsVideo}>
                                    {previewVid ? (
                                        <>
                                        <div className={s.photoDiv}>
                                        <button onClick ={deleteUploadImage} className={s.buttonClosePrevie}>✕</button>
                                        <video className={s.photoimg} src={previewVid} controls style={{ objectFit: "cover" }}/>
                                        </div>
                                        </>
                            ) : (
                                <div className={s.addBlock}>
                            
                                    <img onClick={handleClickVid}
                                           src='/images/addBig.svg'
                                           height={32}
                                           width={32}
                                           alt="addBig"
                                           className={s.button}/>
                                           <input type="file"  style={{ display: "none" }}
                                accept="video/*,.mp4,"
                                
                                onChange={(event: any) => {
                                if (!name) {
                                    return openNotification({type: 'warning', message: 'Заполните название рекламы!'})
                                }
                                if (!selectVend) {
                                    return openNotification({type: 'warning', message: 'Не заполнен вендинг!'})
                                }
                                if (event?.target?.files?.length !== 0) {
                                const file = event.target.files[0];
                            //   if (file.size > 1e6) {
                            //     return openNotification({type: 'warning', message: 'Уменьшите размер картинки или выберите другую!'})
                            // }
                                    
                                if (file && file.type.substring(0, 5) === "video") {
                                    setImageVid(file);
                                    
                                } else {
                                    setImageVid(undefined);
                                    
                                }
                                }}}
                                    ref={hiddenVideoInput}
                                    
                                            />
                            </div>
                            )}
                            <Button  text='Сохранить Видео' style={{margin:'0 auto', marginTop:'10px'}} onClick = {()=>saveAdVid()}/>
                            </div>
                                
                            )}
                        </div>
                    </div>
                </div>
            </div>
                
        </div>
    )
}
const mapStateToProps = (state: TRootState) => ({
    devices: state.devices.vendingDevices
})
const mapDispatchToProps = (dispatch :AppDispatch) => {
    return {
        saveFileAd: (body: TFileAdvertisement) => dispatch(saveFileAd(body)),
        CreateAd: (body: TCreateAd) => dispatch(CreateAd(body)),
        getDevices:(body:TGetVendings) => dispatch(getVendings(body)),
        SaveFileAdd:(formData: any) => dispatch(SaveFileAdd(formData)),
        getAds: (body: TGetAds) => dispatch(getAds(body)),
        GetVendingsWithAds:(body:TVendingsWithAd) => dispatch(GetVendingsWithAds(body))
    
        
    }
    
        
    
}
export const AdCreatingContainer = connect(mapStateToProps, mapDispatchToProps)(AdCreating)