import React from "react";
import Header from "../components/header/Header";
import Panel from "../components/panel/Panel";
import { UsersContainer } from "../components/users/users";

const UsersPage: React.FC = () => {
    return  <>
            <Header/>
                <UsersContainer/>
            <Panel/>
        </>
    
}

export default UsersPage