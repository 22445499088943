import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { openNotification } from "../../../components/notifications/notification"
import { instanceAxiosClose } from "../../api/instances"
import { paths } from "../../api/paths"
import { checkError } from "../../utils/checkErrors"
import { getInlineParams } from "../../utils/helpers"
import { TCreatVer, TEditVer, TGetVer, VerState } from "./VersionSliceType"

export const getVer = createAsyncThunk("getVer", async (body: TGetVer) => {
    try {
        const { data } = await instanceAxiosClose(localStorage.getItem('CURRENT_SERVICE_URL')).get(paths.versionService.getVer + getInlineParams(body))
        return data?.data.value
    } catch (err: any) {
        checkError(err)
    }
})
export const EditVer = createAsyncThunk("editVer", async (body: TEditVer) => {
    try {
        const { data } = await instanceAxiosClose(localStorage.getItem('CURRENT_SERVICE_URL')).post(paths.versionService.editVer, body)
        return data
    } catch (err: any) {
        checkError(err)
    }
})
export const CreateVer = createAsyncThunk("createVer", async (body: TCreatVer) => {
    try {
        const { data } = await instanceAxiosClose(localStorage.getItem('CURRENT_SERVICE_URL')).post(paths.versionService.CreateVer, body)
        return data
    } catch (err: any) {
        checkError(err)
    }
})



const initialState:VerState = {
    versions: [],
    version: null,
    loaders:{
        versionLoading: {state: false, text: ''}
    }
}


export const VerSlice = createSlice({
    name:"VerSlice",
    initialState,
    reducers:{
     
    },
    extraReducers:(builder) => {
        builder.addCase(getVer.pending, (state, action) => {
            state.loaders.versionLoading = {state: true, text: 'Загрузка версий'}
            
        })
        builder.addCase(getVer.fulfilled, (state, action) => {
            state.loaders.versionLoading = {state: false, text: 'Загрузка версий'}
            if(action.meta.arg.offset === 0 && action.payload.length){
                state.versions = action.payload
            }            
        })
        builder.addCase(EditVer.pending,(state, action) =>{
            state.loaders.versionLoading = {state: true, text:'Редактирование версии'}
        })
        builder.addCase(EditVer.fulfilled, (state, action) => {
            state.loaders.versionLoading = {state: false, text: 'Редактирование версии'}
            if(action.payload){
                openNotification({type: 'success', message: 'Версия изменена'})
            }
        })
        builder.addCase(EditVer.rejected, (state, action) => {
            state.loaders.versionLoading = {state: false, text: 'Редактирование версии'}
        })
        builder.addCase(CreateVer.pending,(state, action) =>{
            state.loaders.versionLoading = {state: true, text:'Создание версии'}
        })
        builder.addCase(CreateVer.fulfilled, (state, action) => {
            state.loaders.versionLoading = {state: false, text: 'Создание версии'}
            if(action.payload){
                openNotification({type: 'success', message: 'Версия создана'})
            }
        })
        builder.addCase(CreateVer.rejected, (state, action) => {
            state.loaders.versionLoading = {state: false, text: 'Создание версии'}
        })
    }
})

// export const {deleteAdd, clearAdvertisingForCurrentVending} = adSlice.actions
export default VerSlice.reducer