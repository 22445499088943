
import { DatePicker, Input, InputNumber, Radio, RadioChangeEvent } from 'antd'
import moment, { Moment } from 'moment'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { NULL } from 'sass'
import { getVendingOperations, getVendings } from '../../store/slices/DevicesSlice/DevicesSlice'
import { TGetVendings, TOperationJob, TVendingDevice, TVendingOperation } from '../../store/slices/DevicesSlice/DevicesSliceTypes'
import { AppDispatch, TRootState } from '../../store/store'
import useDebouncedFunction, { operationsErrors } from '../../store/utils/helpers'
import { Button } from '../buttons/button'
import { InfoDialog } from '../dialogs/InfoModal'
import { TextInput } from '../inputs/TextInput'
import { Table, TSort } from '../Table/Table'
import { TActivateUser, TCreateUser, TEditUser, TGetRoles, TGetUser, TRoles, TUsers } from '../../store/slices/UsersSlice/UsersSliceTypes'
import { Select } from 'antd'
import { getUser, EditUser, Active, getRoles, CreateUser } from '../../store/slices/UsersSlice/UserSlice'
import s from './ad.module.scss'
import { ISelectDataItem, MultipleSelect } from '../inputs/MultipleSelect'
import { PhoneInput } from '../inputs/MaskInput'
import { openNotification } from '../notifications/notification'
import { TActiveAd, TAds, TCreateAd, TEditAd, TFileAdvertisement, TGetAds, TGetVendingsWithAds, TVendingsWithAd } from '../../store/slices/AdSlice/AdSliceTypes'
import { ActiveAd, CreateAd, EditAd, GetVendingsWithAds, SaveFileAdd, getAds, saveFileAd } from '../../store/slices/AdSlice/AdSlice'
import { ISelectDataItemNew, NewMultipleSelect } from '../inputs/NewMultipleSelect'
import { current } from '@reduxjs/toolkit'

interface IAdEdit {
    SaveFileAdd: Function,
    EditAd:Function,
    getDevices: Function,
    devices: TVendingDevice[] | [],
    getAds:Function,
    currentAd : TAds | null
    activeAd:Function
    setEditAd:Function
    GetVendingsWithAds:Function
    vendingsWithAds:TGetVendingsWithAds[] | null
    setCurrentDeviceLocal: Function
    currentDeviceLocal: any

}

const AdEditing:React.FC<IAdEdit> = ({SaveFileAdd, EditAd, getDevices, devices, getAds, currentAd,activeAd, setEditAd, GetVendingsWithAds, vendingsWithAds,setCurrentDeviceLocal,currentDeviceLocal}) => {
    const [name,  setName] = useState<string | undefined>()
    const [selectedVending, setSelectedVending] = useState<ISelectDataItemNew[]>([])
    const [vending, setVending] = useState<string | undefined>()
    const [order,  setOrder] = useState<number | undefined>()
    const [typeId, setTypeId] = useState<string | undefined>()
    const [image, setImage] = useState<File>();
    const [imageVid, setImageVid] = useState<File>();
    const [preview, setPreview] = useState<string | null>();
    const [previewVid, setPreviewVid] = useState<string | null>();
    const [currentFormData, setCurrentFormData] = useState<any | null>(null)
    const [currentFormDataVid, setCurrentFormDataVid] = useState<any|null>(null)
    const [activeTab, setActiveTab] = useState(0);
    const hiddenFileInput: any = React.createRef()
    const hiddenVideoInput: any = React.createRef()
    const [isActive, setIsActive] = useState<boolean | undefined>()

    const handleClickVid = (event: any) => {
        event.preventDefault()
        hiddenVideoInput.current.click()
    }
    const handleTabClick = (index:any) => {
        setActiveTab(index);
        // openNotification({type: 'warning', message: 'Внимание! При загрузке видео все фото будут удалены! При загрузке фото, Видео будет удалено!'})
      };
      useEffect(() => {
            console.log(activeTab)
      },[activeTab])

    useEffect(() => {
        
        if (imageVid) {
            hiddenVideoInput.current?.focus();
            const reader = new FileReader();
            reader.readAsDataURL(imageVid);
            reader.onloadend = () => {
                setPreviewVid(reader.result as string);
              };
              
          } else {
            setPreviewVid(null)
          }
        //   let type = currentTarget.files[0].name.split(".").splice(-1,1)[0]
            //     if(type === 'jpeg' || type === 'jpg' || type === 'png'){
                    let newFile = {
                        Name: imageVid?.name,
                        Extention: imageVid?.name.split('.')[imageVid.name.split('.').length-1],
                        dataType: '2',
                        Rewrite: true,
                        
                    }
          console.log(imageVid)
          const formData = new FormData();
          if(imageVid){
            formData.append('Files', imageVid)
            formData.append('Json', JSON.stringify({
                Flag: 'I',
                Id: vending,
                Type: "Advertisement.Video",
                Files:[{
                        Name: imageVid?.name,
                        Extention: imageVid?.name.split('.')[imageVid.name.split('.').length-1].toUpperCase(),
                        dataType: '3',
                        Rewrite: true,
                }]
            })
            )
            
            setCurrentFormDataVid({Files: imageVid, Json: JSON.stringify({
                Flag: 'I',
                Id: vending,
                Type: "Advertisement.Video",
                Files:[{
                    Name: imageVid?.name,
                    Extention: imageVid?.name.split('.')[imageVid.name.split('.').length-1].toUpperCase(),
                    dataType: '3',
                    Rewrite: true,
            }]
            })})
          
            console.log(formData)
            console.log(imageVid)
            
        }
    }, [imageVid]);

    useEffect(() => {
        
        if (image) {
            hiddenFileInput.current?.focus();
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onloadend = () => {
                setPreview(reader.result as string);
              };

          } else {
            setPreview(null)
          }
                    let newFile = {
                        Name: image?.name,
                        Extention: image?.name.split('.')[image.name.split('.').length-1],
                        dataType: '2',
                        Rewrite: true,
                        
                    }
          console.log(image)
          const formData = new FormData();
          if(image){
            formData.append('Files', image)
            formData.append('Json', JSON.stringify({
                Flag: 'I',
                Id: vending,
                Type: "Advertisement.Photo",
                Files:[{
                        Name: image?.name,
                        Extention: image?.name.split('.')[image.name.split('.').length-1].toUpperCase(),
                        dataType: '2',
                        Rewrite: true,
                }]
            })
            )
            
            setCurrentFormData({Files: image, Json: JSON.stringify({
                Flag: 'I',
                Id: vending,
                Type: "Advertisement.Photo",
                Files:[{
                    Name: image?.name,
                    Extention: image?.name.split('.')[image.name.split('.').length-1].toUpperCase(),
                    dataType: '2',
                    Rewrite: true,
            }]
            })})
          
            console.log(formData)
            console.log(image)
            
        }
    }, [image]);

    useEffect(() =>{
        console.log(currentAd)
    })
    useEffect(()=> {
        setName(currentAd?.name)
        setVending(currentAd?.vendingId)
        setOrder(currentAd?.order)
        setIsActive(currentAd?.active)
    }, [currentAd])

    
    const getVendingsHandler = () => {
        getDevices({
            count:10000,
            offset:0,
            Processed: true,
        })
    }
    useEffect(()=>{
        getVendingsHandler()
    },[])

    const changeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value)
    }
    const changeOrder = (value:number) =>{
        setOrder(value)
    }
    const changeVending = (value:string | undefined) => {
        setVending(value)
    }
    const handleClick = (event: any) => {
        event.preventDefault()
        hiddenFileInput.current.click()
    }
    const VendingAdsHandlet = async () =>{
        await GetVendingsWithAds({
            count: 10000,
            offset: 0,
            // vendingId:currentDevice?.id
            onSuccess: (data: any) => {
                if(vendingsWithAds?.length){
                    //@ts-ignore
                    setCurrentDeviceLocal(data?.find(item => item.id === currentDeviceLocal.id))
                    
                }  
            }
        })
        console.log(vendingsWithAds)
            
       
            
    }

    const saveAdVid = () => {
        SaveFileAdd({
            formData:currentFormDataVid
        })
        .then(() => {
            if(imageVid == undefined){
        EditAd({
            id:currentAd?.id,
            vendingId:vending,
            name:name,
            fileName:currentAd?.fileName,
            typeId:currentAd?.typeId
        })
            }else{
                EditAd({
                    id:currentAd?.id,
                    vendingId:vending,
                    name:name,
                    fileName:imageVid?.name.replaceAll(' ', '_'),
                    typeId:3
                })
            }
                    // EditAd({
                    //     id:currentAd?.id,
                    //     vendingId:vending,
                    //     name:name,
                    //     fileName:imageVid?.name.replaceAll(' ', '_'),
                    //     typeId:3
                    // })
    
    })
        activeAd({
            id:currentAd?.id,
            active:isActive
        })
        .then(() => {
            getAds({
                count:100000,
                offset:0,
                vendingId:currentAd?.vendingId
            })
            GetVendingsWithAds({
                count:100000,
                offset:0,
            //     // vendingId:currentAd?.vendingId
            })
            
            VendingAdsHandlet()
            
        })
            setEditAd({open: false})
        
        
       
    }

    const saveAd = () => {
        SaveFileAdd({
            formData:currentFormData
                    })
        .then(() => {
        if(image == undefined) {
            EditAd({
            id:currentAd?.id,
            vendingId:vending,
            name:name,
            fileName:currentAd?.fileName,
            typeId:currentAd?.typeId
        })
        }else{
            EditAd({
                id:currentAd?.id,
                vendingId:vending,
                name:name,
                fileName:image?.name.replaceAll(' ', '_'),
                typeId:2
            })
        }
        // EditAd({
        //     id:currentAd?.id,
        //     vendingId:vending,
        //     name:name,
        //     fileName:image?.name.replaceAll(' ', '_'),
        //     typeId:2
        // })
      
        activeAd({
            id:currentAd?.id,
            active:isActive
        })
        })
        .then(() => {
          const promiseGetAd =  getAds({
                count:100000,
                offset:0,
                vendingId:currentAd?.vendingId
            })
           const ptomiseGetVendAd = GetVendingsWithAds({
                count:100000,
                offset:0,
                // vendingId:currentAd?.vendingId
            })
            Promise.all([promiseGetAd,ptomiseGetVendAd]).then(() => {
            VendingAdsHandlet()
            })
            
        })
        setEditAd({open: false})
        
    }

    const deleteUploadImage = () =>{
        setPreview(null)
        setCurrentFormData(null)
        setPreviewVid(null)
        setCurrentFormDataVid(null)
    }

    return(
        
        <div className={s.adModal}>
        <label 
            style={{marginBottom:'-25px'}}
            htmlFor="name">
            Наименование
        </label>
        <Input
            value={name}
            onChange={changeName}
            id="vending"/>
        {/* <label 
            style={{marginBottom:'-25px'}}
            htmlFor="name">
            Порядок
        </label> */}
        {/* <InputNumber
            type='number'
            value={order}
            onChange={changeOrder}
        /> */}
          {/* <label 
            style={{marginBottom:'-25px'}}
            htmlFor="name">
            Вендинг
        </label>
                    <Select 
                        dropdownClassName={s.CustomDropDown}
                        showSearch 
                        allowClear
                        onChange={changeVending} 
                        value={vending || undefined}
                        optionFilterProp="children"
                        style={{marginLeft:'20px', width:'300px'}}
                        placeholder="Выберите вендинг">
                            {devices.map(dev => 
                                    <Select.Option 
                                        key={dev.id} 
                                        value={dev.id}>
                                        {dev?.nameNumber}
                                    </Select.Option>    
                                )}
                    </Select> */}
                {/* <NewMultipleSelect
                    placeholder= {vending}
                    multiple={true}
                    items={devices}
                    selectedItems={selectedVending}
                    setSelected={setSelectedVending}
        /> */}
         {/* <Radio.Group 
                     value={ isActive } 
                     onChange={changeActive}> 
                    <Radio value={ true }>Активировать</Radio>
                    <Radio value={ false }>Деактивировать</Radio>
        </Radio.Group> */}
        
        <div className={s.filters}>
                    <div className={s.Tabs}> 
                    <div className={s.tabsHeader}>
                        <div
                        //  style={{display: 'inline-block', paddingRight:'10px',paddingLeft:'10px', border:'1px solid',backgroundColor:'#662482',color:'#FFFFFF',transition: '0.3s ease'}}
                            className= {`${s.tabItem} ${activeTab === 0 ? s.active : s.tabItem}`}
                            onClick={() => setActiveTab(0)}
                        >
                            Фото
                        </div>
                        <div 
                        // style={{display: 'inline-block', paddingLeft:'10px',paddingRight:'10px', border:'1px solid',backgroundColor:'#662482', borderLeft:'none', color:'#FFFFFF',transition: '0.3s ease'}}
                            className= {`${s.tabItemR} ${activeTab === 1 ? s.active1 : s.tabItemR}`}
                            onClick={() => setActiveTab(1)}
                        >
                            Видео
                        </div>
                    </div>
                    <div className={s.TabsContent}>
                    {activeTab === 0 && (
                        <div className={s.TapsPhoto}>
                            {preview ? (
                                <>
                                <div className={s.photoDiv}>
                                <button onClick ={deleteUploadImage} className={s.buttonClosePrevie}>✕</button>
                                <img className={s.photoimg} src={preview} width={256} height={455} alt={"preview"}  />
                                </div>
                                </>
                            ) : (
                                <div className={s.addBlock}>
                                    <img onClick={handleClick}
                                           src='/images/addBig.svg'
                                           height={32}
                                           width={32}
                                           alt="addBig"
                                           className={s.button}/>
                                    {/* <input type="file" ref={hiddenFileInput}
                                           onChange={handleChangePhotos}
                                           style={{display: 'none'}}/> */}
                                           <input type="file"  style={{ display: "none" }}
                                accept="image/*,.png,.jpg,"
                                onChange={(event: any) => {
                                    if (!name) {
                                        return openNotification({type: 'warning', message: 'Заполните название рекламы!'})
                                    }
                                    if (!vending) {
                                        return openNotification({type: 'warning', message: 'Не заполнен вендинг!'})
                                    }
                                if (event?.target?.files?.length !== 0) {
                                const file = event.target.files[0];
                            //   if (file.size > 1e6) {
                            //     return openNotification({type: 'warning', message: 'Уменьшите размер картинки или выберите другую!'})
                            // }
                                if (file && file.type.substring(0, 5) === "image") {
                                    setImage(file);
                                } else {
                                    setImage(undefined);
                                    
                                }
                                }}}
                                    ref={hiddenFileInput}
                                            />
                            </div>
                            )}
                            <div style={{marginTop:'10px'}}>
                                Рекомендуемый размер: 1366x768
                            </div>
                            <Button  text='Сохранить изменения' style={{margin:'0 auto', marginTop:'10px'}} onClick = {()=>saveAd()}/>
                        </div>
                    )}

                        <div className={s.TabsVideo}>
                            {activeTab === 1 && (
                               <div className={s.TapsVideo}>
                                    {previewVid ? (
                                <>
                                <div className={s.photoDiv}>
                                <button onClick ={deleteUploadImage} className={s.buttonClosePrevie}>✕</button>
                                <video className={s.photoimg} src={previewVid} controls style={{ objectFit: "cover" }}/>
                                </div>
                                </>
                            ) : (
                                <div className={s.addBlock}>
                            
                                    <img onClick={handleClickVid}
                                           src='/images/playbutton.svg'
                                           height={32}
                                           width={32}
                                           alt="addBig"
                                           className={s.button}/>
                                           <input type="file"  style={{ display: "none" }}
                                accept="video/*,.mp4,"
                                onChange={(event: any) => {
                                    if (!name) {
                                        return openNotification({type: 'warning', message: 'Заполните название рекламы!'})
                                    }
                                    if (!vending) {
                                        return openNotification({type: 'warning', message: 'Не заполнен вендинг!'})
                                    }
                                if (event?.target?.files?.length !== 0) {
                                const file = event.target.files[0];
                            //   if (file.size > 1e6) {
                            //     return openNotification({type: 'warning', message: 'Уменьшите размер картинки или выберите другую!'})
                            // }
                                    
                                if (file && file.type.substring(0, 5) === "video") {
                                    setImageVid(file);
                                    
                                } else {
                                    setImageVid(undefined);
                                    
                                }
                                }}}
                                    ref={hiddenVideoInput}
                                    
                                            />
                            </div>
                            )}
                            <Button  text='Сохранить изменения' style={{margin:'0 auto', marginTop:'10px'}} onClick = {()=>saveAdVid()}/>
                            </div>
                                
                            )}
                        </div>
                    </div>
                </div>
            </div>
        
</div>
    )


}

const mapStateToProps = (state: TRootState) => ({
    devices: state.devices.vendingDevices,
    vendingsWithAds: state.ad.advertisingForCurrentVendings
})

const mapDispatchToProps = (dispatch :AppDispatch) => {
    return {
        EditAd: (body: TEditAd) => dispatch(EditAd(body)),
        getDevices:(body:TGetVendings) => dispatch(getVendings(body)),
        SaveFileAdd:(formData: any) => dispatch(SaveFileAdd(formData)),
        getAds: (body: TGetAds) => dispatch(getAds(body)),
        activeAd:(body: TActiveAd) => dispatch(ActiveAd(body)),
        GetVendingsWithAds:(body:TVendingsWithAd) => dispatch(GetVendingsWithAds(body))
    
    }
}

export const AdEditingContainer = connect(mapStateToProps, mapDispatchToProps)(AdEditing)