import { ClickAwayListener, Drawer } from "@material-ui/core";
import React, { useRef, useState } from "react";
import { BASE_URL } from "../../store/api/paths";
import styles from './Panel.module.scss'
import PanelItem from "./PanelItem";
import { HavePermission } from "../difficultFunc/HavePerm";


const Panel:React.FC = () => {
    const [open, setOpen] = useState(false)
    const nodeRef = useRef(null)
    const panelItems = [
        {
            name: 'Устройства',
            url: BASE_URL.panels.menu,
            imageUrl: './images/print.svg'
        },
        {
            name: 'Документы',
            url: BASE_URL.panels.blancDocuments,
            imageUrl: './images/documents.svg'
        },
        
        
        {
            name:'Реклама',
            url:BASE_URL.panels.advertisement,
            imageUrl:'./images/ad.svg'
        }
        

      
      
    ]
    const panelUnavailableItems = [

        {
            name:'Пользователи',
            url: BASE_URL.panels.users,
            imageUrl:'./images/users.svg'
        },
        {
            name:'Версии',
            url: BASE_URL.panels.versions,
            imageUrl: './images/version.svg'
        }

    ]
    return (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
            <Drawer
                transitionDuration={{enter: 250, exit: 250}}
                ref={nodeRef}
                variant="permanent"
                anchor="left"
                open={open}
                className={open ? styles.drawerOpen : styles.drawerClose}
                classes={{
                    paper: open ? styles.drawerOpen : styles.drawerClose
                }}
                >
                
                <div className={styles.panelMain}>
                    <div className={styles.panelHeader}>
                        <div className={!open ? styles.header : styles.headerOpen}>
                            <span style={open ? {display: 'flex', marginRight: -10, alignItems: 'center', gap: 5, textAlign: 'center', fontSize: 17} : {display: 'none'}}>Вендинг администратор</span>
                            <img src={'/images/MenuIcon.svg'} height={30} onClick={() => {setOpen(!open)}} className={styles.menuIcon}/>
                        </div>
                    </div>
                    <div className={styles.panelItems}>
                        
                        {panelItems.map((item, index) => {
                            return <PanelItem open={open} item={item} key={index}/>
                        })}
                        <HavePermission mnemoName="users">
                        {panelUnavailableItems.map((item, index) => {
                            return <PanelItem open={open} item={item} key={index}/>
                        })}
                         </HavePermission>
                    </div>
                </div>
            </Drawer>
        </ClickAwayListener>
    )
}

export default Panel