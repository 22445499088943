import React, { Dispatch, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { setVendingCommand } from '../../store/slices/DevicesSlice/DevicesSlice'
import { TSetVendingCommand, TVendingDevice } from '../../store/slices/DevicesSlice/DevicesSliceTypes'
import { AppDispatch, TRootState } from '../../store/store'
import { Button } from '../buttons/button'
import { ISelectDataItem, MultipleSelect } from '../inputs/MultipleSelect'
import { openNotification } from '../notifications/notification'
import s from './devices.module.scss'
import { Select } from 'antd'

interface IDeviceCommands {
    currentVendingDevice: TVendingDevice | null
    commandsForCurrentDevice: {
        id: number,
        name: string,
        mnemo: string
    }[]
    setVendingCommand: Function
}

const DeviceCommands:React.FC<IDeviceCommands> = ({currentVendingDevice, commandsForCurrentDevice, setVendingCommand}) => {
    const [selectedCommand, setSelectedCommand] = useState<ISelectDataItem[]>([])
    const [newSelectedCom, setNewSelectedCom] = useState('')
    const setCommandHandler = () => {
        if(newSelectedCom)
            setVendingCommand({
                vendingId: currentVendingDevice?.id,
                nameCommand: newSelectedCom
                // selectedCommand[0]?.mnemo
            })
        else 
            openNotification({type:'warning', message:'Выберите команду'})
    }
    return (
        <div className={s.deviceCommandsModal}>
            {/* <MultipleSelect
                multiple={true}
                items={commandsForCurrentDevice}
                selectedItems={selectedCommand}
                setSelected={setSelectedCommand}
                
            /> */}
            <Select 
                showSearch 
                allowClear
                onChange={(val: any) => setNewSelectedCom(val)} 
                dropdownClassName={s.CustomDropDown}
                optionFilterProp="children"
                style={{ width:'250px', height:'40px',marginTop:'10px'}}
                placeholder="Выберите команду">
                    {commandsForCurrentDevice.map(vend => 
                            <Select.Option 
                                key={vend.mnemo} 
                                value={vend.mnemo}>
                                 {vend?.name}
                            </Select.Option>    
                    )}
            </Select>
            <Button text={'Отправить команду'} onClick={() => {setCommandHandler()}}/>
        </div>
    )
}

const mapStateToProps = (state: TRootState) => ({
    commandsForCurrentDevice: state.devices.commandsForCurrentDevice
})

const mapDispatchToProps = (dispatch:AppDispatch) => {
    return {
        setVendingCommand: (body: TSetVendingCommand) => dispatch(setVendingCommand(body))
    }
}

export const DeviceCommandsContainer = connect(mapStateToProps, mapDispatchToProps)(DeviceCommands)