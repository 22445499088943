import { notification } from 'antd'
import styles from './notification.module.scss'

export type TAntdNotificationType = 'success' | 'info' | 'warning' | 'error'

export type TAntdNotificationPayload =  {
    type: TAntdNotificationType
    message: string
    description?: string
    duration?: number
    style?: any
}

export function openNotification(payload: TAntdNotificationPayload) {
    const {type, ...props} = payload
    // TODO сообщение нужно сделать выше по zIndex чем у модалки
    // TODO эта дурацкая библиотека не хочет пробрасывать мои пропсы в нужный мне блок - ооо, да)0)
    const style: any = {zIndex: 9999}
    notification[type]({
      ...props, className: styles.notification, style: style
    });
  }