import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../store";
import { useEffect, useState } from "react"

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector = useSelector;

const decode = (value: any) => {
    return JSON.stringify(value)
  }
  
  const encode = (value: string) => {
    return value
  }
  
  // useLocalStorage hook
  const useLocalStorage = (key: string, defaultState: any) => {
    const [value, setValue] = useState(
      encode(localStorage.getItem(key) || '') || defaultState
    )
  
    useEffect(() => {
      localStorage.setItem(key, decode(value))
    },  [value])
  
    return [value, setValue]
  }
  
  export {
    useLocalStorage
  }

