import { openNotification } from "../../components/notifications/notification"
import { useAppDispatch } from "./hooks"

export const checkError = (err: any) => {
    
    if(err.response?.status === 401){
        window.localStorage.removeItem('ACCESS_TOKEN')
        window.localStorage.removeItem('ACCESS_TOKEN_REFRESH')
         
        debugger
        if(err.config?.url !== "Account/Login")
            window.location.reload()
    }

    let response = err.response?.data?.errorMessages
    if(response){
        openNotification({type: 'error', message: response[0].errorMessage ? response[0].errorMessage : 'Произошла ошибка'})
    } else {
        // openNotification({type: 'error', message: 'Произошла ошибка'})
    }
    
}