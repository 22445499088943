import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getAdvertisings } from '../../store/slices/DevicesSlice/DevicesSlice'
import { TGetAdvertisings, TVendingAdvertising, TVendingDevice } from '../../store/slices/DevicesSlice/DevicesSliceTypes'
import { AppDispatch, TRootState } from '../../store/store'
import useDebouncedFunction from '../../store/utils/helpers'
import { TextInput } from '../inputs/TextInput'
import { Table } from '../Table/Table'
import s from './devices.module.scss'

interface IDeviceAd {
    currentVendingDevice: TVendingDevice | null
    getAdvertisings: Function
    advertisingForCurrentDevice: TVendingAdvertising[] | null
}

const DeviceAd:React.FC<IDeviceAd> = ({currentVendingDevice, getAdvertisings, advertisingForCurrentDevice}) => {

    const headers = [
        {id: 1, name: "Адрес", mnemo: "address"},
        {id: 2, name: "Телефон", mnemo: "phone"},
        {id: 3, name: "Комментарий", mnemo: "comment"},
    ]

    const [currenAdvertising, setCurrentAdvertising] = useState<any>([])

    useEffect(() => {
        if(advertisingForCurrentDevice){
            let arr = advertisingForCurrentDevice[0].advertisings
            arr = arr.map(item => {
                return {
                    ...item,
                    address: advertisingForCurrentDevice[0].address
                }
            })
            setCurrentAdvertising(arr)
        }
    },[advertisingForCurrentDevice])

    const [searchValue, setSearchValue] = useState<string>('')

    useEffect(() => {
        getAdvertisings({count: 1000000, offset: 0, vendingId: currentVendingDevice?.id, searchValue:''})
    }, [])

    const getVendingsAdSearch = useDebouncedFunction((val: string) => {
        setCurrentAdvertising([])
        getAdvertisings({count: 1000000, offset: 0, vendingId: currentVendingDevice?.id, searchValue:val})
    }, 1000)

    return (
        <div className={s.deviceAdMain}>
            <div className={s.deviceAdHeader}>
                Рекламные запросы вендингового апарата "{currentVendingDevice?.nameNumber}"
            </div>
            <div className={s.deviceFilters}>
                <TextInput height={'36px'} value={searchValue} setValue={(val) => {setSearchValue(val); getVendingsAdSearch(val)}} placeholder={'Поиск'}/>
            </div>
            { advertisingForCurrentDevice?.[0]?.advertisings ? <Table
                headers={headers}
                body={currenAdvertising}
                sizes={[25, 18, 52]}
                uniqueProp={'advertisings'}
            /> : null }
        </div>
    )
}

const mapStateToProps = (state: TRootState) => ({
    advertisingForCurrentDevice: state.devices.advertisingForCurrentDevice
})

const mapDispatchToProps = (dispatch:AppDispatch) => {
    return {
        getAdvertisings: (body: TGetAdvertisings) => dispatch(getAdvertisings(body))
    }
}

export const DeviceAdContainer = connect(mapStateToProps, mapDispatchToProps)(DeviceAd)