import React from 'react'
import { BlancDocumentsContainer } from '../components/blancDocuments/blancDocuments'
import Header from '../components/header/Header'
import Panel from '../components/panel/Panel'

const BlancDocuments: React.FC = () => {
    return <>
        <Header/>
        <BlancDocumentsContainer/>
        <Panel/>
    </>
}

export default BlancDocuments