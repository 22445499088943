import React from "react";
import { out, setIsAuthorised, setUserInfo } from "../../store/slices/AuthSlice/AuthSlice";
import { TRootState } from "../../store/store";
import { useAppDispatch, useAppSelector } from "../../store/utils/hooks";
import styles from './Header.module.scss'
import { useNavigate } from "react-router-dom";

const Header:React.FC = () => {
    const userInfo = useAppSelector((state:TRootState) => state.auth.userInfo)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const exit = () => {
        localStorage.removeItem('ACCESS_TOKEN')
        localStorage.removeItem('ACCESS_TOKEN_REFRESH')
        navigate('/')
        dispatch(setIsAuthorised(false))
        // dispatch(out())
    
        window.location.reload()
    }
    return (
        <div className={styles.headerMain}>
            <div className={styles.exit}>
                <img height={35} src={userInfo?.file}/>
                <span className={styles.exitText} onClick={() => {dispatch(setUserInfo(null)); exit()}}>Выйти</span>
            </div>
        </div>
    )
}

export default Header