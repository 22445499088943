import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import BlancDocuments from '../pages/BlancDocuments'
import MainPage from '../pages/MainPage'
import { BASE_URL } from '../store/api/paths'
import UsersPage from '../pages/UsersPage'
import Advertisement from '../pages/AdPage'
import Version from '../pages/VersionsPage'

const AppRouter:React.FC<{}> = ({}) => {
    return (
        <BrowserRouter>
            <Routes>
                {/* <Route path='/' <Navigate to = "/"/>/> */}
                <Route path={BASE_URL.panels.menu} element={<MainPage/>}/>
                <Route path={BASE_URL.panels.blancDocuments} element={<BlancDocuments/>}/>
                <Route path={BASE_URL.panels.users} element = {<UsersPage/>}/>
                <Route path={BASE_URL.panels.advertisement} element = {<Advertisement/>}/>
                <Route path={BASE_URL.panels.versions} element = {<Version/>}/>
                {/* <Route path='/' element={} /> */}
            </Routes>
        </BrowserRouter>
    )
}

export default AppRouter