import {Dialog, withStyles} from "@material-ui/core";

export const StyledDialog = withStyles((style) => ({
    root: style ? {
        margin: 0,
        padding: 0,
        maxWidth: 'auto',
        ...style
    } :
        {
            margin: 0,
            padding: 0,
            maxWidth: 'auto',
        },
    paper: {
        background: 'transparent',
        boxShadow: 'none',
        maxWidth: 'none',
        borderRadius: 0,
        margin: 0
    }
}))(Dialog);