import { useRef } from "react"
import { TLoading } from "../slices/types"

export const checkLoaders = (loadings: TLoading[]) => {
    let object:TLoading = {state: false, text: ''}
    if(loadings)
        object = Object.values(loadings).find((value:any) => value.state) as TLoading
    return object
}

export const getPropertiesAsArray = (object: any) => {
    let array:any[] = []
    if(object){
        Object.values(object).map((value: any) => {
            array.push(value)
        })
    }    
    return array
}

export const getInlineParams= (item: any) => {
    let result = '?'
    const keys = Object.keys(item)
    keys.forEach((key, index) => {
        if(item[key] || item[key] === 0 || item[key] === false){
            result += key + '=' + item[key]
            if(index +1 !== keys.length){
                result += '&'
            }
        }
    })
    return result
}

export default function useDebouncedFunction(func: Function, delay: number) {
    const ref = useRef<any>(null);
    return (...args: any) => {
        clearTimeout(ref.current);
        ref.current = setTimeout(() => func(...args), delay);
    };
}

export function transformFileToBase64Str(str: string) {
    let _str = str
    _str = _str.toString().split(',')[1]
    if (_str) return _str
    else return ''
}


export const operationsErrors = [5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,58,59,62,63,64,65,66,67,68]
export const devicesErrors = [24, 68]
export const adsErrors = [false]
export const vendingError = [true]
