
import { DatePicker, Input, InputNumber, Radio, RadioChangeEvent } from 'antd'
import moment, { Moment } from 'moment'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { NULL } from 'sass'
import { EditVending, getVendingOperations, getVendings } from '../../store/slices/DevicesSlice/DevicesSlice'
import { TEditVending, TGetVendings, TOperationJob, TVendingDevice, TVendingOperation } from '../../store/slices/DevicesSlice/DevicesSliceTypes'
import { AppDispatch, TRootState } from '../../store/store'
import useDebouncedFunction, { operationsErrors } from '../../store/utils/helpers'
import { Button } from '../buttons/button'
import { InfoDialog } from '../dialogs/InfoModal'
import { TextInput } from '../inputs/TextInput'
import { Table, TSort } from '../Table/Table'
import { TActivateUser, TEditUser, TGetRoles, TGetUser, TGetUsers, TRoles, TUsers } from '../../store/slices/UsersSlice/UsersSliceTypes'
import { Select } from 'antd'
import { getUser, EditUser, Active, getRoles, getUsers } from '../../store/slices/UsersSlice/UserSlice'
import s from './versions.module.scss'
import { ISelectDataItem, MultipleSelect } from '../inputs/MultipleSelect'
import { TEditVer, TGetVer, TVersions } from '../../store/slices/VersionSlice/VersionSliceType'
import { EditVer, getVer } from '../../store/slices/VersionSlice/VersionSlice'

interface IVersionEdit {
    currentVersion : TVersions | null
    getVersions: Function
    // Active: Function
    EditVersion:Function
    setVersionEditing:Function  
}

const VersionEditing:React.FC<IVersionEdit> = ({currentVersion,  EditVersion,setVersionEditing, getVersions}) =>{
        const [version,  setVersion] = useState<number | undefined>()
        const [count, setCount] = useState<number>(30)
        const [offset, setOffset] = useState<number>(0)
   

        useEffect(() => {
            setVersion(currentVersion?.version)
            // setOrg(currentVendingDevice?.name)
        
        }, [currentVersion])

      

        const changeVersion = (value: number | undefined) => {
            setVersion(value)
        }



        const saveVersion = () => {
            EditVersion({
                Id: currentVersion?.id,
                version: version
                
            }).then(() =>{
                getVersions({
                    offset:offset,
                    count:count,
                })
            })
            setVersionEditing({open:false})
            
            // .then(() => { 
            //     if(getVendingsHandler() !== undefined)
            //     getVendingsHandler()
            //     })
            

            
            
        }
        // useEffect(() =>{
        //     getVendingsHandler()
        // },[])


    return (
        <div className={s.editVendingModal}>
                <label
                    style={{marginBottom:'-25px'}}
                    htmlFor="version">
                    Версия 
                </label>
                <InputNumber
                    value={version}
                    onChange={changeVersion}
                    // {(val) => {changeLeftPaper(val.currentTarget.value ? Number(val.currentTarget.value) : 0)}}
                    id="version"/>


                <Button text='Сохранить' onClick = {()=>saveVersion()}/>
        </div>
    )
}
// const mapStateToProps = (state: TRootState) => ({
//     users: state.users.users
// })
const mapDispatchToProps = (dispatch :AppDispatch) => {
    return {
        EditVersion: (body: TEditVer) => dispatch(EditVer(body)),
        getVersions: (body: TGetVer) => dispatch(getVer(body)),
    }
    
        
    
}
export const VersionEditingContainer = connect(null, mapDispatchToProps)(VersionEditing)