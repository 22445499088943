import React from 'react'
import { BlancDocumentsContainer } from '../components/blancDocuments/blancDocuments'
import Header from '../components/header/Header'
import Panel from '../components/panel/Panel'
import { AdContainer } from '../components/advertisement/ad'

const Advertisement: React.FC = () => {
    return <>
        <Header/>
            <AdContainer/>
        <Panel/>
    </>
}

export default Advertisement