import React, {CSSProperties, useEffect, useState} from 'react'
import {StyledDialog} from "./styles";
// import {IConfirmModalProps, IInfoModalProps} from "./types";
import classes from './classes.module.scss'
import {CloseDialogButton} from "./CloseDialogButton";
import styles from "../../../profilePage/promocodePage.module.css";
import s from "../../../_admin/dessertBannerPage/DessertBannerPage.module.css";
import { Button } from '../buttons/button';

interface IInfoModalProps {
    handleClose(): void
    callBack?: () => void
    open: boolean
    text?: any
    className?: string
    children?: any
    style?: CSSProperties
    turnOffStyledDialogCloseHandler?: boolean
    ok?: boolean
    //Пусть пока побудет тут
    svg?:any
    styleCloseButton?: CSSProperties
    component?: any,
    cannotClose?: boolean

    [props: string]: any
}

export const InfoDialog: React.FC<IInfoModalProps> = ({
                                                          open,
                                                          handleClose,
                                                          turnOffStyledDialogCloseHandler,
                                                          text,
                                                          cannotClose = false,
                                                          className = "",
                                                          style,
                                                          callBack,
                                                          svg,
                                                          styleCloseButton,
                                                          component,
                                                          ok,
                                                          children = null
                                                      }) => {
    useEffect(() => {

        if (callBack) {
            callBack()
        }
    }, [])

    // useEffect(()=>{
    //    if (svg) document.getElementById("QRCode").innerHTML = svg
    // },[svg])

    const [mobile, setMobile] = useState(false)
    useEffect(() => {
        if (window.innerWidth < 600) {
            setMobile(true)
        }
    }, [])

    return (
        <StyledDialog open={open} onClose={!turnOffStyledDialogCloseHandler ? !cannotClose ? handleClose : () => {} : () => {}} style={style}>
            <div className={classes.wrap + ' ' + className}>
                {!cannotClose ? <CloseDialogButton handleClose={handleClose} style={styleCloseButton}/> : null }
                {
                    text ?
                    <div className={classes.text} style={{flexGrow: 1, marginTop: 10}}>
                        {
                            text
                        }
                    </div> : null
                }
                {
                    component ? component : null
                }
                {
                    svg ? <div className={classes.inviteQRCode} id={"QRCode"}> </div> : children
                }
                {
                    ok ? <Button
                            className={classes.buttonInfoModal}
                            onClick={() => {handleClose()}}
                            width={mobile ? '200px' : '200px'}
                            height={mobile ? '49px' : '40px'}
                            text={'Ок'}
                            backgroundColor={'#BCA263'}/>
                        : null
                }
            </div>
        </StyledDialog>
    )
}