import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { openNotification } from "../../../components/notifications/notification"
import { instanceAxiosClose } from "../../api/instances"
import { paths } from "../../api/paths"
import { checkError } from "../../utils/checkErrors"
import { getInlineParams } from "../../utils/helpers"
import { TActivateUser, TCreateUser, TEditUser, TGetRoles, TGetUser, TGetUsers, TUserPassword, UsersState } from "./UsersSliceTypes"



export const getUsers = createAsyncThunk("getUsers", async (body: TGetUsers) => {
    try {
        const { data } = await instanceAxiosClose(localStorage.getItem('CURRENT_SERVICE_URL')).get(paths.usersService.getUsers + getInlineParams(body))
        return data?.data.value
    } catch (err: any) {
        checkError(err)
    }
})
export const getUser = createAsyncThunk("getUsers", async (body: TGetUser) => {
    try {
        const { data } = await instanceAxiosClose(localStorage.getItem('CURRENT_SERVICE_URL')).get(paths.usersService.getUser + getInlineParams(body))
        return data?.data.value
    } catch (err: any) {
        checkError(err)
    }
})
export const EditUser = createAsyncThunk("editUser", async (body: TEditUser) => {
    try {
        const { data } = await instanceAxiosClose(localStorage.getItem('CURRENT_SERVICE_URL')).post(paths.usersService.EditUser, body)
        return data
    } catch (err: any) {
        checkError(err)
    }
})
export const CreateUser = createAsyncThunk("createUser", async (body: TCreateUser) => {
    try {
        const { data } = await instanceAxiosClose(localStorage.getItem('CURRENT_SERVICE_URL')).post(paths.usersService.CreateUser, body)
        return data
    } catch (err: any) {
        checkError(err)
    }
})
export const getRoles = createAsyncThunk("getRoles", async (body: TGetRoles) => {
    try {
        const { data } = await instanceAxiosClose(localStorage.getItem('CURRENT_SERVICE_URL')).get(paths.usersService.getRoles + getInlineParams(body))
        return data?.data.value
    } catch (err: any) {
        checkError(err)
    }
})
export const Active = createAsyncThunk("account/activation", async(body:TActivateUser) => {
    try {
        const { data } = await instanceAxiosClose(localStorage.getItem('CURRENT_SERVICE_URL')).post(paths.usersService.EditActive, body)
        return data
    } catch (err: any) {
        checkError(err)
    }
})

export const getPassword = createAsyncThunk("account/password", async(body:TUserPassword) => {
    try {
        const { data } = await instanceAxiosClose(localStorage.getItem('CURRENT_SERVICE_URL')).post(paths.usersService.getPassword, body)
        return data
    } catch (err: any) {
        checkError(err)
    }
})


const initialState : UsersState = {
    showMoreUsers:true,
    users:[],
    user:null,
    roles:[],
    userPass: undefined,
    loaders:{
        usersLoading: {state: false, text: ''},
        editingUsersLoading: {state: false, text: ''},
        createUsersLoading: {state: false, text: ''},
    }


}

export const usersSlice = createSlice({
    name:'UsersSlice',
    initialState,
    reducers:{
        clearUsers(state){
            // state.usersList = []
        },
        setUserInfo(state, { payload }) {
            state.user = payload
        },
        clearPassword(state) {
            state.userPass = undefined
        }
    },

    extraReducers:(builder) => {

        builder.addCase(getUsers.pending, (state, action) => {
            state.loaders.usersLoading = {state: true, text: 'Загрузка пользователей'}
            
        })
        builder.addCase(getUsers.fulfilled, (state, action) => {
            state.loaders.usersLoading = {state: false, text: 'Загрузка пользователей'}
            if(action.meta.arg.offset === 0 && action.payload.length){
                state.users = action.payload
                if(action.payload.length < action.meta.arg.count){
                    state.showMoreUsers = false
                }
            } else  
                if (action.payload.length){
                    state.users = [...state.users, action.payload]
                    if(action.payload.length < action.meta.arg.count){
                        state.showMoreUsers = false
                    }
                }
                    
        })
        builder.addCase(getUsers.rejected, (state, action) => {
            state.loaders.usersLoading = {state: false, text: 'Загрузка пользователей'}
            state.showMoreUsers = false
        })

        builder.addCase(getRoles.fulfilled, (state, action) => {
            state.loaders.usersLoading = {state: false, text: 'Загрузка ролей'}
            state.roles = action.payload
        })
        builder.addCase(getRoles.pending, (state, action) => {
            state.loaders.usersLoading = {state: true, text: 'Загрузка ролей'}
        })

        builder.addCase(EditUser.pending,(state, action) =>{
            state.loaders.editingUsersLoading = {state: true, text:'Редактирование пользователя'}
        })
        builder.addCase(EditUser.fulfilled, (state, action) => {
            state.loaders.editingUsersLoading = {state: false, text: 'Редактирование пользователя'}
            if(action.payload){
                openNotification({type: 'success', message: 'Пользователь изменён'})
            }
        })
        builder.addCase(EditUser.rejected, (state, action) => {
            state.loaders.editingUsersLoading = {state: false, text: 'Редактирование пользователя'}
        })
        
        builder.addCase(CreateUser.pending,(state, action) =>{
            state.loaders.editingUsersLoading = {state: true, text:'Создание пользователя'}
        })
        builder.addCase(CreateUser.fulfilled, (state, action) => {
            state.loaders.editingUsersLoading = {state: false, text: 'Создание пользователя'}
            if(action.payload){
                openNotification({type: 'success', message: 'Пользователь создан'})
            }
        })
        builder.addCase(CreateUser.rejected, (state, action) => {
            state.loaders.editingUsersLoading = {state: false, text: 'Создание пользователя'}
        })

        builder.addCase(getPassword.pending,(state, action) =>{
            state.loaders.editingUsersLoading = {state: true, text:'Получение пароля'}
        })
        builder.addCase(getPassword.fulfilled, (state, action) => {
            state.loaders.editingUsersLoading = {state: false, text: 'Получение пароля'}
            if(action.payload){
                state.userPass = action?.payload?.data?.value
                debugger
            }
        })
        builder.addCase(getPassword.rejected, (state, action) => {
            state.loaders.editingUsersLoading = {state: false, text: 'Получение пароля'}
        })

    }

})

export const {clearUsers, clearPassword} = usersSlice.actions

export default usersSlice.reducer