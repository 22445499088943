
import { DatePicker, Pagination } from 'antd'
import moment, { Moment } from 'moment'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { NULL } from 'sass'
import { clearCurrentVendingOperations, getVendingOperations } from '../../store/slices/DevicesSlice/DevicesSlice'
import { TGetVendings, TOperationJob, TVendingDevice, TVendingOperation } from '../../store/slices/DevicesSlice/DevicesSliceTypes'
import { AppDispatch, TRootState } from '../../store/store'
import useDebouncedFunction, { operationsErrors } from '../../store/utils/helpers'
import { Button } from '../buttons/button'
import { InfoDialog } from '../dialogs/InfoModal'
import { TextInput } from '../inputs/TextInput'
import { Table, TSort } from '../Table/Table'
import s from './devices.module.scss'

interface IDeviceStatistic {
    currentVendingDevice: TVendingDevice | null
    getVendingOperations: Function
    deviceOperations: TVendingOperation[]
    showMoreOperations: boolean
}

const DeviceStatictic:React.FC<IDeviceStatistic> = ({ currentVendingDevice, getVendingOperations, deviceOperations, showMoreOperations }) => {
    const [count, setCount] = useState<number>(30)
    const [offset, setOffset] = useState<number>(0)
    const [searchValue, setSearchValue] = useState<string>('')
    const [processed, setProcessed] = useState<boolean>(true)
    const [dateBeg, setDateBeg] = useState<Moment | null>(moment().subtract(2, 'month'))
    const [dateEnd, setDateEnd] = useState<Moment | null>(moment())
    const [deviceJobs, setDeviceJobs] = useState<{open: boolean, currentJobs: TOperationJob[] | null}>({open: false, currentJobs: null})
    const [showJson, setShowJson] = useState<{open: boolean, currentJob: TOperationJob | null}>({open: false, currentJob: null})
    const [currentDeviceOperations, setCurrentDeviceOperations] = useState<any[] | []>([])
    const [currentSort, setCurrentSort] = useState<TSort>({orderBy: '', isAsc: null})
    const [sum, setSum] = useState<number>(0)
    const [page, setPage] = React.useState(1);

    useEffect(() => {
        if(deviceOperations.length > 0){
        let deviceOperationLocal = deviceOperations?.length ? deviceOperations.map( operation => {
            return {
                ...operation,
                countOperation: `<div style='margin-left: 80px'>${operation?.countOperation} </div>`,
                sumOperation: `<div style='margin-left: 55px'>${operation?.sumOperation} р.</div>`,
            }
        }) : []
        setCurrentDeviceOperations(deviceOperationLocal)
        debugger
        }else{
        setCurrentDeviceOperations([])
        }
     
    },[deviceOperations, searchValue])


    const setCurrentSortHandler = (val: TSort) => {
        setCurrentSort(val)
        if(currentVendingDevice?.id)
            getVendingOperations({
                orderBy: val.orderBy,
                isAsc: val.isAsc,
                count: offset + count,
                offset: 0,
                vendingId: currentVendingDevice.id,
                searchValue: searchValue,
                Processed: processed,
                DateBeg: dateBeg ? dateBeg.set('hour', 0).set('minute', 0).set('millisecond', 0).format('YYYY-MM-DDT00:00:00') : null,
                DateEnd: dateEnd ? dateEnd.set('hour', 23).set('minute', 59).set('millisecond', 59).format('YYYY-MM-DDT23:59:59') : null,
            })
    }


    const showMoreHandler = () => {
        if(currentVendingDevice?.id)
        {
            setCount(count + 30)
            getVendingOperations({
                orderBy: currentSort.orderBy,
                isAsc: currentSort.isAsc,
                count: count,
                offset: offset,
                vendingId: currentVendingDevice.id,
                searchValue: searchValue,
                Processed: processed,
                DateBeg: dateBeg ? dateBeg.set('hour', 0).set('minute', 0).set('millisecond', 0).format('YYYY-MM-DDT00:00:00') : null,
                DateEnd: dateEnd ? dateEnd.set('hour', 23).set('minute', 59).set('millisecond', 59).format('YYYY-MM-DDT23:59:59') : null,
            })
        }
    }


    const headers = [
        {id: 1, name: "Тип операции", mnemo: "typeOperationName", orderBy: 'typeOperationName'},
        {id: 2, name: "Количество операций", mnemo: 'countOperation', orderBy: 'countOperation'},
        {id: 3, name: "Статус операции", mnemo: "errorName", orderBy: 'errorName'},
        {id: 4, name: "Сумма операции", mnemo: 'sumOperation', orderBy: 'sumOperation'},
        {id: 5, name: "Способ оплаты", mnemo: 'wayPayName', orderBy: 'wayPayName'},
        {id: 6, name: "Статус оплаты", mnemo: 'statusPayName', orderBy: 'statusPayName'},
        {id: 7, name: "Дата", mnemo: "dateCreate", orderBy: 'dateCreate'},
    ]

    // waypayname - способ оплаты
    // countoperation - количество операций

    const jobsHeaders = [
        {id: 1, name: 'ID', mnemo: "jobId"},
        {id: 2, name: 'Дата создания', mnemo: 'dateCreate'},
        {id: 3, name: 'Состояние', mnemo: 'nameError'}
    ]

    const buttons = [
        {id: 1, name: 'deviceJobs', mnemo: 'deviceJobs', image: <img width={25} title={'Задания'} height={25} src='/images/shesterni.svg'/>, function: (val: any) => {setDeviceJobs({open: true, currentJobs: val?.jobs || null})}},
    ]

    const operationsButtons = [
        {id: 1, name: 'deviceJson', mnemo: 'deviceJson', image: <img width={25} title={'Показать JSON'} height={25} src='/images/json.svg'/>, function: (val: TOperationJob) => {setShowJson({open: true, currentJob: val});}},
    ]
    
    //Сумма операций по данным
    var totalsum = deviceOperations.reduce((accum,item) => accum + item.sumOperation, 0)
    
    console.log(totalsum)
    useEffect(() => {
        if(currentVendingDevice?.id)
            getVendingOperations({
                orderBy: currentSort.orderBy,
                isAsc: currentSort.isAsc,
                count: count,
                offset: offset,
                vendingId: currentVendingDevice.id,
                searchValue: searchValue,
                Processed: processed,
                DateBeg: dateBeg ? dateBeg.set('hour', 0).set('minute', 0).set('millisecond', 0).format('YYYY-MM-DDT00:00:00') : null,
                DateEnd: dateEnd ? dateEnd.set('hour', 23).set('minute', 59).set('millisecond', 59).format('YYYY-MM-DDT23:59:59') : null,
            })
    },[])

    const getOperationsSearch = useDebouncedFunction((val: string) => {
        setCurrentDeviceOperations([])
        if(currentVendingDevice?.id)
            debugger
            getVendingOperations({
                orderBy: currentSort.orderBy,
                isAsc: currentSort.isAsc,
                count: offset + count,
                offset: 0,
                vendingId: currentVendingDevice?.id,
                searchValue: val,
                Processed: processed,
                DateBeg: dateBeg ? dateBeg.set('hour', 0).set('minute', 0).set('millisecond', 0).format('YYYY-MM-DDT00:00:00') : null,
                DateEnd: dateEnd ? dateEnd.set('hour', 23).set('minute', 59).set('millisecond', 59).format('YYYY-MM-DDT23:59:59') : null,
            })
    }, 1000)

    const getOperationsDateBeg = (val: Moment | null) => {
        setCurrentDeviceOperations([])
        if(currentVendingDevice?.id)
            getVendingOperations({
                orderBy: currentSort.orderBy,
                isAsc: currentSort.isAsc,
                count: offset + count,
                offset: 0,
                vendingId: currentVendingDevice.id,
                searchValue: searchValue,
                Processed: processed,
                DateBeg: val ? val.set('hour', 0).set('minute', 0).set('millisecond', 0).format('YYYY-MM-DDT00:00:00') : null,
                DateEnd: dateEnd ? dateEnd.set('hour', 23).set('minute', 59).set('millisecond', 59).format('YYYY-MM-DDT23:59:59') : null,
            })
    }

    const getOperationsDateEnd = (val: Moment | null) => {
        setCurrentDeviceOperations([])
        if(currentVendingDevice?.id)
            getVendingOperations({
                orderBy: currentSort.orderBy,
                isAsc: currentSort.isAsc,
                count: offset + count,
                offset: 0,
                vendingId: currentVendingDevice.id,
                searchValue: searchValue,
                Processed: processed,
                DateBeg: dateBeg ? dateBeg.set('hour', 0).set('minute', 0).set('millisecond', 0).format('YYYY-MM-DDT00:00:00') : null,
                DateEnd: val ? val.set('hour', 23).set('minute', 59).set('millisecond', 59).format('YYYY-MM-DDT23:59:59') : null,
            })
    }




    return (<div className={s.deviceStatisticMain}>
         <InfoDialog open={deviceJobs.open} handleClose={() => { setDeviceJobs({open: false, currentJobs: null})}}>
            <div className={s.deviceStatisticMainJobsTable}>
                <div className={s.deviceStatisticMainJobsTableHeader}>
                    Задания принтера
                </div>
                <Table
                    customId={'jobId'}
                    headers={jobsHeaders}
                    buttons={operationsButtons}
                    body={deviceJobs?.currentJobs ? deviceJobs.currentJobs.map(item => {return {...item, dateCreate: item?.dateCreate ? moment(item.dateCreate).format("DD.MM.YYYY hh:mm:ss") : ''}}) : []}
                    sizes={[10, 29, 57, 1]}
                />
            </div>
         </InfoDialog>
         <InfoDialog open={showJson.open} handleClose={() => { setShowJson({open: false, currentJob: null})}}>
            <pre>{showJson.currentJob?.body}</pre>
         </InfoDialog>
        <div className={s.deviceStatisticHeader}>
            Операции по вендинговому аппарату {currentVendingDevice?.nameNumber ? `"${currentVendingDevice.nameNumber}"` : ''}
        </div>
        <div className={s.filters}>
                    <div className={s.filterBlock__search}>
                        <TextInput height={'36px'} value={searchValue} setValue={(val) => {setSearchValue(val); getOperationsSearch(val)}} placeholder={'Поиск'}/>
                    </div>
                    <div className={s.filterBlock__datePicker}>
                        <div className={s.title}>Дата от:</div>
                        <DatePicker
                            style={{height: '36px'}}
                            value={dateBeg}
                            onChange={(val) => {setDateBeg(val); getOperationsDateBeg(val)}}
                            placeholder={'Выберите'}
                            format={'DD.MM.YYYY'}
                        />
                    </div>
                    <div className={s.filterBlock__datePicker}>
                        <div className={s.title}>Дата до:</div>
                        <DatePicker
                            style={{height: '36px'}}
                            value={dateEnd}
                            onChange={(val) => {setDateEnd(val); getOperationsDateEnd(val)}}
                            placeholder={'Выберите'}
                            format={'DD.MM.YYYY'}
                        />
                    </div>
                    <div className={s.sum}>
                        Cумма операций: {
                        currentDeviceOperations.length > 0 ?
                        totalsum :
                        0
                        }р.
                    </div>
                    <div className={s.operCount}>
                        Отображенное количество операций: {
                        currentDeviceOperations.length
                        }
                    </div>
                    {/* <div className={s.filterBlock}>
                        <Button height={'36px'} width={'120px'} text={'Применить'} onClick={() => getVendingsHandler()}/>
                    </div> */}
                </div>
        <div className={s.deviceStatisticTable}>
            
            <Table
                customId={'orderId'}
                buttons={buttons}
                headers={headers}
                body={currentDeviceOperations}
                rowClassName={s.rowClassName}
                badRow={{className: s.badRowClassName, customId: 'errorId', matchIds: operationsErrors}}
                sizes={[10, 15, 20, 13, 10, 12, 14, 1]}
                sort={currentSort}
                setSort={setCurrentSortHandler}
            />

        </div>
                    {/* <Pagination
                        defaultCurrent={6} 
                        total={500}
                    /> */}
        { showMoreOperations ? <div className={s.showMore}>
                    <Button text={'Показать еще...'} onClick={() => {showMoreHandler()}}/>
                </div> : null }
    </div>)
}

const mapStateToProps = (state: TRootState) => ({
    deviceOperations: state.devices.currentVendingOperations,
    showMoreOperations: state.devices.showMoreOperations
})

const mapDispatchToProps = (dispatch:AppDispatch) => {
    return {
        getVendingOperations: (body: TGetVendings) => dispatch(getVendingOperations(body))
    }
}

export const DeviceStaticticContainer = connect(mapStateToProps, mapDispatchToProps)(DeviceStatictic)