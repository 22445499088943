import { StylesProvider } from '@material-ui/core';
import { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import './App.css';
import AuthModal from './components/auth/AuthModal';
import { Button } from './components/buttons/button';
import { InfoDialog } from './components/dialogs/InfoModal';
import { LoadingModal } from './components/dialogs/LoadingModal';
import AppRouter from './router/AppRouter';
import { checkConnect, getMyInfo } from './store/slices/AuthSlice/AuthSlice';
import { TLoading } from './store/slices/types';
import { TRootState } from './store/store';
import { checkLoaders, getPropertiesAsArray } from './store/utils/helpers';
import { useAppDispatch, useAppSelector, useLocalStorage } from './store/utils/hooks';

import styles from './wrapper.module.scss'
import { TextInput } from './components/inputs/TextInput';
import { paths } from './store/api/paths';

function App() {
  const [isAuthorised, setIsAuthorised] = useState<boolean>(false)
  const stateIsAuthorised = useAppSelector((state: TRootState) => state.auth.isAuthorised)
  const userInfo = useAppSelector((state: TRootState) => state.auth.userInfo)
  const serviceIsAvailable = useAppSelector((state: TRootState) => state.auth.serviceIsAvailable)
  const [loadObject, setLoadObject] = useState<TLoading>({state: false, text: ''})
  const authLoaders = useAppSelector((state: TRootState) => state.auth.loaders)
  const [serviceUrl, setServiceUrl] = useState<string>(localStorage.getItem('CURRENT_SERVICE_URL') || paths.baseURL)
  const devicesLoaders = useAppSelector((state: TRootState) => state.devices.loaders)
  // const accessToken = useAppSelector((state:TRootState) => state.auth.access_token)
  const [access_token, setAccess_token] = useState(useAppSelector((state: TRootState) => state.auth.access_token) || localStorage.getItem('ACCESS_TOKEN'))
  const documentsLoaders = useAppSelector((state: TRootState) => state.documents.loaders)
  const adsLoaders = useAppSelector((state: TRootState) => state.ad.loaders)
  const usersLoaders = useAppSelector((state:TRootState) => state.users.loaders)
  const dispatch = useAppDispatch()
  // const access_token = localStorage.getItem('ACCESS_TOKEN')
  // const [access, setAccess] = useState(localStorage.getItem('ACCESS_TOKEN'))

  useEffect(() => {
    let loadingsArray = []
    loadingsArray = [...getPropertiesAsArray(authLoaders), ...getPropertiesAsArray(devicesLoaders), ...getPropertiesAsArray(documentsLoaders),...getPropertiesAsArray(adsLoaders),...getPropertiesAsArray(usersLoaders)]
    setLoadObject(checkLoaders([...loadingsArray]) || {state: false, text: ''})
},[authLoaders, devicesLoaders, documentsLoaders, adsLoaders, usersLoaders])

  useEffect(() => {
    if(localStorage.getItem('ACCESS_TOKEN'))
        dispatch(getMyInfo())
      dispatch(checkConnect(serviceUrl))
  
  },[])

  useEffect(() => {
    setAccess_token(localStorage.getItem('ACCESS_TOKEN'))
  })

  

  return (
    <div className={styles.wrapper}>
      <LoadingModal open={loadObject}/>
      { serviceIsAvailable === false ? <>
        <InfoDialog open={true} cannotClose={true} handleClose={() => {}}>
          <div className={styles.infoService}>
            <div className={styles.infoServiceTitle}>Сервис временно недоступен</div>
            <div className={styles.authModal__inputs__input}>
                    <span className={styles.authModal__inputs__input__title}>URL сервиса</span>
                    <TextInput height={'40px'} width={'250px'} className={styles.authModal__inputs__input__textInput} value={serviceUrl} setValue={setServiceUrl} />
              </div>
            <Button className={styles.tryAgain} style={{fontSize: 10}} onClick={() => {localStorage.setItem('CURRENT_SERVICE_URL', serviceUrl); window.location.reload()}} text={'Попробовать снова'}/>
          </div>
        </InfoDialog>
      </> : !access_token ? <AuthModal/> : null}
      {access_token ? <AppRouter /> : null}
    </div>
  );
}

export default App;
