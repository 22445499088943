import React from "react";
import { DevicesContainer } from "../components/devices/devices";
import Header from "../components/header/Header";
import Panel from "../components/panel/Panel";

const MainPage:React.FC = () => {
    return (
        <>
            <Header/>
            <DevicesContainer/>
            <Panel/>
        </>
    )
}

export default MainPage