import React from "react";
import { Link, NavLink } from "react-router-dom";
import styles from './Panel.module.scss'

export type IPanelItem = {
    item:{icon?: any,
    name: string, url: string, imageUrl: string},
    open: boolean,
    
}

const PanelItem:React.FC<IPanelItem> = ({item, open}) => {
    return (
        <NavLink to={item.url}><div className={open ? styles.panelItemMain  + ' ' + 'panelItemMain' : styles.panelItemMain}>
            <div className={!open ? styles.icon + ' ' + 'panelItemMain' : styles.icon}>
                <img width={'25px'} src={item.imageUrl}/>
            </div>
            {open ? <div className={styles.name}>
                {item.name}
            </div> : null }
        </div>
        </NavLink>
    )
}

export default PanelItem    