import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { openNotification } from "../../../components/notifications/notification"
import { instanceAxiosClose } from "../../api/instances"
import { paths } from "../../api/paths"
import { checkError } from "../../utils/checkErrors"
import { getInlineParams } from "../../utils/helpers"
import { DocumentsState, TDeleteBlancDocument } from "./DocumentsSliceTypes"

export const getBlancDocuments = createAsyncThunk("getBlancDocuments", async () => {
    try {
        const { data } = await instanceAxiosClose(localStorage.getItem('CURRENT_SERVICE_URL')).get(paths.documentsService.getBlancDocuments)
        return data?.data.value
    } catch (err: any) {
        checkError(err)
    }
})

export const deleteBlancDocument = createAsyncThunk("deleteBlancDocument", async (body: TDeleteBlancDocument) => {
    try {
        const { data } = await instanceAxiosClose(localStorage.getItem('CURRENT_SERVICE_URL')).post(paths.documentsService.deleteBlancDocument, body)
        return data?.data
    } catch (err: any) {
        checkError(err)
    }
})

export const saveFile = createAsyncThunk('saveFile', async (body: TDeleteBlancDocument) =>{
    try{
        const { data } = await instanceAxiosClose(localStorage.getItem('CURRENT_SERVICE_URL')).post(paths.documentsService.saveFile, body)
        return data?.data
    } catch (err: any){
        checkError(err)
    }
})


const initialState:DocumentsState = {
    blancDocumentsList: [],
    loaders:{
        blancDocumentsLoading: {state: false, text: ''},
        deleteDocumentLoading: {state: false, text: ''},
        saveFileDocumentLoading: {state: false, text: ''}
    }
}


export const documentsSlice = createSlice({
    name: 'DocumentsSlice',
    initialState,
    reducers:{
        clearBlancDocument(state){
            state.blancDocumentsList = []
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getBlancDocuments.pending, (state, action) => {
            state.loaders.blancDocumentsLoading = {state: true, text: 'Получение списка бланковых документов'}
        })
        builder.addCase(getBlancDocuments.fulfilled, (state, action) => {
            state.loaders.blancDocumentsLoading = {state: false, text: 'Получение списка бланковых документов'}
            if(action.payload){
                state.blancDocumentsList = action.payload
            }
        })
        builder.addCase(getBlancDocuments.rejected, (state, action) => {
            state.loaders.blancDocumentsLoading = {state: false, text: 'Получение списка бланковых документов'}
        })

        builder.addCase(deleteBlancDocument.pending, (state, action) => {
            state.loaders.deleteDocumentLoading = {state: true, text: 'Удаление документа'}
        })
        builder.addCase(deleteBlancDocument.fulfilled, (state, action) => {
            state.loaders.deleteDocumentLoading = {state: false, text: 'Удаление документа'}
            if(action.payload){
                openNotification({type: 'success', message: 'Файл успешно удален'})
            }
        })
        builder.addCase(deleteBlancDocument.rejected, (state, action) => {
            state.loaders.deleteDocumentLoading = {state: false, text: 'Удаление документа'}
        })

        builder.addCase(saveFile.pending, (state, action) => {
            state.loaders.saveFileDocumentLoading = {state: true, text: 'Загрузка файла'}
        })
        builder.addCase(saveFile.fulfilled, (state, action) => {
            state.loaders.saveFileDocumentLoading = {state: false, text: 'Загрузка файла'}
            if(action.payload){
                openNotification({type: 'success', message: 'Файл успешно загружен'})
            }
        })
        builder.addCase(saveFile.rejected, (state, action) => {
            state.loaders.saveFileDocumentLoading = {state: false, text: 'Загрузка файла'}
        })
    }
})

export const { clearBlancDocument } = documentsSlice.actions

export default documentsSlice.reducer;