import React, {useEffect, useRef, useState} from 'react'
import { TGetRoles, TGetUsers, TRoles, TUsers } from '../../store/slices/UsersSlice/UsersSliceTypes'
import { TSort, Table } from '../Table/Table'
import useDebouncedFunction, { devicesErrors } from '../../store/utils/helpers'
import { InfoDialog } from '../dialogs/InfoModal'
import { DeviceStaticticContainer } from '../devices/deviceStatistic'
import { DeviceCommandsContainer } from '../devices/deviceCommands'
import { TextInput } from '../inputs/TextInput'
import { Button } from '../buttons/button'
import s from './ad.module.scss'
import { AppDispatch, TRootState } from '../../store/store'
import { getRoles, getUsers } from '../../store/slices/UsersSlice/UserSlice'
import { connect } from 'react-redux'
import { Select } from 'antd'
import { HavePermission } from '../difficultFunc/HavePerm'
import { AdCreatingContainer } from './createAd'
import { TAds, TDeleteAd, TEditAd, TGetAds, TGetVendingsWithAds, TVendingsWithAd } from '../../store/slices/AdSlice/AdSliceTypes'
import { TGetVendings, TVendingDevice } from '../../store/slices/DevicesSlice/DevicesSliceTypes'
import { EditAd, GetVendingsWithAds, clearAdvertisingForCurrentVending, deleteAd, deleteAdd, getAds } from '../../store/slices/AdSlice/AdSlice'
import { getVendings } from '../../store/slices/DevicesSlice/DevicesSlice'
import { AdEditingContainer } from './editAd'
import { openNotification } from '../notifications/notification'
import { useAppDispatch } from '../../store/utils/hooks'
import { AdModContainer } from './MonitorAd'

interface IAdd{
    GetVendingsWithAds: Function
    // clearAdvertisingForCurrentVending: Function
    vendingsWithAds: TGetVendingsWithAds[] | []
    showMoreAd: boolean
}

const Adss:React.FC<IAdd> = ({GetVendingsWithAds, vendingsWithAds, showMoreAd}) => {
    const [count, setCount] = useState<number>(30)
    const [offset, setOffset] = useState<number>(0)
    const [vendingDevicesAdCustom, setVendingDevicesAdCustom] = useState<any[] | []>([])
    const [vendingId, setVendingId] = useState<string>('')
    const [processed, setProcessed] = useState<boolean>(true)
    const [name, setName] = useState<string>('')
    const [adsModal, setAdsModal] = useState<{open: boolean, currentDevice: TGetVendingsWithAds | null}>({open: false, currentDevice: null})
    const [currentSort, setCurrentSort] = useState<TSort>({orderBy: '', isAsc: null})
    const [adCreating, setAdCreating] = useState<{open: boolean}>({open: false})
    const dispatch = useAppDispatch()

    const setCurrentSortHandler = (val: TSort) => {
        setCurrentSort(val)
        GetVendingsWithAds({
            count: offset + count,
            offset: 0,
          
        })
    }

    

    const getVendingsAdsHandler = () => {
        GetVendingsWithAds({
            count: count,
            offset: offset,
            // vendingId: vendingId,
            // Processed: true
         
        })
    }
    const showMoreHandler = () => {
        GetVendingsWithAds({
            count: count,
            offset: offset,
            
        })
        // setOffset(offset + count)
        setCount(count+30)
    }
    const changeVendingAd = (value:string) => {
        // setVendingId(value)
        GetVendingsWithAds({
            count: count,
            offset: offset,
            vendingId: value,
        })
    }

    useEffect(() => {
        getVendingsAdsHandler()
    },[count])

    const headers = [
        
        {id: 1, name: "Название", mnemo: "nameNumber"},
        {id: 2, name: "Адрес", mnemo: "address"}
    ]

    const buttons = [
        
        {id: 1, name: 'vendingWithAd', mnemo: 'vendingWithAd', image: <img title={'Просмотр рекламы'} width={25} height={25} src='/images/horn.svg'/>, function: (val: TGetVendingsWithAds) => setAdsModal({open: true, currentDevice: val})},
        ]
    useEffect(() => {
        setVendingDevicesAdCustom(
            vendingsWithAds?.length ? 
            vendingsWithAds.map( dev => {
                    console.log(dev.nameNumber)
                    return {
                        ...dev,
                    }
                })
                 : []
            )
        },[vendingsWithAds])

    return(
        <>
        <div className={s.mainBlock}>
             <InfoDialog open={adCreating.open} handleClose={() => {setAdCreating({open: false})}}>
                <AdCreatingContainer setAdCreating={setAdCreating} vendingsWithAds={vendingsWithAds}/>
            </InfoDialog>
            <InfoDialog open={adsModal.open} handleClose={() => {setAdsModal({open: false, currentDevice: null});}}>
                <AdModContainer currentDevice={adsModal.currentDevice} setAdsModal={setAdsModal}/>
            </InfoDialog>
            <div className={s.tableContainer}>
                <div className={s.filters}>
                    
                    <Select 
                        showSearch 
                        allowClear
                        onChange={changeVendingAd} 
                        optionFilterProp="children"
                        style={{ width:'250px', height:'40px',marginTop:'10px'}}
                        placeholder="Вендинг">
                            {vendingsWithAds.map(vend => 
                                    <Select.Option 
                                        key={vend.id} 
                                        value={vend.id}>
                                        {vend?.nameNumber}
                                    </Select.Option>    
                                )}
                    </Select>
                    <Button text='Добавить рекламу' onClick={()=>setAdCreating({open: true}) }/>
                </div>
             {/* <div>
                {
                    vendingsWithAds.map(dev=>
                        <div>
                            {dev.nameNumber}
                        </div>
                        )
                }
             </div> */}
                
            
                <div className={s.table}>
                                <Table
                         buttons={buttons}
                        headers={headers}
                        body={vendingDevicesAdCustom}
                        sizes={[50,30,20]}
                        // badRow={{className: s.badRowClassName, customId: 'errorId', matchIds: devicesErrors}}
                        sort={currentSort}
                        setSort={setCurrentSortHandler}
                                />
                </div>
                { showMoreAd ? <div className={s.showMore}>
                    <Button text={'Показать еще...'} onClick={() => {showMoreHandler()}}/>
                </div> : null }
            </div>
        </div>
        </>
    )


}
const mapStateToProps = (state: TRootState) => ({
    vendingsWithAds: state.ad.advertisingForCurrentVendings,
    showMoreAd: state.ad.showMoreAd
})

const mapDispatchToProps = (dispatch:AppDispatch) => {
    return {
        GetVendingsWithAds:(body:TVendingsWithAd) => dispatch(GetVendingsWithAds(body)),
        clearAdvertisingForCurrentVending: () => dispatch(clearAdvertisingForCurrentVending())
    }
}

export const AdContainer = connect(mapStateToProps, mapDispatchToProps)(Adss)