import React, { CSSProperties } from 'react'
import s from './loadingAnimation.module.css'

export const Loader = () => {
    return (<>
        <div className={s.loader}>
            <div className={s.duo + ' ' + s.duo1}>
                <div className={s.dot + ' ' + s.dotA}></div>
                <div className={s.dot + ' ' + s.dotB}></div>
            </div>
            <div className={s.duo + ' ' + s.duo2}>
                <div className={s.dot + ' ' + s.dotA}></div>
                <div className={s.dot + ' ' + s.dotB}></div>
            </div>
        </div></>)
}