import React, { Dispatch, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { setVendingCommand } from '../../store/slices/DevicesSlice/DevicesSlice'
import { TSetVendingCommand, TVendingDevice } from '../../store/slices/DevicesSlice/DevicesSliceTypes'
import { AppDispatch, TRootState } from '../../store/store'
import { Button } from '../buttons/button'
import { ISelectDataItem, MultipleSelect } from '../inputs/MultipleSelect'
import { openNotification } from '../notifications/notification'
import s from './versions.module.scss'
import { TGetVer, TVersions } from '../../store/slices/VersionSlice/VersionSliceType'
import { getVer } from '../../store/slices/VersionSlice/VersionSlice'
import useDebouncedFunction from '../../store/utils/helpers'
import { TextInput } from '../inputs/TextInput'
import { Table } from '../Table/Table'
import { VersionEditingContainer } from './editVersion'
import { InfoDialog } from '../dialogs/InfoModal'
import { VerCreatingContainer } from './createVersion'

interface IVersions {
    getVer:Function,
    versions: TVersions[]
}

const Versions:React.FC<IVersions> = ({getVer, versions}) => {
   
    const headers = [
        {id:1, name:"Id", mnemo:"id"},
        {id:2, name:"Версия", mnemo:"version"}
    ]
    const buttons = [
        {id:1, name:"EditVersions", mnemo:"Editing",image:<img title={'Редактирование'} width={25} height={25} src='/images/editing.svg'/>, function: (val:TVersions) =>{setVersionEditing({open: true, currentVersion:val})}}
    ]
    const [currentVersions, setCurrentVersions] = useState<any>([])
    const [searchValue, setSearchValue] = useState<string>('')
    const [versionEditing, setVersionEditing] = useState<{open: boolean, currentVersion: TVersions | null}>({open: false, currentVersion: null})
    const [versionCreating, setVersionCreating] = useState<{open: boolean}>({open: false})
    
    useEffect(() =>{
        getVer({
            count: 10000000, offset:0, searchValue:''
        })
    },[])
    const getVerSearch = useDebouncedFunction((val: string) => {
        getVer({count: 1000000, offset: 0, searchValue:val})
    }, 1000)
    useEffect(() => {
        if(versions){
            versions.map(item => {
                return {
                    ...item,
                }
            })
            setCurrentVersions(versions)
        }
    },[versions])
    return (
        <div className={s.verMain}>
            <InfoDialog open={versionEditing.open} handleClose={() => {setVersionEditing({open: false, currentVersion: null});}}>
                <VersionEditingContainer currentVersion={versionEditing.currentVersion} setVersionEditing={setVersionEditing}/>
            </InfoDialog>
            <InfoDialog open={versionCreating.open} handleClose={() => {setVersionCreating({open: false})}}>
                <VerCreatingContainer setVerCreating={setVersionCreating}/>
            </InfoDialog>
        <div className={s.tableContainer}>
            <div className={s.filters}>
            <div className={s.filterBlock__search} style={{marginTop:'9px'}} >
                <TextInput height={'36px'}  value={searchValue} setValue={(val) => {setSearchValue(val); getVerSearch(val)}} placeholder={'Поиск'}/>
                </div>
            <Button text='Добавить версию' width={'150px'} onClick={()=>setVersionCreating({open: true}) }/>
            </div>
            <div className={s.table}>
            { versions ?  <Table
                headers={headers}
                body={currentVersions}
                buttons={buttons}
                sizes={[40, 40, 20]}
                uniqueProp={'version'}
            /> : null }
            </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: TRootState) => ({
    versions: state.ver.versions
})

const mapDispatchToProps = (dispatch:AppDispatch) => {
    return {
        getVer: (body: TGetVer) => dispatch(getVer(body))
    }
}

export const VersionsContainer = connect(mapStateToProps, mapDispatchToProps)(Versions)