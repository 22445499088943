import React, {useEffect, useState} from 'react'
import { TGetRoles, TGetUsers, TRoles, TUsers } from '../../store/slices/UsersSlice/UsersSliceTypes'
import { TSort, Table } from '../Table/Table'
import useDebouncedFunction, { devicesErrors } from '../../store/utils/helpers'
import { InfoDialog } from '../dialogs/InfoModal'
import { DeviceStaticticContainer } from '../devices/deviceStatistic'
import { DeviceCommandsContainer } from '../devices/deviceCommands'
import { TextInput } from '../inputs/TextInput'
import { Button } from '../buttons/button'
import s from './users.module.scss'
import { AppDispatch, TRootState } from '../../store/store'
import { getRoles, getUsers } from '../../store/slices/UsersSlice/UserSlice'
import { connect } from 'react-redux'
import { Select } from 'antd'
import { UserEditingContainer } from './userEdit/userEdit'
import { UserCreatingContainer } from './createUser/createUser'
import { HavePermission } from '../difficultFunc/HavePerm'

interface IUsers{
    getUsers:Function
    users: TUsers[] | []
    showMoreUsers:boolean
    getRoles:Function
    roles: TRoles[] | []

}

const Users:React.FC<IUsers> =({getUsers,users,showMoreUsers, getRoles, roles})=>{
    const [customUsers, setCustomUsers] = useState<any[] | []>([])
    const [count, setCount] = useState<number>(30)
    const [offset, setOffset] = useState<number>(0)
    const [userId, setUserId] = useState<string>('')
    const [search, setSearch] = useState<string>('')
    const [userCreating, setUserCreating] = useState<{open: boolean}>({open: false})
    const [userEditing, setUserEditing] = useState<{open: boolean, currentUser: TUsers | null}>({open: false, currentUser:null})
    const [currentSort, setCurrentSort] = useState<TSort>({orderBy: '', isAsc: null})

    const setCurrentSortHandler = (val: TSort) => {
        setCurrentSort(val)
        getUsers({
            count: offset + count,
            offset: 0,
            order: val.orderBy,
            desc: val.isAsc,
            search: search,
       
        })
    }
    
    useEffect(() => {
        console.log(currentSort)
    },[currentSort])

    
    useEffect(() => {
        setCustomUsers(
            users?.length ? 
            users.map( uusers => {
                return {
                    ...uusers,
                }
            }) : []
        )
    },[users])

    const getUsersHandler = () => {
        getUsers({
            count: count,
            offset: offset,
            order: currentSort.orderBy,
            desc: currentSort.isAsc,
            search: search,
        })
    }
    const getUsersSearch = useDebouncedFunction((val: string) => {
        getUsers({
            count: offset + count,
            offset: 0,
            search: val,
        })
    }, 1000)

    const getRolesHandler = () => {
        getRoles({
            count:10000,
            offset:0
        })
    }

    const showMoreHandler = () => {
        getUsers({
            count: count,
            // offset: offset+count,
            offset: offset,
            order: currentSort.orderBy,
            desc: currentSort.isAsc,
            search: search,
        })
        // setOffset(offset + count)
        setCount(count + 30)
    }

    const changeRole = (value:number) => {
        getUsers({
            count: count,
            offset: 0,
            order: currentSort.orderBy,
            desc: currentSort.isAsc,
            search: search,
            roleId:value
        })
    }

    useEffect(() => {
        getUsersHandler()
        getRolesHandler()
    },[count])

    const headers = [
        {id: 1, name: "ID", mnemo: "id", orderBy: 'id'},
        {id: 2, name:"Логин", mnemo:"login", orderBy: 'login'},
        {id: 3, name: "Имя огранизации", mnemo: "name", orderBy: 'name'},
        {id: 4, name: "Email", mnemo: "email", orderBy:'email'},
        {id: 5, name: "Роль", mnemo: "role", orderBy:'role'},
        {id: 6, name: "Город", mnemo: "town", orderBy:'town'},
        {id: 7, name:"Телефон", mnemo: "phone", orderBy:'phone'}
        // {id: 8, name:}
    ]

    const buttons = [
        {id:1, name:"userEditing",mnemo: 'userEditing',image:<img title={'Редактирование'} width={25} height={25} src='/images/editing.svg'/>, function: (val:TUsers) => {setUserEditing({open: true, currentUser:val}); }},
    ]


    return(
        
           <>
        <div className={s.mainBlock}>
        <InfoDialog open={userEditing.open} handleClose={() => {setUserEditing({open: false, currentUser: null})}}>
                <UserEditingContainer currentUser={userEditing.currentUser} setUserEditing={setUserEditing}/>
        </InfoDialog>

        <InfoDialog open={userCreating.open} handleClose={() => {setUserCreating({open: false})}}>
                <UserCreatingContainer setUserCreating={setUserCreating}/>
        </InfoDialog>
            <div className={s.tableContainer}>
            <HavePermission mnemoName={'users'}>
                <div className={s.filters}>
                    <div className={s.filterBlock__search}>
                        <TextInput height={'36px'} value={search} setValue={(val) => {setSearch(val); getUsersSearch(val)}} placeholder={'Поиск'}/>
                        </div>
                        <Select 
                        showSearch 
                        allowClear
                        onChange={changeRole} 
                        optionFilterProp="children"
                        style={{marginLeft:'20px', width:'250px', height:'40px'}}
                        placeholder="Роль пользователя">
                            {roles.map(role => 
                                    <Select.Option 
                                        key={role.id} 
                                        value={role.id}>
                                        {role?.name}
                                    </Select.Option>    
                                )}
                    </Select>
                   
                    <Button text='Добавить пользователя' onClick={()=>setUserCreating({open: true}) } style={{width:'200px'}}/>
                </div>
                
                <div className={s.table}>
                    <Table
                        buttons={buttons}
                        headers={headers}
                        body={customUsers}
                        sizes={[4, 14, 14, 14, 12, 14, 12]}
                        badRow={{className: s.badRowClassName, customId: 'errorId', matchIds: devicesErrors}}
                        sort={currentSort}
                        setSort={setCurrentSortHandler}
                    />
                </div>
                
                { showMoreUsers ? <div className={s.showMore}>
                    <Button text={'Показать еще...'} onClick={() => {showMoreHandler()}}/>
                </div> : null }
                </HavePermission>
            </div>
        </div>  
        </>
    )
}
const mapStateToProps = (state: TRootState) => ({
    users: state.users.users,
    showMoreUsers: state.users.showMoreUsers,
    roles: state.users.roles
})
const mapDispatchToProps = (dispatch:AppDispatch) => {
    return {
        getUsers: (body: TGetUsers) => dispatch(getUsers(body)),
        getRoles:(body:TGetRoles) => dispatch(getRoles(body)),
    }
}

export const UsersContainer = connect(mapStateToProps, mapDispatchToProps)(Users)