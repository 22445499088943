import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { clearBlancDocument, deleteBlancDocument, getBlancDocuments, saveFile } from '../../store/slices/DocumentsSlice/DocumentsSlice'
import { TBlancDocument, TDeleteBlancDocument } from '../../store/slices/DocumentsSlice/DocumentsSliceTypes'
import { AppDispatch, TRootState } from '../../store/store'
import { transformFileToBase64Str } from '../../store/utils/helpers'
import { Button } from '../buttons/button'
import { InfoDialog } from '../dialogs/InfoModal'
import { openNotification } from '../notifications/notification'
import { Table, TSort } from '../Table/Table'
import s from './blancDocuments.module.scss'
import { HavePermission } from '../difficultFunc/HavePerm'

interface IBlancDocuments {
    getBlancDocuments: Function,
    deleteBlancDocument: Function,
    saveFile: Function,
    blancDocumentsList: TBlancDocument[] | []
    clearBlancDocument: Function
}

const BlancDocuments:React.FC<IBlancDocuments> = ({getBlancDocuments, blancDocumentsList, deleteBlancDocument, saveFile, clearBlancDocument}) => {
    const [blancDocumentsListCustom, setBlancDocumentsListCustom] = useState<any[]>([])
    const [deleteDocument, setDeleteDocument] = useState<{open: boolean, currentDocument: TBlancDocument | null}>({open: false, currentDocument: null})
    const [currentSort, setCurrentSort] = useState<TSort>({orderBy: '', isAsc: null})
    const hiddenFileInput: any = React.createRef()
    const setCurrentSortHandler = (val: TSort) => {
        setCurrentSort(val)
        getBlancDocuments()
    }
    useEffect(() => {
        getBlancDocuments()
    },[])
    useEffect(() => {
        setBlancDocumentsListCustom(blancDocumentsList.map((blanc:TBlancDocument) => {
            return (
                {
                    id: blanc.id,
                    name: blanc.name,
                    linkFile: `<a href='${blanc.linkFile}' target='_blank' >Ссылка на документ</a>`
                }
            )
        }))
    },[blancDocumentsList])
    const headers = [
        {id: 1, name: "ID", mnemo: "id", orderBy: 'id'},
        {id: 2, name: "Название", mnemo: "name", orderBy: 'name'},
        {id: 3, name: "Адрес", mnemo: "linkFile"},
    ]
    const buttons = [
        {id: 1, name: 'deleteDocument', mnemo: 'deleteDocument', image: <img title={'Удалить документ'} width={25} height={25} src='/images/trash.svg'/>, function: (val: TBlancDocument) => {setDeleteDocument({open: true, currentDocument: val}); }},
    ]
    const deleteBlancHandler = () => {
        deleteBlancDocument({
            flag: 'D',
            type: "BlancDocuments",
            id: 0,
            files: [{
                name: deleteDocument.currentDocument?.name,
                // dataType: "1",
                // base64: '',
                // extension: 'pdf'
            }]
        }).then(() => {
            getBlancDocuments()
            clearBlancDocument()
            setDeleteDocument({open: false, currentDocument: null})
        })
    }

    const handleClick = (event: any) => {
        event.preventDefault()
        hiddenFileInput.current.click()
    }

    const handleChangePhotos: any = ({currentTarget}: any) => {
        if(currentTarget?.files[0]){
            const reader = new FileReader()
            reader.readAsDataURL(currentTarget.files[0])
            reader.onloadend = () => {
                const res: any = reader.result
                if(blancDocumentsList.find(item => item.name === currentTarget.files[0].name.replaceAll(' ', '_'))){
                    return openNotification({type: 'warning', message: 'Файл с таким именем уже существует!'})
                }
                let type = currentTarget.files[0].name.split(".").splice(-1,1)[0]
                if(type === 'docx' || type === 'doc' || type === 'odt' || type === 'pdf' || type === 'jpeg' || type === 'jpg' || type === 'png'){
                    let newFile = {
                        name: currentTarget.files[0].name.replaceAll(' ', '_'),
                        extension: currentTarget.files[0].type,
                        base64: transformFileToBase64Str(res),
                        dataType: '1'
                    }
                    saveFile({
                        flag: 'i',
                        type: "BlancDocuments",
                        files:[newFile]
                    }).then(() => {
                        getBlancDocuments()
                        
                    })
                } else {
                    return openNotification({type: 'warning', message: 'Доступные расширения для файлов: docx, doc, odt, pdf, jpeg, jpg или png'})
                }
                
            }
        }
    }

    return (<>
        <div className={s.mainBlock}>
        <input type="file" ref={hiddenFileInput}
                                           onChange={handleChangePhotos}
                                           style={{display: 'none'}}/>
            <InfoDialog open={deleteDocument.open} handleClose={() => {setDeleteDocument({open: false, currentDocument: null})}}>
                <div className={s.forSureDelete}>
                    <div className={s.forSureTitle}>
                        Вы действительно хотите удалить документ "{deleteDocument.currentDocument?.name}"?
                    </div>
                    <div className={s.forSureButton}>
                        <Button text='Удалить' onClick={() => {deleteBlancHandler()}}/>
                    </div>
                </div>
            </InfoDialog>
            <div className={s.tableContainer}>
                <HavePermission mnemoName={'users'}>
                <div className={s.filters}>
                    <Button text='Добавить документ' onClick={(event: any) => {handleClick(event)}}/>
                </div>
                </HavePermission>
                <div className={s.table}>
                    <Table
                        headers={headers}
                        body={blancDocumentsListCustom}
                        buttons={buttons}
                        sizes={[15, 45, 35, 1]}
                        sort={currentSort}
                        setSort={setCurrentSortHandler}
                    />
                </div>
            </div>
        </div>
    </>)
}

const mapStateToProps = (state: TRootState) => ({
    blancDocumentsList: state.documents.blancDocumentsList
})

const mapDispatchToProps = (dispatch:AppDispatch) => {
    return {
        getBlancDocuments: () => dispatch(getBlancDocuments()),
        deleteBlancDocument: (val: TDeleteBlancDocument) => dispatch(deleteBlancDocument(val)),
        saveFile: (val: TDeleteBlancDocument) => dispatch(saveFile(val)),
        clearBlancDocument: () => dispatch(clearBlancDocument())
    }
}

export const BlancDocumentsContainer = connect(mapStateToProps, mapDispatchToProps)(BlancDocuments)